<axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
<p class="kz-visually-hidden" role="alert" *ngIf="commonService.isDetailPage" id="isDetailPage" aria-live="assertive">{{this.bookTitle}}</p>
<div [hidden]="isLoading" class="title-details" (window:resize)="onResize()">
        <axis360-breadcrumb [breadcrumbs]="commonService.breadcrumbs"
                *ngIf="commonService.breadcrumbs && browserHistory"
                [breadcrumbsClass]="'kz-breadcrumb'"></axis360-breadcrumb>
        <ng-template #separatorTemplate>
                <mat-icon svgIcon="kz-right"></mat-icon>
        </ng-template>
        <div *ngIf="!titleError">
                <div class="titledetails-bookwrapper">
                        <div class="col-lg-4 col-sm-5 col-xs-12 titlebook-leftwrapper"
                                [ngClass]="{'book-progress-bar': isProgressBarShown}">
                                <ng-container>
                                        <axis360-title-details-actions [titleDetailsRating]="titleDetailsRating"
                                                [titleDetailsActions]="titleDetailsActions"
                                                (progressBarEvent)="progressBarEvent($event)"
                                                (moveToReviewCTAEvent)="scrollTitleReview($event)"
                                                (firstTitleReviewEvent)="scrollTitleReview($event)">
                                        </axis360-title-details-actions>
                                </ng-container>
                        </div>
                        <div class="col-lg-8 col-sm-7 col-xs-12 titlebook-rightwrapper">
                                <div class="title-details-info">
                                        <div class="author-name visible-xs" tabindex="1"
                                                *ngIf="titleButtonViews?.availability.totalQuantity > 0">
                                                <p class="primary-heading-2 available-copies"
                                                        *ngIf="titleButtonViews?.availability.availableCopies !== 9999;else availableCopies">
                                                        {{titleButtonViews?.availability.availableCopies > 0 &&
                                                        !titleButtonViews?.availability.isRTV ?
                                                        0 : titleButtonViews?.availability.availableCopies}} of
                                                        {{titleButtonViews?.availability.totalQuantity}}
                                                        Copies Available</p>
                                                <ng-template #availableCopies>
                                                        <p class="primary-heading-2 available-copies">
                                                                {{UNLIMITED_COPIES}}
                                                        </p>
                                                </ng-template>
                                                <p class="primary-heading-2 line"
                                                        *ngIf="titleButtonViews?.holdsAllowed && titleButtonViews?.availability.isInHoldQueue">
                                                        You are {{holdPositionFormat}} in Line
                                                </p>
                                                <p class="primary-heading-2 line"
                                                        *ngIf="titleButtonViews.holdsAllowed && !titleButtonViews.availability.isInHoldQueue && titleButtonViews.availability.holdPosition ">
                                                        {{titleButtonViews.availability.holdPosition}} people waiting
                                                </p>
                                        </div>
                                        <div class="author-name visible-xs">
                                                <p class="primary-heading-2 line coming-soon"
                                                        *ngIf="titleButtonViews?.availableBookStatus === COMING_SOON && !titleButtonViews.availability.isInHoldQueue && titleButtonViews.availability.holdPosition ">
                                                        {{titleButtonViews.availability.onOrderQuantity}} copies Coming
                                                        Soon</p>
                                        </div>
                                        <div class="visible-xs" *ngIf="titleContent?.titleStateInfo && isVisibleMobile">
                                                <axis360-title-details-action-button [titleDetailsData]="titleDetailsData"
                                                        [bookDetail]="titleContent"></axis360-title-details-action-button>
                                        </div>
                                        <div class="title-wrapper">
                                                <div class="title-sec">
                                                        <h1 class="primary-heading-1" *ngIf="titleDetailsData"
                                                                [innerHTML]="titleDetailsData?.title"
                                                                [ngClass]="{'no-description': !titleDetailsData?.subTitle}">
                                                        </h1>
                                                        <p class="primary-para" *ngIf="titleDetailsData?.subTitle">
                                                                {{titleDetailsData.subTitle}}</p>
                                                </div>
                                                <div class="action-sec">
                                                        <axis360-title-details-secondary-actions
                                                                [titleButtonViews]="titleButtonCTA"
                                                                [shareActionsButton]="shareActionsButton"
                                                                [bookDetail]="titleContent">
                                                        </axis360-title-details-secondary-actions>
                                                </div>

                                        </div>
                                        <div class="author-name">
                                                <p class="primary-para author" *ngIf="titleDetailsData?.author">By: <a
                                                                *ngFor="let authorName of titleDetailsData?.author;let i = index"
                                                                class="primary-link" [routerLink]="['/view/search']"
                                                                [queryParams]="{page:1,pageSize:10,searchText:authorName,q:authorName,searchby:'author',format:'All',addeddate:'',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}"
                                                                (click)="trackByTitleDetailsActionsLike('ACTIONS_AUTHOR',authorName)">
                                                                {{authorName}} {{ (i < titleDetailsData?.author.length -
                                                                        1) ? ';' : '' }} </a>
                                                </p>
                                                <p class="primary-para narrator"
                                                        *ngIf="titleDetailsData?.narrator[0].trim()">
                                                        Narrated By: <a class="primary-link"
                                                                [routerLink]="['/view/search']"
                                                                [queryParams]="{page:1,pageSize:10,searchText:titleDetailsData.narrator[0],q:titleDetailsData.narrator[0],searchby:'author',format:'All',addeddate:'',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}"
                                                                (click)="trackByTitleDetailsActionsLike('ACTIONS_NARRATOR',titleDetailsData.narrator[0])">
                                                                {{titleDetailsData.narrator}}
                                                        </a>
                                                </p>
                                        </div>
                                        <div>
                                                <ng-container *ngIf="titleDetailsRating">
                                                        <axis360-title-details-more [removeRating]="true"
                                                                [titleDetailsRating]="titleDetailsRating"
                                                                (titleRatingEvent)="handleRating($event)"
                                                                (firstTitleReview)="scrollTitleReview($event)"
                                                                (scrollTitleReview)="scrollTitleReview($event)">
                                                        </axis360-title-details-more>
                                                </ng-container>
                                                <axis360-shimmer-cards *ngIf="!titleDetailsRating"
                                                        [compName]="compName"></axis360-shimmer-cards>
                                        </div>

                                        <div class="visible-xs title-state-info" *ngIf="titleButtonViews?.availability">
                                                <div class="checkout-limit-sec mt-1"
                                                        *ngIf="(titleButtonViews?.availability.collectionType === 'PPC' || isPPCOnlyLibrary) && titleButtonViews?.ppcBudgetReached">
                                                        <mat-icon svgIcon="kz-info-icon"></mat-icon>
                                                        <p class="primary-heading-2 checkout-limit">
                                                                {{CHECKOUT_LIMIT}}</p>
                                                </div>
                                                <div class="due-date-sec mt-1"
                                                        *ngIf="titleButtonViews?.titleCheckoutInfo"
                                                        id="afterCtaFocusMobile">
                                                        <mat-icon svgIcon="kz-calender-icon"></mat-icon>
                                                        <p class="primary-heading-2">Due Date:
                                                                {{titleButtonViews.titleCheckoutInfo?.checkoutDueDate}}
                                                        </p>
                                                </div>
                                        </div>
                                        <h2 class="primary-heading-2 synopsis" *ngIf="titleDetailsData?.synopsis">
                                                Synopsis</h2>
                                        <p class="primary-para synopsis-text"
                                                *ngIf="titleDetailsData?.synopsis && !showParagraph"
                                                [innerHTML]="titleDetailsData?.synopsis.substring(0, 361)+'...'"></p>
                                        <button class="primary-link expand-link" href="javascript:void(0);" id="more"
                                                *ngIf="!showParagraph && titleDetailsData?.synopsis.length > 358"
                                                (click)="synopsisMoreLess(!showParagraph, 'less');trackByTitleDetailsActions('ACTIONS_SEE_MORE');"
                                                 aria-live="assertive">View More</button>
                                        <ng-container *ngIf="showParagraph">
                                                <p class="primary-para synopsis-text" *ngIf="showParagraph"
                                                        [innerHTML]="titleDetailsData?.synopsis"></p>
                                                <button class="primary-link expand-link" href="javascript:void(0);" id="less"
                                                        (click)="synopsisMoreLess(!showParagraph, 'more');trackByTitleDetailsActions('ACTIONS_SEE_LESS');"
                                                        aria-live="assertive">View Less</button>
                                        </ng-container>
                                        <div class="author-name hidden-xs" tabindex="1"
                                                *ngIf="titleButtonViews?.availability.totalQuantity > 0">
                                                <p class="primary-heading-2 available-copies"
                                                        *ngIf="titleButtonViews?.availability.availableCopies !== 9999;else availableCopies">
                                                        {{titleButtonViews?.availability.availableCopies > 0 &&
                                                        !titleButtonViews.availability.isRTV ?
                                                        0 : titleButtonViews?.availability.availableCopies}} of
                                                        {{titleButtonViews?.availability.totalQuantity}}
                                                        Copies Available </p>
                                                <ng-template #availableCopies>
                                                        <p class="primary-heading-2 available-copies">
                                                                {{UNLIMITED_COPIES}}
                                                        </p>
                                                </ng-template>
                                                <p class="primary-heading-2 line"
                                                        *ngIf="titleButtonViews.holdsAllowed && titleButtonViews.availability.isInHoldQueue">
                                                        You are {{holdPositionFormat}} in line</p>
                                                <p class="primary-heading-2 line"
                                                        *ngIf="titleButtonViews.holdsAllowed && !titleButtonViews.availability.isInHoldQueue && titleButtonViews.availability.holdPosition ">
                                                        {{titleButtonViews.availability.holdPosition}} people waiting
                                                </p>
                                        </div>
                                        <axis360-shimmer-cards *ngIf="!isCopiesLoad"
                                                [compName]="compNameCopies"></axis360-shimmer-cards>
                                        <div class="author-name hidden-xs">
                                                <p class="primary-heading-2 line"
                                                        *ngIf="titleButtonViews?.availableBookStatus === COMING_SOON && !titleButtonViews.availability.isInHoldQueue && titleButtonViews.availability.holdPosition ">
                                                        {{titleButtonViews.availability.onOrderQuantity}} copies Coming
                                                        Soon</p>
                                        </div>
                                        <div class="hidden-xs title-state-info" *ngIf="titleButtonViews?.availability">
                                                <div class="checkout-limit-sec mt-1"
                                                        *ngIf="(titleButtonViews.availability.collectionType === 'PPC' || isPPCOnlyLibrary) && titleButtonViews.ppcBudgetReached">
                                                        <mat-icon svgIcon="kz-no-info-icon"></mat-icon>
                                                        <p class="primary-heading-2 checkout-limit">{{CHECKOUT_LIMIT}}
                                                        </p>
                                                </div>
                                                <div class="due-date-sec mt-1"
                                                        *ngIf="titleButtonViews.titleCheckoutInfo" id="afterCtaFocusPc">
                                                        <mat-icon svgIcon="kz-calender-icon"></mat-icon>
                                                        <p class="primary-heading-2 due-date">Due Date:
                                                                {{titleButtonViews.titleCheckoutInfo.checkoutDueDate }}
                                                        </p>
                                                </div>
                                        </div>
                                        <axis360-shimmer-cards *ngIf="!titleActions"
                                                [compName]="compNameButtons"></axis360-shimmer-cards>
                                        <div class="hidden-xs"
                                                *ngIf="titleDetailsData && titleActions && titleContent?.titleStateInfo && !isVisibleMobile">
                                                <axis360-title-details-action-button [titleDetailsData]="titleDetailsData"
                                                        [bookDetail]="titleContent"></axis360-title-details-action-button>
                                        </div>

                                </div>
                        </div>
                </div>
                <mat-tab-group class="custom-tab-group title-details-tab-group"
                        (selectedTabChange)="selectedTabChange($event)" #tabGroup>
                        <mat-tab class="custom-tab-labels" label="Details" *ngIf="titleDetailsActions">
                        </mat-tab>
                        <mat-tab class="custom-tab-labels" label="More Like This"
                                *ngIf="moreLikeItemTab && recommendedTitleDetails">
                        </mat-tab>
                        <mat-tab class="custom-tab-labels" label="Related Items"
                                *ngIf="learningActivityBooks?.length > 0 || alternateFormatTitle?.length > 0">
                        </mat-tab>
                </mat-tab-group>
                <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'Details' && titleDetailsActions">
                        <axis360-title-details-info [titleDetailsActions]="titleDetailsActions">
                        </axis360-title-details-info>
                </div>
                <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'More Like This'">
                        <axis360-spinner-loader isLoading="true" *ngIf="moreLikeItemLoader"></axis360-spinner-loader>
                        <axis360-title-recommendations [isLoad]="true"
                                [recommendedTitleDetails]="recommendedTitleDetails"
                                [titleDetailsData]="titleDetailsData" (moreLikeTabsHide)="titleDetailsTabsHide($event)">
                        </axis360-title-recommendations>
                </div>
                <div class="custom-tab-body" *ngIf="defaultSelectedTabs === 'Related Items'">
                        <axis360-spinner-loader isLoading="true" *ngIf="realatedItemLoader"></axis360-spinner-loader>
                        <axis360-title-related-items [ISBN]="isbn" [isLoad]="true"
                                [learningActivityData]="learningActivityBooks"
                                [alternateFormatData]="alternateFormatTitle">
                        </axis360-title-related-items>
                </div>
                <ng-container *ngIf="defaultSelectedTabs === 'Details'">
                        <div class="review-sec" *ngIf="patronReviewsEnabled">
                                <mat-divider aria-hidden="true" class="kz-divider"></mat-divider>
                                <div class="review-ratings">
                                        <div>
                                                <h2 aria-label="Reviews" class="reviews">Reviews</h2>
                                                <p class="reviews-desc">{{totalReviewsCount ? totalReviewsCount : 0}}
                                                        reader
                                                        reviews</p>
                                        </div>
                                        <div>
                                                <button id="review-btn"
                                                        class="btn-primary-blue btn-full-width btn-review"
                                                        (click)="writeReview()">
                                                        Write a Review
                                                </button>
                                        </div>
                                </div>
                        </div>
                        <div class="reviews-content" *ngIf="patronReviewsEnabled && reviewList">
                                <axis360-title-review [reviewList]="reviewList" role="list"></axis360-title-review>
                                <axis360-spinner-loader></axis360-spinner-loader>
                        </div>
                </ng-container>
        </div>
        <div class="title-details-no-title-sec" *ngIf="titleError">
                <p class="title-details-no-title-heading">No Title Details Yet</p>
        </div>
</div>