<mat-card class="kz-card hidden-card kz-program-card" role="button" [tabindex]="isAddTitleDisable ? -1 : 0"
    [ngClass]="{'disabled': isAddTitleDisable }" *ngIf="titleContent?.addBtn" id="loc_AddaTitle" (click)="openPopup()"
    (keyup.enter)="openPopup()" attr.aria-label="{{isAddTitleDisable ? 'Add a Title disabled': 'Add a Title'}}">
    <div class="hidden-title" [ngClass]="{'disabled': isAddTitleDisable}">
        <mat-icon svgIcon="kz-addplus" aria-hidden="true"></mat-icon>
        <p class="primary-para mt-1">Add a Title</p>
    </div>
</mat-card>
<div class="title-card-wrapper {{notificationClass}}" [ngClass]="{'kz-myshuff-box': isMyshuffBox }"
    *ngIf=" !titleContent?.addBtn && !isCurrentlyCheckout && titleContent && titleContent.itemId !== '' && (titleContent.itemId !== 'seeAll' || !titleContent.itemId )">
    <div class="d-flex overflow-delete" *ngIf="isDeleteFlag">
        <mat-checkbox axis360MatCheckboxAda ngDefaultControl *ngIf="isDeleteFlag"
            (change)="fieldsChange($event, titleContent)" class="delete-checkbox"
            id="loc_TitleCheckbox{{titleContent.title}}" (click)="fieldsChange($event, titleContent);$event.stopPropagation();"
            (keydown.enter)="fieldsChange($event, titleContent);$event.stopPropagation();" [attr.tabindex]="isDeleteFlag ? '0' : '-1'"> <span
                class="kz-visually-hidden">{{titleContent.title}}</span></mat-checkbox>
    </div>   
    <a href="javascript:;" [ngStyle]="(this.titleContent.isManual) && {'pointer-events': 'none'}" attr.role="{{this.titleContent.isManual ? 'img': 'link'}}" attr.tabindex="{{this.programType === 'Milestone' && this.titleContent.isManual ? '-1' : '0'}}" [routerLink]="titleDetailLink" #title [attr.aria-label]="titleAriaLabel(title)"
        class="card-link" id="title_{{titleContent.itemId}}" (click)="cardClick(type);$event.stopPropagation();"
        (keyup.enter)="cardClick(type);$event.stopPropagation();">      
        <mat-card class="kz-card">

            <div class="kz-card-image" aria-hidden="true"
                *ngIf="(titleContent.url || titleContent.isbn !==''); else hiddenImage">
                <img class="card-image" mat-card-image
                    [src]="titleContent.url || titleContent?.ImageUrl || getTitleImage(titleContent.isbn)" alt="">
            </div>
            <ng-template #externalImage>
                <div class="kz-milestone-cover-bg" *ngIf="true">
                    <div class="kz-user-add-title mb-2">
                        <h3 class="heading-2 milestone-head three-ellipsis" axis360EllipsisTitle>{{titleContent.title}}
                        </h3>
                    </div>
                    <div class="kz-user-author-name">
                        <p class="secondary-para milestone-author single-ellipsis" axis360EllipsisTitle>
                            {{titleContent.author}}</p>
                    </div>
                </div>
            </ng-template>
            <div class="carousel-card-ribbon"
                [ngClass]="{'duration-sec': titleContent?.DisplayStatus.startsWith('Due'),'dark-top-banner-bg': !titleContent?.DisplayStatus.startsWith('Due In')}"
                *ngIf=" isCurrentlyCheckout &&  titleContent?.DisplayStatus && titleContent?.DisplayStatus !== '' && titleContent?.DisplayStatus.startsWith('Due')">
                <span class="due-duration kz-time-remain-lbl">{{titleContent?.DisplayStatus.replace('(s)', 's')}}</span>
            </div>
            <ng-template #hiddenImage>
                <span *ngIf="!titleContent.isManual; else externalImage">
                    <div class="kz-hidden-image">
                        <mat-icon svgIcon="kz-no-info-icon" matTooltip="This title is no longer available."
                            matTooltipClass="kz-card-tooltip"></mat-icon>
                        <div class="hidden-title-img"></div>
                    </div>
                </span>
            </ng-template>
            <div id="card-image" class="book-poster-bg" *ngIf="titleContent?.FormatType || titleContent?.formatType"
                aria-hidden="true">
                <div class="carousel-card-ribbon"
                    [ngClass]="{'carousel-card-ribbon-past-checkout': (titleContent?.DisplayStatus === pastCheckout)}"
                    *ngIf="titleContent?.DisplayStatus && titleContent?.DisplayStatus !== '' && titleContent?.DisplayStatus !== readyForCheckout && titleContent?.DisplayStatus !== comingSoon && titleContent?.DisplayStatus !== onHold && titleContent?.DisplayStatus !== unAvailableStatus  && titleContent?.DisplayStatus !== requested  && !titleContent?.DisplayStatus.startsWith('Due') && !isDeleteFlag">
                    {{titleContent.DisplayStatus | titlecase}}</div>
                <div class="carousel-card-ribbon carousel-card-ribbon-available"
                    *ngIf="(titleContent?.DisplayStatus == readyForCheckout) && !isDeleteFlag">
                    {{readyForCheckout | titlecase}}</div>
                <div class="carousel-card-ribbon dark-top-banner-bg"
                    *ngIf="(titleContent?.DisplayStatus === onHold) && !isDeleteFlag && titleContent?.titleStateInfo?.holdPosition">
                    {{titleContent?.HoldPosition}}</div>
                <div class="book-poster" *ngIf="titleContent.iconInfo && titleContent.formatType !== 'Print Book'">
                    <mat-icon class="book-poster-icon" [svgIcon]="getImage(titleContent.iconInfo.icon)"></mat-icon>
                </div>
                <div class="book-poster"
                    *ngIf="!titleContent.iconInfo && titleContent.formatType && titleContent.formatType !== 'Print Book'">
                    <mat-icon class="book-poster-icon" [title]="titleContent.iconInfo"
                        [svgIcon]="getIcon(titleContent.formatType).icon"></mat-icon>
                </div>
                <div class="duration" *ngIf="!isDueDate && !isMyStuff">
                  <ng-container *ngIf="titleContent?.DisplayStatus === 'COMING SOON' && titleContent?.DisplayStatus !== 'Print Book' && !titleContent?.runTime && !titleContent?.RunTime">
                    <div class="duration-sec">
                      {{ titleContent?.DisplayStatus | titlecase }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="titleContent?.DisplayStatus !== 'COMING SOON'">
                    <div class="duration-sec" *ngIf="titleContent?.formatType === 'Print Book' || titleContent?.formatType === 'printbook'">
                      {{ printBook }}
                    </div>
                  </ng-container>
                    <div *ngIf="(titleContent?.FormatType === 'ABT' || titleContent?.formatType === 'ABT' || titleContent?.formatType === 'audiobook'
                                    || titleContent?.iconInfo?.icon === 'audio')">
                       <ng-container *ngIf="(titleContent?.DisplayStatus === 'UNAVAILABLE' && !titleContent?.runTime && !titleContent?.RunTime); else noDuration">
                        <div class="duration-sec">
                          {{ eAudio }}
                        </div>
                      </ng-container>
                      <ng-template #noDuration>
                        <div class="duration-sec" *ngIf="!(titleContent?.runTime || titleContent?.RunTime)">
                            {{eAudio}}
                        </div>
                    </ng-template>
                      <ng-container *ngIf="(titleContent?.DisplayStatus === 'COMING SOON') && (titleContent?.runTime || titleContent?.RunTime)">
                        <div class="duration-sec displayStatus">
                          <span class="due-duration">
                            {{ titleContent?.DisplayStatus | titlecase }}
                          </span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="(titleContent?.runTime || titleContent?.RunTime) && titleContent?.DisplayStatus !== 'COMING SOON'">
                        <div class="duration-sec displayStatus">
                          <span class="duration" aria-hidden="true">
                            {{ titleContent?.runTime || titleContent?.RunTime }}
                          </span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!(titleContent?.runTime || titleContent?.RunTime) && titleContent?.DisplayStatus && titleContent?.DisplayStatus !== 'COMING SOON'">
                        <div class="duration-sec displayStatus">
                          <span class="due-duration" *ngIf="isShowDisplayStatus()">
                            {{ titleContent?.DisplayStatus | titlecase }}
                          </span>
                        </div>
                      </ng-container>
                    </div>
                    <!--<div class="duration-sec" *ngIf="titleContent?.DisplayStatus">
                        <span class="due-duration karishma2" *ngIf="isShowDisplayStatus()">
                            {{titleContent?.DisplayStatus === comingSoon ? titleContent?.DisplayStatus
                            :titleContent?.DisplayStatus.replace('(s)', 's') | titlecase}}
                        </span>
                    </div>-->
                    <div *ngIf="(titleContent?.formatType === 'EBT' || titleContent?.formatType === 'ebook' || titleContent?.FormatType === 'EBT'
                                    || titleContent?.iconInfo?.icon === 'ebook')">
                        <div *ngIf="isShowEbookStatus()">
                            <div
                                *ngIf="((titleContent?.bookLength ==='0' || !titleContent?.bookLength)
                                        && (titleContent?.BookLength ==='0' || !titleContent?.BookLength)); else isBookLength">
                                <div class="duration-sec"
                                    *ngIf="(titleContent?.Attribute === 'Read Along' || titleContent?.attribute === 'Read Along'); else noAttribute">
                                    <span class="duration-text" [innerHtml]="getTitleFormat(titleContent)"></span>
                                </div>
                                <ng-template #noAttribute>
                                    <div class="duration-sec">
                                        {{eBook}}
                                    </div>
                                </ng-template>
                            </div>
                            <ng-template #isBookLength>
                                <div class="duration-sec">
                                    {{titleContent.bookLength || titleContent.BookLength}} Pages
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>          
            <div aria-hidden="true" class="book-ribbon"
                *ngIf="isShowWaitList() && !titleContent.isManual && !isCurrentlyCheckout && titleContent.DisplayStatus !== requested && this.titleContent?.DisplayStatus !== this.pastCheckout && titleContent?.formatType && !titleContent?.titleStateInfo?.holdPosition">
                {{waitList}}
            </div>
        </mat-card>
        </a>   
    <ng-container *ngIf="titleContent?.titleStateInfo && isProgramDetail">
        <axis360-title-actions [isDueDate]="true" [isProgramCTAShow]="isProgramCTAShow"
            [isAriaLabel]="getSanitizedTitle(titleContent.title)" [durationSecClass]="'carousel-card-ribbon'" [seeAll]="seeAll"
            [searchResult]="searchResult" [bookProgress]="bookProgress" [programType]="programDetails?.type"
            [isParticipant]="!isAddTitleDisable" [dueDurationClass]="'kz-time-remain-lbl'"
            [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'"
            [kzActionMenuItemClass]="'kz-my-stuff-list-option program-list-option'" [isSuggestedBook]="isSuggestedBook"
            [expandSecClass]="'more-menu more-menu-list'" [bookDetail]="titleContent" [showAction]="showAction"
            [isProgressCalculate]="false" (click)="$event.stopPropagation();$event.preventDefault()"
            (keyup.enter)="$event.stopPropagation();$event.preventDefault()" [isMyStuff]="true"
            (titleMarkAsReadEvent)="titleMarkAsReadEvent($event)">
        </axis360-title-actions>
    </ng-container>
</div>
<div class="title-card-wrapper"
    *ngIf="!titleContent?.addBtn && isCurrentlyCheckout && titleContent && titleContent.itemId !== '' && (titleContent.itemId !== 'seeAll' || !titleContent.itemId )">
    <mat-card class="kz-card">
    <a href="{{'/Action/'+playerURL+'?itemId='+titleContent.itemId+'&ISBN='+titleContent.isbn}}"
        [target]="isCurrentlyCheckout?'_blank':'_self'" #title [attr.aria-label]="titleAriaLabel(title)"
        class="card-link" (click)="cardClick(type);$event.stopPropagation();"
        (keyup.enter)="cardClick(type);$event.stopPropagation();" role="link">
      

            <div class="kz-card-image" aria-hidden="true"
                *ngIf="(titleContent.url || titleContent.isbn !==''); else hiddenImage">
                <img class="card-image" mat-card-image
                    [src]="titleContent.url || titleContent?.ImageUrl || getTitleImage(titleContent.isbn)" alt="">
            </div>
            <div class="kz-card-image d-flex overflow-delete" *ngIf="isDeleteFlag">
                <mat-checkbox axis360MatCheckboxAda *ngIf="isDeleteFlag" (change)="fieldsChange($event, titleContent)"
                    class="delete-checkbox" (click)="$event.stopPropagation();"
                    (keyup.enter)="$event.stopPropagation();"></mat-checkbox>
            </div>
            <ng-template #externalImage>
                <div class="kz-hidden-image kz-user-image" *ngIf="true">
                    <div class="kz-milestone-cover-img"></div>
                </div>
            </ng-template>
            <div class="carousel-card-ribbon"
                [ngClass]="{'duration-sec': titleContent?.DisplayStatus.startsWith('Due'),'dark-top-banner-bg': !titleContent?.DisplayStatus.startsWith('Due In')}"
                *ngIf=" isCurrentlyCheckout &&  titleContent?.DisplayStatus && titleContent?.DisplayStatus !== '' && titleContent?.DisplayStatus.startsWith('Due')">
                <span class="due-duration kz-time-remain-lbl">{{titleContent?.DisplayStatus.replace('(s)', 's')}}</span>
            </div>
            <ng-template #hiddenImage>
                <span *ngIf="!titleContent.isManual; else externalImage">
                    <div class="kz-hidden-image">
                        <mat-icon svgIcon="kz-no-info-icon" matTooltip="This title is no longer available."
                            matTooltipClass="kz-card-tooltip"></mat-icon>
                        <div class="hidden-title-img"></div>
                    </div>
                </span>
            </ng-template>
            <div class="book-poster-bg" [attr.aria-hidden]="isMobile() ? 'false' : 'true'">
                <div class="carousel-card-ribbon"
                    *ngIf="titleContent?.DisplayStatus && titleContent?.DisplayStatus !== '' && titleContent?.DisplayStatus !== comingSoon &&titleContent?.DisplayStatus !== readyForCheckout && titleContent?.DisplayStatus !== onHold && titleContent?.DisplayStatus !== unAvailableStatus && titleContent?.DisplayStatus !== comingSoon && titleContent?.DisplayStatus !== requested  && !titleContent?.DisplayStatus.startsWith('Due') && !isDeleteFlag">
                    {{titleContent.DisplayStatus | titlecase}}</div>
                <div class="carousel-card-ribbon carousel-card-ribbon-available"
                    *ngIf="(titleContent?.DisplayStatus == readyForCheckout) && !isDeleteFlag">
                    {{readyForCheckout | titlecase}}</div>
                <div class="carousel-card-ribbon dark-top-banner-bg"
                    *ngIf="(titleContent?.DisplayStatus === onHold) && !isDeleteFlag && titleContent?.titleStateInfo?.holdPosition">
                    {{titleContent?.HoldPosition}}</div>
                <div class="book-poster" *ngIf="titleContent.iconInfo">
                    <mat-icon class="book-poster-icon" [svgIcon]="getImage(titleContent.iconInfo.icon)"></mat-icon>
                </div>
                <div class="book-poster" *ngIf="titleContent.formatType">
                    <mat-icon class="book-poster-icon" [svgIcon]="getIcon(titleContent.formatType).icon"></mat-icon>
                </div>
                <div class="duration" *ngIf="!isDueDate && !isMyStuff">
                    <div *ngIf="(titleContent?.FormatType === 'ABT' || titleContent?.formatType === 'ABT' || titleContent?.formatType === 'audiobook'
                                || titleContent?.iconInfo?.icon === 'audio')">
                        <div class="duration-sec"
                            *ngIf="(titleContent?.runTime || titleContent?.RunTime); else noDuration">
                            <span class="duration" aria-hidden="true">{{titleContent?.runTime ||
                                titleContent?.RunTime}}</span>
                        </div>
                        <ng-template #noDuration>
                            <div class="duration-sec" *ngIf="!(titleContent?.runTime || titleContent?.RunTime)">
                                {{eAudio}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="duration-sec" *ngIf="titleContent?.DisplayStatus">
                        <span class="due-duration" *ngIf="isShowDisplayStatus()">
                            {{titleContent?.DisplayStatus.replace('(s)', 's') | titlecase}}
                        </span>
                    </div>
                    <div class="duration-sec" *ngIf="titleContent?.formatType === 'printbook'">
                        {{printBook}}
                    </div>
                    <div *ngIf="(titleContent?.formatType === 'EBT' || titleContent?.formatType === 'ebook' || titleContent?.FormatType === 'EBT'
                                || titleContent?.iconInfo?.icon === 'ebook')">
                        <div *ngIf="isShowEbookStatus()">
                            <div
                                *ngIf="((titleContent?.bookLength ==='0' || !titleContent?.bookLength)
                                    && (titleContent?.BookLength ==='0' || !titleContent?.BookLength)); else isBookLength">
                                <div class="duration-sec"
                                    *ngIf="(titleContent?.Attribute === 'Read Along' || titleContent?.attribute === 'Read Along'); else noAttribute">
                                    <span class="duration-text" [innerHtml]="getTitleFormat(titleContent)"></span>
                                </div>
                                <ng-template #noAttribute>
                                    <div class="duration-sec">
                                        {{eBook}}
                                    </div>
                                </ng-template>
                            </div>
                            <ng-template #isBookLength>
                                <div class="duration-sec">
                                    {{titleContent.bookLength || titleContent.BookLength}} Pages
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
                  
    </a>
    <mat-card-actions class="kz-list-group" *ngIf="titleContent?.titleStateInfo && myShelfLoad"> 
        <ng-container *ngIf="titleContent?.titleStateInfo">
          <axis360-title-actions [bookDetail]="titleContent" [isProgressCalculate]="false"
                                 [dueDurationClass]="'kz-time-remain-lbl'"
                                 [isAriaLabel]="getSanitizedTitle(titleContent.title)"
                                 [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'" [kzActionMenuItemClass]="'kz-my-stuff-list-option'"
                                 [expandSecClass]="'more-menu more-menu-list'" (click)="$event.stopPropagation();$event.preventDefault()"
                                 (keyup.enter)="$event.stopPropagation();$event.preventDefault()" [isMyStuff]="true" [isHistory]="false"
                                 (titleActionEvent)="titleActionEvent()" [activeMyStuff]="'checkouts'">
          </axis360-title-actions>
        </ng-container>
      </mat-card-actions>
    <div aria-hidden="true" class="book-ribbon"
    *ngIf="isShowWaitList() && !titleContent.isManual && !isCurrentlyCheckout && titleContent.DisplayStatus !== requested && this.titleContent?.DisplayStatus !== this.pastCheckout && titleContent?.formatType && !titleContent?.titleStateInfo?.holdPosition">
        <span class="sr-only">Status &nbsp;</span>{{waitList}}
    </div> 
</mat-card>
    <ng-container *ngIf="titleContent?.titleStateInfo && isProgramDetail">
        <axis360-title-actions [isDueDate]="true" [durationSecClass]="'carousel-card-ribbon'"
            [isSuggestedBook]="isSuggestedBook" [seeAll]="seeAll" [searchResult]="searchResult"
            [isAriaLabel]="titleContent.title" [bookProgress]="bookProgress" [programType]="programDetails?.type"
            [isParticipant]="!isAddTitleDisable" [dueDurationClass]="'kz-time-remain-lbl'"
            [kzCardBtnClass]="'kz-card-btn kz-card-btn-list'"
            [kzActionMenuItemClass]="'kz-my-stuff-list-option program-list-option'"
            [expandSecClass]="'more-menu more-menu-list'" [bookDetail]="titleContent" [showAction]="showAction"
            [isProgressCalculate]="false" (click)="$event.stopPropagation();$event.preventDefault()"
            (keyup.enter)="$event.stopPropagation();$event.preventDefault()" [isMyStuff]="true"
            (titleMarkAsReadEvent)="titleMarkAsReadEvent($event)" [isProgramCTAShow]="isProgramCTAShow">
        </axis360-title-actions>
    </ng-container>
</div>
<mat-card class="kz-card see-all" *ngIf="titleContent && !titleContent?.addBtn &&  titleContent.itemId === 'seeAll'"
    (click)="seeAllClick()" (keyup.enter)="seeAllClick()">
    <div class="see-all-container">
        <mat-icon svgIcon="kz-see-all-eye" class="see-all-icon"></mat-icon>
        <a href="javascript:;" class="see-all-title see-all-text">See All {{titleContent.TotalQuantity}} Titles</a>
    </div>
</mat-card>
<mat-card class="kz-card hidden-card kz-feature-card" aria-label="This title is no longer available."
    *ngIf="!titleContent?.addBtn &&  (!titleContent || titleContent.itemId === '')">
    <div class="hidden-title">
        <mat-icon svgIcon="kz-no-info-icon" matTooltip="This title is no longer available."
            matTooltipClass="kz-card-tooltip"></mat-icon>
        <div class="hidden-title-img"></div>
    </div>
</mat-card>
