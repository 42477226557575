<div class="announcements-col" *ngIf="announcement && announcement.headLines">
  <h2 class="announcements-heading">{{announcement.headLines}}</h2>
  <div class="announcements-img" *ngIf="announcement.isImage" tabindex="-1">
    <a *ngIf="announcement.isImage && announcement.imageLink !== '' && announcement.imageLink" class="focus-announcement" [href]="announcement.imageLink" 
     attr.aria-label="{{announcement.titles}}, opens in a new tab" target="_blank">
      <img class="img-responsive" [src]="announcement.url" aria-hidden="true" alt="{{announcement.titles}}">
    </a>
    <img *ngIf="!announcement.imageLink || announcement.imageLink === ''" class="img-responsive" [src]="announcement.url" alt="{{announcement.titles}}">
    <span class="hidden">{{announcement.titles}}</span>
  </div>
  <div *ngIf="!announcement.isImage && !showIframe " class="shimmer-BG announe-image"></div>
  <axis360-iframe *ngIf="!announcement.isImage  && announcement.url && showIframe" [classes]="'video-responsive'" class="rwd-media"
                  [url]="announcement.url"></axis360-iframe>
  <h3 class="announcements-sub-heading" title="{{announcement.titles}}">{{announcement.titles}}</h3>
  <p class="announcements-para desc-alink" [innerHTML]="announcement.body"> </p>
</div>

