import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { KzSearchResultsComponent } from '../kz-search-results.component';
import {
  CATEGORY_TYPE,
  SITES_TITLE,
  AUDIO_FORMAT,
  EAUDIO,
  EBOOK,
  CATEGORY_REFINER,
  EBOOK_EAUDIO,
  CATEGORY_TYPE_LIST,
  EBOOK_EAUDIO_REFINER_ORDER_LIST
} from '../shared/constant/search.constant';
import { KzSearchService } from '../shared/services/kz-search.service';
import { SearchStorageService } from '@search/shared/services/search-storage-service';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { Subscription } from 'rxjs';
import { RefinerItem } from '../shared/data/search.model';
import { SITE_REFINER, TITLE_REFINER } from '@shared/constants/search.constant';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ConfigService } from '@shared/services/config.service';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { SearchRefinersComponent } from '@shared/components/search-refiners/search-refiners.component';
import { arrangeInOrder, cdkRemoveVisualHidden, deepClone, focusElement } from '@shared/helper/app.util';
import { UserService } from '@shared/services/user.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ALL_REFINERS } from '@shared/constants/app.constants';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CommonService } from '@shared/services/common.service';
import { ProgramsService } from '@readingprograms/shared/services/programs.service';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { TITLE_A_Z } from '@search/shared/constants/search-results.constants';
import { PROFILE_TYPES } from '@profile/shared/constants/profiles.constants';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
@Component({
  selector: 'axis360-kz-search-list-view',
  templateUrl: './kz-search-list-view.component.html',
  styleUrls: ['./kz-search-list-view.component.scss']
})
export class KzSearchListViewComponent extends KzSearchResultsComponent implements OnInit, OnDestroy {
  @Output() count = new EventEmitter<{ type: string; count: number }>();
  titleCard = true;
  Category = CATEGORY_TYPE;
  titleRefiners;
  centerTab = true;
  eBook = EBOOK;
  eAudio = EAUDIO;
  audioFormat = AUDIO_FORMAT;
  eBook_eAudio = EBOOK_EAUDIO;
  webPathExpressTiltle = SITES_TITLE;
  scrollSubscription: Subscription;
  currentCategory: string;
  format: string;
  refinersHidden: boolean = false;
  attributes: string = 'false';
  thirdPartyCountvalue = 0;
  isThirdPartyLoading: boolean;
  tempRefiner;
  parentRoute: string;
  thirdPartyParentRount: string;
  tempEvent = [];
  sortRefiner: any[];
  selectedTitleRefiner = {};
  separationOfSortFromRefiners = false;
  categoryTypeList = CATEGORY_TYPE_LIST;
  arrangeInOrder = arrangeInOrder;
  epopupValue: boolean;
  collections: string;
  checkselectedTitleEmpty: boolean;
  learningActivityPills = {};
  thirdPartyResultCount: number = 0;
  enablePurchaseRequest: boolean;
  isMobile = false;
  skipLink = '';
  constructor(
    public matDialog: MatDialog,
    public searchService: KzSearchService,
    public activeRouter: ActivatedRoute,
    public router: Router,
    public spinnerService: SpinnerService,
    public liveAnnouncer: LiveAnnouncer,
    public snackBarService: SnackBarService,
    public searchServiceService: SearchStorageService,
    private infiniteScroll: InfiniteScrollService,
    public profileInfoService: ProfileInfoService,
    public configService: ConfigService,
    public thirdPartyService: ThirdPartyService,
    public userService: UserService,
    public breadcrumbService: BreadcrumbService,
    public overlayContainer: OverlayContainer,
    public commonService: CommonService,
    public programsService: ProgramsService,
    public titleService: Title,
    public kzProgramsService: KzProgramsService,
    public location: Location
  ) {
    super(
      matDialog,
      searchService,
      activeRouter,
      router,
      spinnerService,
      liveAnnouncer,
      snackBarService,
      searchServiceService,
      profileInfoService,
      configService,
      thirdPartyService,
      userService,
      breadcrumbService,
      commonService,
      programsService,
      titleService,
      kzProgramsService,
      location
    );
  }
  /*
   * read search request field and call corresponding function based on pathParams
   */
  ngOnInit(): void {
    setTimeout(() => {
      navigator.userAgent.match(/(Android|iPhone|iPod|iPad|Macintosh)/i) && focusElement('loc_btnHamburgerMenu')
    }, 2000);
    this.epopupValue = this.configService.currentLibrary.partnerSiteEnabled;
    this.enablePurchaseRequest = (this.userService.isLoggedIn()) ? this.configService.getProfileFeatures(
      this.profileInfoService.currentProfile.profileType.toLowerCase()
    ).purchaseRequestsEnabled : this.configService.getProfileFeatures(PROFILE_TYPES.ADULT).purchaseRequestsEnabled;
    this.activeRouter.queryParamMap.subscribe(() => {
      this.studyTitlesResult = [];
      this.isPressrdrLoad = false;
      this.searchService.totalCountThirdparty = 0;
      this.currentCategory = this.activeRouter.snapshot.params.format;
      this.thirdPartyService.searchViewRefiner = true;
      this.readQueryParms();
      var regExString = new RegExp("(?:view/)((.[\\s\\S]*))(?:/search)", "ig"); //set ig flag for global search and case insensitive
      var regExString1 = regExString.exec(this.router.url);
      this.parentRoute = regExString1 ? regExString1[1] + '/search/' + this.currentCategory : 'search/' + this.currentCategory;
      this.thirdPartyParentRount = regExString1 ? `${regExString1[1]}/search/${this.currentCategory}/content` : `search/${this.currentCategory}/content`;
      this.searchService.selectedCategory = this.currentCategory;
      this.attributes = this.activeRouter.snapshot.queryParams.attributes;
      let categoryText = this.categoryTypeList.filter((category) => category.value === this.currentCategory);
      const catergory = (categoryText?.length ? categoryText[0].text + ' - ' : (this.currentCategory ? this.currentCategory + ' - ' : ''))
      this.breadcrumbService.set("@searchformat", catergory + 'Search Results')
      this.setCategoryRefiner(this.currentCategory);
      this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
      this.layoutComponent = undefined;
      this.refinersComponent = undefined;
      this.layoutComponents = [];
      this.isThirdPartyLoading = true;
      this.format = this.activeRouter.snapshot.queryParams.format;
      this.collections = this.activeRouter.snapshot.queryParams.collections;
      this.searchService.thiredPartyNoData.next(false);
      this.activityCategory = this.activeRouter.snapshot.queryParams['searchby'].charAt(0).toLocaleUpperCase()
        + this.activeRouter.snapshot.queryParams['searchby'].substr(1).toLowerCase();
    });
    this.searchService.isTitletoRecommendThirdPartyRemoved.subscribe((res) => {
      if (res) {
        this.thirdPartyCountvalue = 0;
        this.isThirdPartyLoading = false;
      }
    });
    this.searchService.thirdPartyCount.subscribe((res: number) => {
      this.thirdPartyCountvalue = res;
      this.isThirdPartyLoading = false;
    })
    this.thirdPartyResultCount = 0;
    this.isMobile = window.innerWidth <= 768;
    this.skipLink = `/ng${this.location.path(false)}#searchResultContainer`;
    setTimeout(() => {
      document.getElementById('searchResultsList')?.classList.add('hidden');
    }, 2000);
  }
  setCategoryRefiner(selectedCategory: string) {
    this.searchService.setDisableReset(true);
    this.searchService.showThiredParty = false;
    this.searchService.totalCount = 0;
    this.searchService.totalCountThirdparty = 0;
    this.refinersHidden = false;
    if (selectedCategory) {
      this.searchService.showThiredPartyRefiner = false;
      let categoryRefiner = {
        [CATEGORY_TYPE.TITLES]: () => (this.getSearchTitles(true, true), this.getTitleRefiners(), (this.programsDetails = this.activeRouter.snapshot.queryParams['pageName'])
        ),
        [CATEGORY_TYPE.EBOOKS_TITLES]: () => (this.getSearchTitles(true, true), this.getTitleRefiners(), (this.programsDetails = this.activeRouter.snapshot.queryParams['pageName'])),
        [CATEGORY_TYPE.EAUDIO_TITLES]: () => (this.getSearchTitles(true), this.getTitleRefiners(), (this.programsDetails = this.activeRouter.snapshot.queryParams['pageName'])),
        [CATEGORY_TYPE.WEB_RESOURCES]: () => (this.getSitesData(), sessionStorage.setItem('webresouse', undefined)),
        [CATEGORY_TYPE.ACTIVE_RESOURCES]: () => ((this.learningActivityResult = undefined, this.refiners = [], this.activityRefiner = [], this.learningActivityPills = { ...this.selectedOptions }), this.getLearningActivity(true),
        sessionStorage.setItem('webresouse', undefined),(this.showLearningActivity = true))
      };
      if (!categoryRefiner[selectedCategory]) {
        this.searchService.showThiredParty = true;
        categoryRefiner = {
          ...categoryRefiner,
          ...{ [selectedCategory]: () => (this.getThirdPartyLibraryComponents(selectedCategory, true), (this.refinersHidden = true)) }
        };
      }
      categoryRefiner[selectedCategory]();
      this.changeCategory(selectedCategory);
    }
  }
  changeCategory(selectedCategory) {
    for (let catgeory in CATEGORY_REFINER) {
      this.searchService[CATEGORY_REFINER[catgeory]] = catgeory !== selectedCategory ? false : true;
    }
  }
  /**
   * StudyTiles Refine changes
   */
  titleRefiner(res: RefinerItem, isPills = false) {
    this.selectedOption = res;
    this.studyTitles[res.myParent] = res.value;
    this.studyTitles.page = 1;
    if (res.myParent === 'sortby') {
      this.searchService.setDisableReset(false);
    }
    this.appliedFilterCount = this.titleRefiners
      .map((x) => x.items.reduce((n, e) => (e.defaultSelected === true || e.isSelected ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
    if (isPills) {
      this.filterSearchTitle(isPills);
    }
  }
  /**
   * Learning Activity Refine changes
   */
  learningActivityRefiner(res) {
    this.learningActivity[res.myParent] = res.value === '' ? 'All' : res.value;
    const resValue = res.value.includes('All') ? [] : res.value.split(',');
    this.selectedOptions[res.myParent] = res.value === '' ? [] : resValue;
    this.learningActivity['sortOrder'] = res.myParent === 'sortBy' && res.value === TITLE_A_Z ? '-1' : '1';
    this.refiners.find(x => x.key === res.myParent).selectedItem = res.value.split(',');

    if (res.value !== '') {
      this.activityRefiner.filter(sme => sme.refiner === res.myParent).map(ref => {
        if (res.value.includes('All')) {
          ref.selectedFilter = 'All';
          ref.filters.map(fill => {
            fill.isSelected = true;
            return fill;
          });
        } else {
          ref.selectedFilter = res.value;
        }
        return ref;
      });
      // Remove the below code for Separation of All from Refiners in Search result Page
      // this.activityRefiner.filter(sme => sme.refiner === res.myParent).map(ref => {
      //   const allSelected = res.value.includes('All') ? ref.filters.slice(1).every(item => item.isSelected) : ref.filters.every(item => item.isSelected);
      //   ref.filters[0].isSelected = allSelected;
      //   ref.selectedFilter = allSelected ? 'All' : ref.selectedFilter;
      //   this.selectedOptions[res.myParent] = allSelected ? ['All'] : this.selectedOptions[res.myParent];
      //   return ref;
      // });
    } else {
      const activityRefiner = deepClone(this.activityRefiner)
      activityRefiner.filter(sme => sme.refiner === res.myParent).map(ref => {
        ref.selectedFilter = 'All';
        ref.filters.map(fill => {
          fill.isSelected = true;
          return fill;
        });
        return ref;
      });
      this.activityRefiner = activityRefiner;
    }
    const keys = { activityTypes: 'activityTypes', audiences: 'audiences' }
    let selectedCategory =
      (this.selectedOptions[keys.activityTypes].length === 0
        && this.selectedOptions[keys.audiences].length === 0);
    if (selectedCategory) {
      this.searchService.setDisableReset(true);
    }
    this.selectedOptions = { ...this.selectedOptions };
    this.selectedOptions.defaulter = false;
    return;
  }
  /**
   * Get Study Titles Refiner
   */
  getTitleRefiners() {
    const { collections, searchText, format } = this.activeRouter.snapshot.queryParams || {};
    sessionStorage.setItem('webresouse', undefined);
    const collectionData = (collections === "Titles to Recommend") ? collections : ''
    this.searchService
      .getSearchRefiners(collectionData, searchText, format)
      .subscribe((response) => {
        response.refiner = this.arrangeInOrder(response.refiner, EBOOK_EAUDIO_REFINER_ORDER_LIST, true).filter(elem => elem !== undefined);
        response.refiner = this.epopupValue ? response.refiner.filter((item) => item.text !== 'Availability') : response.refiner;
        this.refinerSelected(response.refiner);
        this.tempRefiner = deepClone(response.refiner);
      });
  }

  /**
   * Determines whether load more can
   * @returns
   */
  canLoadMore() {
    let categoryCheck = {
      [CATEGORY_TYPE.WEB_RESOURCES]: () => {
        return this.studySitesPageCount >= 24;
      },
      [CATEGORY_TYPE.TITLES]: () => {
        return this.studyTitlesResult[0]?.TotalItems > this.studyTitlesResult[0]?.Items.length;
      },
      [CATEGORY_TYPE.EBOOKS_TITLES]: () => {
        return this.studyTitlesResult[0]?.TotalItems > this.studyTitlesResult[0]?.Items.length;
      },
      [CATEGORY_TYPE.EAUDIO_TITLES]: () => {
        return this.studyTitlesResult[0]?.TotalItems > this.studyTitlesResult[0]?.Items.length;
      },
      [CATEGORY_TYPE.ACTIVE_RESOURCES]: () => {
        return this.learningActivityResult?.totalCount > this.learningActivityResult?.data.length;
      }
    };
    if (!categoryCheck[this.currentCategory]) {
      categoryCheck = {
        ...categoryCheck,
        ...{
          [this.currentCategory]: () => {
            return false;
          }
        }
      };
    }
    return categoryCheck[this.currentCategory]();
  }
  /**
   * Load more content
   * @returns returns can load more or not
   */
  loadMore = () => {
    const categoryLoadMore = {
      [CATEGORY_TYPE.WEB_RESOURCES]: () => (this.studySites.page++, this.getSitesData()),
      [CATEGORY_TYPE.TITLES]: () => (this.studyTitles.page++, this.getSearchTitles(true)),
      [CATEGORY_TYPE.EAUDIO_TITLES]: () => (this.studyTitles.page++, this.getSearchTitles(true)),
      [CATEGORY_TYPE.EBOOKS_TITLES]: () => (this.studyTitles.page++, this.getSearchTitles(true)),
      [CATEGORY_TYPE.ACTIVE_RESOURCES]: () => (this.learningActivity.page++, this.getLearningActivity())
    };
    if (!this.canLoadMore()) {
      return;
    }
    categoryLoadMore[this.currentCategory]();
  };

  /**
   * clear the selected filter for  particular Category.
   */
  clearAllRefiner() {
    this.liveAnnouncer.announce('Filters have been cleared');
    cdkRemoveVisualHidden();
    const clearRefiner = {
      [CATEGORY_TYPE.TITLES]: () => this.clearTitleRefiner(),
      [CATEGORY_TYPE.EAUDIO_TITLES]: () => this.clearTitleRefiner(),
      [CATEGORY_TYPE.EBOOKS_TITLES]: () => this.clearTitleRefiner(),
      [CATEGORY_TYPE.WEB_RESOURCES]: () => this.clearSitesRefiner(),
      [CATEGORY_TYPE.ACTIVE_RESOURCES]: () => this.clearLearningActivityRefiner(true)
    };
    clearRefiner[this.currentCategory]();
  }
  /**
   * Clears title refiner
   */
  clearTitleRefiner() {
    TITLE_REFINER.map((filter) => (this.studyTitles[filter] = ''));
    this.studyTitles.format = this.format;
    this.studyTitles.collections = this.collections;
    this.studyTitles.page = 1;
    this.studyTitlesResult = [];
    this.studyTitles.availability = this.epopupValue ? 'Available' : 'All';
    this.studyTitles.agelevel = 'All';
    this.searchService.setDisableReset(true);
    this.searchService.tierTwoRefinerSearch.next('search');
    this.refinerSelected(deepClone(this.tempRefiner), true);
    this.getSearchTitles(true);
  }
  /**
   * Clears sites refiner
   */
  clearSitesRefiner() {
    SITE_REFINER.map((refiner) => (this.studySites[refiner] = ''));
    this.isAppliedFilter = false;
    this.searchService.studySites.page = 0;
    this.sitesInfo = [];
    this.searchService.setDisableReset(true);
    this.searchService.isPillsEmptyTitleFoucs = true;
    this.searchStorageService.removeSearchRefiners();
    this.getSitesData();
  }
  /**
   * Clears learning activity refiner
   */
  clearLearningActivityRefiner(isLoad = false) {
    this.learningActivityPills = [];
    this.learningActivity = JSON.parse(JSON.stringify(this.searchService.learningActivity));
    this.getLearningActivityFilters(this.learningActivityResult, true, isLoad);
    delete this.learningActivityResult;
    this.searchService.setDisableReset(true);
    this.searchService.isPillsEmptyTitleFoucs = true;
    this.getLearningActivity(isLoad);
  }
  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this.thirdPartyService.searchViewRefiner = false;
    sessionStorage.setItem('webresouse', undefined);
  }
  /**
   * Refiners selected
   * @param refiners
   */
  refinerSelected(refiners, isClearAllPills?) {
    this.titleRefiners = refiners.map((val) => {
      // start tempory removal of collection type will be removed in 1.3 release
      if (val.value === 'collections') {
        const removeIItem = val.items.findIndex((x) => x.value === 'Titles to Recommend');
        if (removeIItem >= 0) {
          val.items.splice(removeIItem, 1);
        }
        val.items.length === 0 && (val.items = []);
      }

      val.selected = '';
      val.selectedText = '';
      val.items.forEach((x) => {
        x.isSelected = false;
        if (val.value === 'format') {
          if (this.studyTitles.format !== 'All') {
            x.defaultSelected = x.value;
            return (x.value === !!this.studyTitles[x.myParent]);
          }
          return;
        }
        if (this.studyTitles[x.myParent] !== '') {
          const refStudyTitles = (x.text === 'All Titles') ? 'All' : '';
          const studyTitlesRefTitle = ((this.studyTitles[x.myParent] === 'All') ? refStudyTitles : this.studyTitles[x.myParent]);
          x.defaultSelected = (x.value === studyTitlesRefTitle);
        }
      });
      const defaltselection = val.items.find((x) => x.defaultSelected);
      if (defaltselection) {
        val.selected = defaltselection.value;
        val.selectedText = defaltselection.text;
      }
      return val;
    });
    const titleRefinerList = this.titleRefiners.filter(ref => ref.items.length > 0);
    this.titleRefiners = titleRefinerList;
    // Remove the below code for Separation of Sort from Refiners in Search result Page
    // this.sortRefiner = this.titleRefiners.filter(x => x.text === 'Sort By');
    // this.titleRefiners = this.titleRefiners.filter(x => x.text !== 'Sort By');
    // Old code for Pills below
    // this.selectedTitleRefiner = this.titleRefiners.reduce((result, item) => {
    //   const key = item.value.toLowerCase();
    //   const selectedText = item.selectedText;
    //   selectedText !== '' && (result[key] = [selectedText === 'All Titles' ? 'All' : selectedText]);
    //   return result;
    // }, {});
    this.titlePills(isClearAllPills);
    this.appliedFilterCount = this.titleRefiners
      .map((x) => x.items.reduce((n, e) => (e.defaultSelected === true || e.isSelected ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
  }
  /**
   *
   * Open dialog on below 768px
   */
  openFilter() {
    if (this.filterOpened) {
      return;
    }
    this.filterOpened = !this.filterOpened;
    this.searchService.setDisableCheckBox.next(true);
    const searchRefinersDialog = this.matDialog.open(SearchRefinersComponent, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title',
      data: {
        refiners: this.titleRefiners && deepClone(this.titleRefiners),
        availableLimiters:
          this.currentCategory === 'web_Resources' && !this.availableLimiters
            ? JSON.parse(sessionStorage.getItem('webresouse'))
            : deepClone(this.availableLimiters),
        activityRefiner: this.activityRefiner && deepClone(this.activityRefiner),
        showAccordionThirdParty: this.refinersHidden,
        showAccordion: !this.refinersHidden,
        radioClass: 'kz-search-radio'
      }
    });
    searchRefinersDialog.afterClosed().subscribe((result) => {
      this.filterOpened = false;
      if (result) {
        switch (Object.keys(result)[0]) {
          case 'studyTitlesRefinerChange':
            this.titleRefinerChange(result.studyTitlesRefinerChange);
            break;
          case 'applyLimiter':
            this.applyFilterChange(result.applyLimiter);
            break;
          case 'activityRefinerChange':
            this.learningActivityChange(result.activityRefinerChange);
            break;
          default:
            this.clearAllRefiner();
            break;
        }
      }
    });
  }
  /**
   * Titles refiner change
   * @param refiner
   */
  titleRefinerChange(refiner) {
    this.studyTitlesResult = [];
    this.titleRefiners = deepClone(refiner);
    this.appliedFilterCount = this.titleRefiners
      .map((x) => x.items.reduce((n, e) => (e.defaultSelected === true ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
    refiner.filter((item) => (this.studyTitles[item.value] = item.selected));
    this.getSearchTitles(true);
  }
  /**
   * Applys filter change
   * @param refiner
   */
  applyFilterChange(refiner) {
    for (let item of refiner) {
      switch (item.title) {
        case 'Subject':
          this.studySites.subjectFilter = item.selectedFacets;
          break;
        case 'Format':
          this.studySites.formatFilter = item.selectedFacets;
          break;
        case 'Source':
          this.studySites.sourceFilter = item.selectedFacets;
          break;
        case 'Language':
          this.studySites.language = item.selectedFacets;
          break;
        case 'Grade Level':
          this.studySites.gradeMap = item.selectedFacets;
          break;
        case 'Domain':
          this.studySites.domainFilter = item.selectedFacets;
          break;
      }
    }
    this.availableLimiters = deepClone(refiner);
    this.appliedFilterCount = this.availableLimiters.filter((val) => val.selectedFacets).length;
    sessionStorage.setItem('webresouse', JSON.stringify(this.availableLimiters));
    this.sitesInfo = [];
    this.getSitesData();
  }
  /**
   * Learning activity change
   * @param result
   */
  learningActivityChange(result) {
    delete this.learningActivityResult;
    this.appliedFilterCount = 0;
    this.activityRefiner = deepClone(result);
    this.activityRefiner.map(activityRef => {
      if (activityRef.refiner === "activityTypes" || activityRef.refiner === 'audiences') {
        this.learningActivity[activityRef.refiner] = 'All';
        const selectedRefine = activityRef.filters.filter(ref => ref.isSelected).map(item => item.value);
        activityRef.selectedFilter = selectedRefine?.length < activityRef?.filters.length ? selectedRefine.toString() : 'All';
        this.refiners.find(x => x.key === activityRef.refiner).selectedItem = activityRef.selectedFilter.split(',');
      };
      return activityRef;
    });
    const refineObj = { sortBy: [], audiences: [], activityTypes: [] };
    this.activityRefiner?.forEach((ele) => {
      refineObj[ele.refiner] = ele.selectedFilter.split(',');
    });
    this.appliedFilterCount = this.activityRefiner
      .map((x) => x.filters.reduce((n, e) => (e.isSelected === true ? n + 1 : n), 0))
      .reduce((num, item) => num + item, 0);
    this.learningActivityPills = { ...refineObj, defaulter: false };
    this.learningActivity.sortBy = refineObj.sortBy.toString();
    this.learningActivity.audience = refineObj.audiences.length ? refineObj.audiences.toString() : 'All';
    this.learningActivity.audiences = refineObj.audiences.length ? refineObj.audiences.toString() : 'All';
    this.learningActivity.activityType = refineObj.activityTypes.length ? refineObj.activityTypes.toString() : 'All';
    this.learningActivity.activityTypes = refineObj.activityTypes.length ? refineObj.activityTypes.toString() : 'All';
    this.getLearningActivity();
  }


  /**
   * removeTitleSelection
   */
  removeTitleSelection(option) {
    const matchResult = /(iPhone|iPod|iPad|Macintosh)/i.exec(navigator.userAgent);
    if (matchResult) {
      setTimeout(() => {
        const searchbtn: HTMLElement = document.getElementById('searchCarouselTitle');
        searchbtn?.setAttribute('tabindex', '-1');
        searchbtn?.setAttribute('aria-hidden', 'true');
        this.searchService.tierTwoRefinerSearch.next('search');
      }, 1000)
    }
    if (option.key === 'ClearAll') {
      document.getElementById('carouselTitle')?.focus();
      this.clearTitleRefiner();
    } else {
      this.liveAnnouncer.announce(`${option.value} filter has been Cleared`)
      if (option.key === 'releasedate' || option.key === 'recentlyadded') {
        this.titleRefiners.forEach(item => {
          if (item.value === option.key) {
            const itemIndex = item.items.findIndex(ref => ref.text === option.value);
            if (itemIndex >= 0) {
              item.items[itemIndex].isSelected = false;
            };
            const itemsList = item.items[item.items.map(x => x.myParent === option.key && x.defaultSelected).lastIndexOf(true)];
            item.selected = itemsList?.value || '';
            item.selectedText = itemsList?.text;
            const titleRefTernary = (itemsList?.value === 'All Titles') ? ''
              : itemsList?.value || '';
            this.titleRefiner({
              myParent: option.key,
              value: itemsList?.value === 'All' ? '' : titleRefTernary
            } as any, true);
          }
        });
        return;
      }
      this.titleRefiners.filter(ref => ref.value === option.key).map(item => {
        const optionValueOfRAL = option.value === 'eRead-Along' ? 'RAL' : option.value;
        const optionKeyChecker = (option.key === 'language' || option.key === 'subject') ?
          option.value.toLocaleUpperCase() : optionValueOfRAL;
        const optionValue = (option.value === 'Available Now') ? "Available" : optionKeyChecker;
        item.items.find(refItem => refItem.value === optionValue).isSelected = false;
        const itemList = item.items.find(itemValue => itemValue.myParent === option.key && itemValue.defaultSelected);
        item.selected = itemList?.value || '';
        item.selectedText = itemList.text;
        const isItemListChecker = (itemList?.value === 'All' ? '' :
          itemList?.value || '')
        this.titleRefiner({
          myParent: option.key, value: (itemList?.text === 'All Titles' ? 'All' : isItemListChecker)
        } as any, true);
        return item;
      });

      // Remove the below code for Refiners in Search result Page
      // this.selectedTitleRefiner[option.key].splice(this.selectedTitleRefiner[option.key].indexOf(option.value), 1);
      // this.selectedTitleRefiner = { ...this.selectedTitleRefiner };
      // this.tempRefiner.filter(x => x.value === option.key).map(tempRef => {
      //   this.titleRefiners.filter(filter => filter.value === option.key).map(titleRef => {
      //     titleRef.selected = tempRef.selected;
      //     titleRef.selectedText = tempRef.selectedText;
      //     return titleRef;
      //   });
      //   // this.titleRefiner({ myParent: option.key, value: tempRef.selectedText === 'All' ? '' : tempRef.selectedText === 'All Titles' ? '' : tempRef.selectedText } as any);
      //   return tempRef;
      // });
    }
  }

  /**
  * Removes WebResources Selection
  */
  removeWebResourcesSelection(option) {
    if (option.key === 'ClearAll') {
      this.clearSitesRefiner();
    } else {
      this.selectedWebResoure[option.key].splice(this.selectedWebResoure[option.key].indexOf(option.value), 1);
      this.selectedWebResoure = { ...this.selectedWebResoure };
      const result = Object.keys(this.selectedWebResoure).reduce((result, key,) => {
        result[key] = this.selectedWebResoure[key].join(',');
        return result;
      }, {});
      const availableLimitersIndex = this.availableLimiters.findIndex(x => x.title.toLocaleLowerCase() === option.key.toLocaleLowerCase());
      this.availableLimiters[availableLimitersIndex].selectedFacets = '';
      this.availableLimiters[availableLimitersIndex].facets.forEach(y => {
        if (y.value === option.value) {
          y.selected = false;
        };
      });
      const webResources = {
        GRADE_LEVEL: "grade level",
        DOMAIN: "domain",
        LANGUAGE: "language",
        SUBJECT: "subject",
        FORMAT: "format",
        SOURCE: "source"
      }

      this.studySites = {
        ...this.studySites,
        gradeMap: result[webResources.GRADE_LEVEL] || '',
        domainFilter: result[webResources.DOMAIN] || '',
        language: result[webResources.LANGUAGE] || '',
        subjectFilter: result[webResources.SUBJECT] || '',
        formatFilter: result[webResources.FORMAT] || '',
        sourceFilter: result[webResources.SOURCE] || ''
      };
      this.sitesInfo = [];
      if (Object.keys(this.selectedWebResoure).length < 2) {
        this.searchService.setDisableReset(true);
        this.searchService.isPillsEmptyTitleFoucs = true;
      }
      this.storeRefiners();
      this.getSitesData();
    }

  }


  /**
  * Removes selection
  */
  removeLearningActivitySelection(option) {
    if (option.key === 'ClearAll') {
      this.learningActivityPills = { sortBy: ['Linked Title'], activityTypes: [ALL_REFINERS], audiences: [ALL_REFINERS], defaulter: false };
      this.refiners = [];
      this.clearLearningActivityRefiner(true);

    } else {
      if (option.key === 'sortBy') {
        const refiners = deepClone(this.refiners);
        this.selectedOptions.sortBy = [];
        refiners.find((item) => item.key === 'sortBy').selectedItem = [];
      } else {
        const allRemove = this.selectedOptions[option.key].indexOf(ALL_REFINERS);
        this.selectedOptions[option.key].splice(this.selectedOptions[option.key].indexOf(option.value), 1);
        if (allRemove >= 0) {
          if (option.value === ALL_REFINERS) {
            this.selectedOptions[option.key] = [];
          } else {
            this.selectedOptions[option.key].splice(allRemove, 1);
          }
        }
        const refiners = this.refiners;
        const refinerSelectedItem = refiners.find((item) => item.key === option.key);
        refinerSelectedItem.selectedItem.splice(refinerSelectedItem.selectedItem.indexOf(option.value), 1);
        this.activityRefiner.filter(filter => filter.refiner === option.key).map(activityRef => {
          activityRef.filters.map((_: any) => {
            if (refinerSelectedItem.selectedItem.length > 0 && _.value === option.value) {
              _.isSelected = false;
            } else if (refinerSelectedItem.selectedItem.length === 0) {
              _.isSelected = true;
            }
            return _;
          });
          activityRef.selectedFilter = (activityRef.filters.filter(_ => _.isSelected).length >= activityRef.filters.length) ? 'All' : activityRef.filters.filter(_ => _.isSelected && _.value !== 'All').map(_ => _.value).toString(); //  && _.value !== 'All' Bug fix:244885
          return activityRef;
        });
        this.activityRefiner = [...this.activityRefiner];
        this.appliedFilterCount = this.activityRefiner
          .map((x) => x.filters.reduce((n, e) => (e.isSelected === true ? n + 1 : n), 0))
          .reduce((num, item) => num + item, 0);
        this.searchService.disableReset.next(this.appliedFilterCount >= 18);
        delete this.learningActivityResult;
        this.learningActivity[option.key] = refinerSelectedItem.selectedItem.toString() !== '' ? refinerSelectedItem.selectedItem.toString() : 'All';
        this.selectedOptions.defaulter = false;
        this.learningActivity.page = 1;
        this.getLearningActivity();
      }
      const { activityTypes, audiences, publishers, relatedTitle } = this.selectedOptions;
      if (activityTypes.length === 0 && audiences.length === 0 && publishers.length  === 0 && relatedTitle.length === 0) {
        this.searchService.setDisableReset(true);
        this.searchService.isPillsEmptyTitleFoucs = true;
      }
    }
  }

  /**
   * Close dialog on window resize
   */
  closeFilter() {
    if (window.innerWidth > 768 && this.filterOpened) {
      this.matDialog.closeAll();
      this.filterOpened = false;
    }
  }
  /**
   * Emits count
   * @param event
   */
  emitCount(event) {
    let count: number;
    this.tempEvent.push(event);
    this.tempEvent.length === 2 && (count = this.tempEvent.map(item => item.count).reduce((countA, countB) => countA + countB, 0));
    this.thirdPartyResultCount += 1;
    if (count === 0) {
      this.layoutComponent = undefined;
      this.layoutComponents = []
    }
    if (this.thirdPartyResultCount > 1) {
      this.isThirdPartyLoading = false;
    }
    this.count.emit(event);
    this.thirdPartyCountvalue = this.thirdPartyCountvalue + event.count;
  }

  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementsByTagName('html')[0].setAttribute('class', 'hide-scroll');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementsByTagName('html')[0].classList.remove('hide-scroll');
  }

  sortChange(sort, selectedType: string) {
    if (selectedType === 'learningActivity') {
      this.learningActivityRefiner(sort);
      this.activitySortRefiner.map(ref => {
        ref.filters.map(fill => {
          if (fill.value === sort.value) {
            ref.selectedFilter = sort.value;
            fill.isSelected = true;
          }
          return fill;
        });
        return ref;
      });
      return;
    }
    this.sortRefiner.map(x => {
      x.selected = sort.text;
      x.selectedText = sort.text
      return x;
    });
    this.titleRefiner(sort);
  }
  search(category) {
    const categoryFilterSearch = {
      [CATEGORY_TYPE.WEB_RESOURCES]: () => (this.filterStudySites()),
      [CATEGORY_TYPE.TITLES]: () => (this.filterSearchTitle()),
      [CATEGORY_TYPE.EAUDIO_TITLES]: () => (this.filterSearchTitle()),
      [CATEGORY_TYPE.EBOOKS_TITLES]: () => (this.filterSearchTitle()),
      [CATEGORY_TYPE.ACTIVE_RESOURCES]: () => (this.filterResourceTitle())
    };
    categoryFilterSearch[category]();
  };

  filterSearchTitle(isPills?) {
    this.studyTitles.page = 1;
    this.studyTitlesResult = [];
    setTimeout(() => {
      const searchbtn: HTMLElement = document.getElementById('searchCarouselTitle');
      searchbtn?.setAttribute('tabindex', '-1');
      searchbtn?.setAttribute('aria-hidden', 'true');
      this.searchService.tierTwoRefinerSearch.next('search');
    }, 1000)
    this.titlePills(isPills);
    if (this.checkselectedTitleEmpty) {
      this.searchService.setDisableReset(true);
    }
    this.getSearchTitles(true);
  }
  filterResourceTitle() {
    this.learningActivity.page = 1;
    delete this.learningActivityResult;
    this.learningActivityPills = { ...this.selectedOptions };
    this.getLearningActivity();
  }
  filterStudySites() {
    this.studySites.page = 1;
    this.sitesInfo = [];
    this.getSitesData();
  }
  titlePills(isPills?) {
    this.selectedTitleRefiner = this.titleRefiners.reduce((result: any, item) => {
      const key = item.value;
      item.text !== 'Sort By' && (result[key] = [...item.items.filter(ref => ref.isSelected).map(list => list.text)].filter(items => !items.includes('All') && !items.includes('All Titles')));
      return result;
    }, {});

    this.selectedTitleRefiner = { ...this.selectedTitleRefiner };
    this.checkselectedTitleEmpty = Object.values(this.selectedTitleRefiner).every((item) => (item as any).length === 0);
    if (isPills && this.checkselectedTitleEmpty) {
      this.searchService.isPillsEmptyTitleFoucs = true;
    }
  }

  onResize(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  searchPurchaseRequest() {
    this.searchService.totalCount = 0;
    let reqData = this.searchService.studyTitles;
    reqData.collections = 'Titles to Recommend';
    reqData.ref = Math.random();
    this.searchService.collections = reqData.collections;
    const PAGE_TITLE_FORMAT = {
      'eBooks': 'eBook',
      'eAudios': 'eAudio',
      'eBook': 'eBook',
      'eAudio': 'eAudio'
    };
    this.router.navigate([`/view/search/${PAGE_TITLE_FORMAT[reqData.pageTitle]}`], { queryParams: reqData });
  }
  skipRefiners() {
    const searchContainer = document.getElementById('searchResultContainer');
    searchContainer?.focus();
  }
  callPhygitalLibrary(){
    this.spinnerService.showLoader();
    window.open(this.phygitalUrlData.ilsUrl, '_blank').focus();
    this.spinnerService.showLoader(false);
  }
}
