import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LayoutComponent } from '../../data/third-party-model';
import { deepClone, setIvyCarouselProps } from '@shared/helper/app.util';
import { ThirdPartyBaseService } from '@shared/services/third-party-base.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import {
  FORMAT_TYPE_BY_VENDOR_CODE,
  PROFILE_TYPES_UI_AUDIENCE_DESC,
  THIRD_PARTY_GROUPS,
  THIRD_PARTY_SUBTYPE,
  THIRD_PARTY_SUBTYPE_KEY,
  THIRD_PARTY_TYPES,
  THIRD_PARTY_URLS
} from 'app/third-party/shared/constants/third-party.constants';
import {
  formatDataAPILatestIssues,
  formatLatestIssues,
  getCheckArticleContentAndMediaData,
  getMultipleVideoProgressBasedOnItemId,
  getPastCheckoutsByItemId,
  latestIssuesDates,
  seeAllClick,
  updateDataApiItemForVideo
} from 'app/third-party/shared/helper/third-party-util';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { ThousandSuffixesPipe } from '@shared/pipes/thousand-suffixes.pipe';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { Subscription } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { EngageBaseService } from '@shared/services/engage-base.service';
import { ThirdPartyAnalyticService } from 'app/third-party/shared/services/third-party-analytic.service';
import { ConfigService } from '@shared/services/config.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CommonService } from '@shared/services/common.service';
@Component({
  selector: 'axis360-third-party-cards-carousel',
  templateUrl: './third-party-cards-carousel.component.html',
  styleUrls: ['./third-party-cards-carousel.component.scss']
})
export class ThirdPartyCardsCarouselComponent implements OnInit, OnDestroy {
  @Input() componentData: LayoutComponent;
  @Input() hideSeeAll = false;
  @Input() componentTitleClass: string;
  @Input() seeAllLink = THIRD_PARTY_URLS.TIER2;
  @Input() pageTitle = '';
  @Input() vendorCode = '';
  @Input() cellsToScrollNums = [5, 4, 3, 2];
  @Input() isShowCount: boolean;
  @Output() count = new EventEmitter<{ type: string; count: number | string; entityId: string; realcount?: number }>();
  @Output() errorHandler = new EventEmitter<{ error: boolean }>();
  @Input() thirdPartyFeatured = false;
  @Input() inputElmIndex?: number;
  @Input() parentRoute;

  @Input() isCarouselLoad = false;
  @Output() checkEventDataLoading = new EventEmitter<boolean>();

  reloadDataAPISubscriptions: Subscription;
  thirdPartyVideoClass = '';
  cellsToScroll = 5;
  compName = SHIMMER_COMPONENTS.TITLE_CARDS;
  thirdCompName = SHIMMER_COMPONENTS.ARTICLE_CARDS;
  THIRD_PARTY_TYPES = THIRD_PARTY_TYPES;
  itemsData;
  cellWidth = 164;
  cellMargin = 0;
  cellHeight = 252;
  isLoading = true;
  carouselType = '';
  titleCount: number;
  showTitleCount = false;
  seeAllClick = seeAllClick;
  getCheckArticleContentAndMediaData = getCheckArticleContentAndMediaData;
  getMultipleVideoProgressBasedOnItemId = getMultipleVideoProgressBasedOnItemId;
  getPastCheckoutsByItemId = getPastCheckoutsByItemId;
  THIRD_PARTY_SUBTYPE = THIRD_PARTY_SUBTYPE;
  queryParams: object = {};
  /**
   * To update the carousel cell width and margin
   */
  onResize = setIvyCarouselProps.bind(this, 160, 0, 252, 164, 0, 252, this.cellsToScrollNums);
  constructor(
    private thirdPartyBaseService: ThirdPartyBaseService,
    public thirdPartyService: ThirdPartyService,
    public userService: UserService,
    private profileInfoService: ProfileInfoService,
    public enagageService: EngageBaseService,
    public router: Router,
    public activeRoute: ActivatedRoute,
    public configService: ConfigService,
    public thirdPartyAnalyticService: ThirdPartyAnalyticService,
    public searchService: KzSearchService,
    public breadcrumbService: BreadcrumbService,
    public commonService: CommonService
  ) { }
  ngOnInit(): void {
    const isParentRouteURL = this.parentRoute?.includes('content') ? this.parentRoute : (this.parentRoute + '/content');
    const isParentRoute = this.parentRoute?.includes('mystuff') ? this.parentRoute : isParentRouteURL;
    this.parentRoute = this.parentRoute ? isParentRoute : '';
    if (this.vendorCode === THIRD_PARTY_GROUPS.PRESSRDR) {
      this.parentRoute = (this.parentRoute?.includes('search')) ?
        this.parentRoute.replace(/%20/g, " ") : this.parentRoute;
    }
    this.formatDataApi();
    this.getData();
    this.onResize = setIvyCarouselProps.bind(this, 160, 0, 252, 164, 0, 252, this.cellsToScrollNums);
    this.onResize();
    this.reloadDataAPISubscriptions = this.thirdPartyService.getReloadDataAPI().subscribe((item) => {
      if (
        item &&
        (this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS ||
          this.componentData.subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST ||
          this.componentData.subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS)
      ) {
        this.isLoading = true;
        if (!this.itemsData || (this.itemsData || [])?.length === 0) {
          this.isLoading = false;
          return;
        }
        const componentDataTemp = this.componentData;
        const checkActionType = item.actiontype === 'checkout' || item.actiontype === 'renewtitle';
        const temp = (this.itemsData || []).map((itemList) => {
          if (
            (itemList.titleID === item.ItemId && itemList.formatType === item.formatType) ||
            (itemList.itemId === item.ItemId && itemList.format === item.formatType)
          ) {
            itemList.isCheckout = checkActionType ? 'Y' : 'N';
            itemList.isCheckedout = checkActionType ? 'true' : 'false';
          }
          return itemList;
        });
        this.itemsData = [];
        this.componentData = {} as any;
        this.itemsData = deepClone(temp);
        this.componentData = componentDataTemp;
        this.itemsData && this.itemsData.length > 0 && (this.isLoading = false);
      }
    });
  }
  formatData(data: any) {
    const formatMap = {
      [THIRD_PARTY_SUBTYPE.LATEST_ISSUES]: formatLatestIssues,
      [THIRD_PARTY_SUBTYPE.VIDEOS]: updateDataApiItemForVideo,
      [THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST]: updateDataApiItemForVideo
    };
    const formatFunc = formatMap[this.componentData.subType];
    if (formatFunc) {
      return formatFunc(data);
    }
    return data;
  }
  formatDataApi() {
    const { searchText: q = '', searchby, series = '', vendor_code = '', collectionId, author = '', topics = '' } = this.activeRoute.snapshot.queryParams;
    const { id = '' } = this.activeRoute.snapshot.params;
    const { userService, profileInfoService, configService } = this;
    const { subType, type, dataApi } = this.componentData;
    const { currentProfile } = profileInfoService;
    const { currentLibrary, vendorDetails } = configService;
    const vendorList = vendorDetails.find((list) => list.vendor_code === this.vendorCode);
    const vendorSubscription = configService.getSubscriptionData((this.vendorCode || vendor_code).toLocaleLowerCase());
    // Update collection ID based on library settings
    const updateCollectionId = Object.keys(dataApi.library_settings || {}).reduce((acc, key) => {
      acc[key] = vendorSubscription[dataApi.library_settings[key]];
      return acc;
    }, {});
    const formatMap = {
      [THIRD_PARTY_SUBTYPE.LATEST_ISSUES]: latestIssuesDates
    };
    this.componentData.dataApi.search = {
      ...this.componentData.dataApi.search,
      country: this.configService.currentLibrary.countryCode,
      language: this.configService.currentLibrary.languageCode
    };
    const formatFunc = formatMap[this.componentData.subType];
    if (!formatFunc) {
      const { contentCollectionId } = updateCollectionId as { contentCollectionId: string };
      /**
       * Pass the Third Party Subtype we get the value based on subType
       */
      switch (subType) {
        case THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS:
          dataApi.body = {
            ...dataApi.body,
            LibraryId: collectionId,
            PatronGUID: userService.getPatronId(),
            Books: [{ ItemId: id }],
            Filters: { Format: FORMAT_TYPE_BY_VENDOR_CODE[vendorList.format_code] }
          };
          dataApi.headers = {
            ...dataApi.headers,
            patronid: userService.getPatronId(),
            profileid: currentProfile.profileId || ''
          };
          break;
        case THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST:
          {
            dataApi.headers = {
              ...dataApi.headers,
              patronid: userService.getPatronId(),
              profileid: currentProfile.profileId || ''
            };
            const isZoneEnable = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
              ? 'teen'
              : 'adult';
            const audienceType =
              PROFILE_TYPES_UI_AUDIENCE_DESC[
              currentProfile.profileType?.toLowerCase() === 'adult'
                ? isZoneEnable
                : currentProfile.profileType?.toLowerCase()
              ];
            const isProfileTypeZoneEnable = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
              ? 'teen,children'
              : '';
            dataApi.search = {
              ...dataApi.search,
              ...updateCollectionId,
              audiencedesc: currentProfile.profileType
                ? audienceType
                : isProfileTypeZoneEnable
            };
          }
          break;
        case THIRD_PARTY_SUBTYPE.VIDEOS:
          {
            const { search } = dataApi as any;
            const isZoneEnabled = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
              ? 'teen'
              : 'adult';
            const audienceTypeBasedOnSubscription =
              PROFILE_TYPES_UI_AUDIENCE_DESC[
              currentProfile.profileType.toLowerCase() === 'adult'
                ? isZoneEnabled
                : currentProfile.profileType.toLowerCase()
              ];
            const isZoneEnableProfileType = currentLibrary.kidsZoneEnabled && !currentLibrary.axis360Enabled
              ? 'teen,children'
              : '';
            const queryParams = {
              ...search,
              contentCollectionId,
              audiencedesc: currentProfile.profileType
                ? audienceTypeBasedOnSubscription
                : isZoneEnableProfileType
            };
            let seriesType;
            if (series) {
              queryParams.series = series;
              queryParams.searchTerm = '';
              queryParams.formatType = FORMAT_TYPE_BY_VENDOR_CODE[vendorList.format_code];
            } else if (q) {
              seriesType = (searchby === 'series') ? { series: q, searchTerm: '' } : { searchTerm: q, series: '' };
              queryParams.searchTerm = q;
              queryParams.searchType = searchby === 'all' ? 'term' : searchby;
            }

          queryParams.topics = queryParams.topics ? queryParams.topics : (topics ? topics : '');
          queryParams.contributors = queryParams.contributors ? queryParams.contributors : (author ? author.replace(' ', '-').toLowerCase() : author);

          dataApi.search = { ...queryParams, ...seriesType };
          dataApi.headers = {
            ...dataApi.headers,
            patronid: userService.getPatronId(),
            profileid: currentProfile.profileId || ''
          };
        }
          break;
        case THIRD_PARTY_SUBTYPE.SUPPLEMENTS:
          dataApi.pathParams = { id };
          break;
        case THIRD_PARTY_SUBTYPE.ARTICLES:
          dataApi.body = {
            ...dataApi.body,
            query: q,
            countries: [this.configService.currentLibrary.countryTxtCode],
            languages: [this.configService.currentLibrary.languageTxtCode]
          };

          break;
        case THIRD_PARTY_SUBTYPE.PUBLICATIONS:
          this.updateDataApiSearch(dataApi, q);
          break;
        case THIRD_PARTY_SUBTYPE.NEWSPAPERS:
          this.updateDataApiSearch(dataApi, q);
          break;
        case THIRD_PARTY_SUBTYPE.MAGAZINES:
          this.updateDataApiSearch(dataApi, q);
          break;
        default:
          break;
      }
      if (type === THIRD_PARTY_TYPES.ITEMS_CAROUSEL) {
        this.carouselType = 'item_carousel';
      } else {
        this.carouselType = 'card_carousel';
      }
      return;
    }
    if (subType === THIRD_PARTY_SUBTYPE.LATEST_ISSUES) {
      this.thirdPartyService.setLatestIssueData.subscribe((res) => {
        if (res) {
          dataApi.country = res.country;
          dataApi.type = res.type;
          dataApi.displayName = res.displayName;
        }
      });
      dataApi.pathParams = { id };
    }
    const { titleIssue_date: LatestIssuesDate = '', issueDate } = this.activeRoute.snapshot.queryParams;
    const { fromDate, toDate }: { fromDate?: Date; toDate?: Date } =
      formatFunc(dataApi, (LatestIssuesDate && LatestIssuesDate !== '') ? LatestIssuesDate : issueDate);
    dataApi.search = {
      ...dataApi.search,
      ...formatDataAPILatestIssues({ fromDate, toDate })
    };
  }

  updateDataApiSearch(dataApi, q) {
    if (!dataApi.body) {
      dataApi.search = { ...dataApi.search, q };
    }
  }

  parseString(input: string): string {
    // Split by both '#' and '&' and trim the results
    return input?.split(/[#&]/).map(part => part.trim()).toString();
  }

  /**
   * Get the ThirdPartyItems Base on DataAPI difference Vendors
   * @returns DataAPI response based on ThirdPartyAPIM
   */
  async getData() {
    const { searchService, profileInfoService, componentData } = this;
    const { currentProfile } = profileInfoService;
    const { subType, type, title } = componentData;
    let count;
    try {
      this.componentData.dataApi.search = {
        ...this.componentData.dataApi.search,
        inVal: this.configService.currentLibrary.countryCode,
        inVal1: this.configService.currentLibrary.languageCode
      };
      let itemsDataObj;
      if (this.componentData?.itemsDataObj) {
        itemsDataObj = this.componentData.itemsDataObj;
      } else {
        itemsDataObj = await this.thirdPartyBaseService.getThirdPartyItems(this.componentData.dataApi);
      }
      const rooKey = THIRD_PARTY_SUBTYPE_KEY[this.componentData.subType || THIRD_PARTY_SUBTYPE.ITEMS];
      this.itemsData = this.formatData(rooKey ? itemsDataObj[rooKey] : itemsDataObj);
      if (subType === THIRD_PARTY_SUBTYPE.LATEST_ISSUES) {
        this.thirdPartyService.setLatestIssueData.subscribe((res) => {
          if (res) {
            (this.itemsData || []).map(items => {
              if (items.displayName === '') {
                items.displayName = res.displayName;
                return items;
              };
              return items;
            });
          }
        });
      }
      if (subType === THIRD_PARTY_SUBTYPE.RECOMMEND_VIDEOS) {
        this.thirdPartyVideoClass = 'third-party-card-checkout-videos';
        this.itemsData.map((items: any) => {
          items.vendorCode = this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code;
          items.thirdPartyVideoClass = (items.displayStatus && items.displayStatus !== '') ? 'third-party-card-checkout-videos third-party-card-title-banner' : 'third-party-card-checkout-videos';
          items.author = this.parseString(items.author);
          return items;
        });
        this.itemsData = currentProfile.profileId
          ? await this.getPastCheckoutsByItemId.apply(this)
          : this.itemsData || [];
        this.itemsData.map((itemList) => {
          itemList.vendorCode = this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code;
          itemList.thirdPartyVideoClass = (itemList.displayStatus && itemList.displayStatus !== '') ? 'third-party-card-checkout-videos third-party-card-title-banner' : 'third-party-card-checkout-videos';
          const bookStatus = 'Book Status ' + itemList.displayStatus + ', ';
          const bookDuration = itemList.runTime !== '' ? ' Duration ' + this.replaceTimeStrings(itemList.runTime) : '';
          itemList.altLabel = `${(itemList.displayStatus && itemList.displayStatus !== '') ? bookStatus : ''}
            Format ${(itemList.formatType || itemList.format) === 'VID' ? 'Video' : 'Videobook'}, Book title ${itemList.bookTitle || itemList.title}, Author ${itemList?.authors?.author[0] || itemList?.authors || itemList?.author},${bookDuration}`
        return itemList;
        });
        this.itemsData.length === 0 && this.errorHandler.emit({ error: true });
        count = this.itemsData?.length || 0;
        this.count.emit({ type: title, count: new ThousandSuffixesPipe().transform(count), entityId: this.vendorCode, realcount: Number(count) });
        this.isLoading = false;
        return;
      }
      if (subType === THIRD_PARTY_SUBTYPE.VIDEOS_FEATURED_LIST) {
        this.itemsData = currentProfile.profileId ? await this.getPastCheckoutsByItemId.apply(this) : this.itemsData;
        this.itemsData.map((items: any) => {
          items.vendorCode = this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code;
          items.bookTitle = items.bookTitle.replace(/<[^<>]*?>/g, '');
          const bookStatus = 'Book Status ' + items.displayStatus + ', ';
          const bookDuration = items.runTime !== '' ? ', Duration ' + this.replaceTimeStrings(items.runTime) : '';
          const bookAltTitle = `Format ${items.formatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${items.bookTitle}, Author ${items.authors.author[0]}${bookDuration}`;
          items.altLabel = `${(items.displayStatus && items.displayStatus !== '') ? bookStatus : ''} ${bookAltTitle}`
          items.thirdPartyVideoClass = (items.displayStatus && items.displayStatus !== '') ? 'third-party-card-checkout-videos third-party-card-title-banner' : 'third-party-card-checkout-videos';
          return items;
        });
        this.thirdPartyVideoClass = 'third-party-card-checkout-videos';
        this.itemsData.length === 0 && this.errorHandler.emit({ error: true });
        const searchTitleResponse = 'searchTitleResponse';
        count = itemsDataObj[searchTitleResponse].searchTitleResult.resultCount || 0;
        this.count.emit({ type: title, count: new ThousandSuffixesPipe().transform(Number(count)), entityId: this.vendorCode });
        this.isLoading = false;
        return;
      }
      if (subType === THIRD_PARTY_SUBTYPE.VIDEOS) {
        this.thirdPartyVideoClass = 'third-party-card-checkout-videos';
        this.itemsData = currentProfile.profileId ? await this.getPastCheckoutsByItemId.apply(this) : this.itemsData;
        this.itemsData.length === 0 && searchService.thiredPartyNoData.next(true);
        this.itemsData.length === 0 && searchService.removeElmId.next(this.inputElmIndex);
        this.itemsData.map((items: any) => {
          items.vendorCode = this.vendorCode || this.activeRoute.snapshot.queryParams.vendor_code;
          items.bookTitle = items.bookTitle.replace(/<[^<>]*?>/g, '');
          const bookStatus = 'Book Status ' + items.displayStatus + ', ';
          const bookDuration = items.runTime !== '' ? ', Duration ' + this.replaceTimeStrings(items.runTime) : '';
          const bookAltTitle = `Format ${items.formatType === 'VID' ? 'Video' : 'Videobook'}, Book title ${items.bookTitle}, Author ${items.authors.author[0]}${bookDuration}`;
          items.altLabel = `${(items.displayStatus && items.displayStatus !== '') ? bookStatus : ''} ${bookAltTitle}`
          items.thirdPartyVideoClass = (items.displayStatus && items.displayStatus !== '') ? 'third-party-card-checkout-videos third-party-card-title-banner' : 'third-party-card-checkout-videos';
          return items;
        });
        const searchTitleResponse = 'searchTitleResponse';
        this.checkEventDataLoading.emit(true);
        count = itemsDataObj[searchTitleResponse].searchTitleResult.resultCount || 0;
        this.itemsData.count = new ThousandSuffixesPipe().transform(Number(count));
        this.showTitleCount = true;
        this.titleCount = new ThousandSuffixesPipe().transform(Number(count));
        this.count.emit({ type: title, count: new ThousandSuffixesPipe().transform(Number(count)), entityId: this.vendorCode, realcount: Number(count) });
        this.isLoading = false;
        return;
      }
      if (type === THIRD_PARTY_TYPES.ARTICLE_CAROUSEL) {
        this.itemsData = this.getCheckArticleContentAndMediaData(this.itemsData);
        this.itemsData.map(art => {
          const {
            publication: { title: publishedTitle, countries = [], language: publishedLanguage } = { title: '', language: '' },
            article: { date, title: articleTitle, author }
          } = art;
          const publication = publishedTitle ? `Publication Title ${publishedTitle}` : 'Publication Title none';
          const publicationDate = date ? `Date ${this.formatDate(date)}` : 'Date none';
          art.article.date = publicationDate;
          const article = articleTitle ? `Article Title ${articleTitle}` : 'Article Title none';
          const articleAuthor = author ? `Author ${author}` : 'Author none';
          const country = countries[0] ? `Country ${countries[0]}` : 'Country none';
          const languageInfo = publishedLanguage ? `in ${publishedLanguage}` : '';
          const countryInfo = countries[0] ? `from ${countries[0]}` : '';
          const articleAltLabel = `${publication}, ${publicationDate}, ${article}, ${articleAuthor}, ${country}, ${languageInfo} ${countryInfo}`;
          art.article.altLabel = articleAltLabel;
          return art;
        });
      }
      const key = 'meta';
      if (type !== THIRD_PARTY_TYPES.ARTICLE_CAROUSEL) {
        this.itemsData = this.itemsData.map(item => {
          const date = new Date(item.latestIssue.issueDate);
          const options: Intl.DateTimeFormatOptions = {
            month: 'long', // Abbreviated month name (e.g., "February ")
            day: '2-digit', // Two-digit day of the month (e.g., "26")
            year: 'numeric' // Full numeric year (e.g., "2024")
          };
          let publishedTitle = 'Published in '
          const multicountry = 'and multiple countries'
          if ((item?.countrySlug || item?.country)?.split('| ').length > 1) {
            publishedTitle = publishedTitle + (item?.countrySlug || item?.country)?.split('| ')[0] + multicountry;
          } else {
            publishedTitle = publishedTitle + (item?.countrySlug || item?.country)?.split('| ')[0];
          }
          const formattedDate = new Intl.DateTimeFormat('en-US', options)?.format(date);
          const bookAltTitle = `${publishedTitle}, Book title ${item.displayName}, Type ${item.type}`;
          item.altLabel = formattedDate ?
            `${publishedTitle}, Book title ${item.displayName}, Type ${item.type}, issue On ${formattedDate}` : bookAltTitle;
          return item;
        });
      }
      count = itemsDataObj[key].totalCount || 0;
      this.itemsData.count = itemsDataObj[key].totalCount || 0;
      if (subType === THIRD_PARTY_SUBTYPE.ARTICLES || subType === THIRD_PARTY_SUBTYPE.PUBLICATIONS) {
        this.itemsData.count = new ThousandSuffixesPipe().transform(itemsDataObj[key].totalCount || 0);
      }
      this.searchService.totalCountThirdparty += Number(count);
      this.count.emit({ type: title, count, entityId: this.vendorCode, realcount: Number(count) });
      this.isLoading = false;
    } catch (e) {
      (e.status === 400 || e.status === 401 || e.status === 500 || e.statusCode === 500) && this.errorHandler.emit({ error: true });
      this.count.emit({ type: title, count: 0, entityId: this.vendorCode, realcount: Number(count) });
      this.isLoading = false;
      searchService.thiredPartyNoData.next(true);
      this.checkEventDataLoading.emit(true);
    }
  }

  /**
   * Unsubscribe the behaviour subjects
   */
  ngOnDestroy(): void {
    if (this.reloadDataAPISubscriptions) {
      this.thirdPartyService.setReloadDataAPI(false);
      this.reloadDataAPISubscriptions.unsubscribe();
    }
  }

  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (target.className.indexOf('carousel-arrow-next') > -1) {
      this.thirdPartyAnalyticService.trackTitleCarouselEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (target.className.indexOf('carousel-arrow-prev') > -1) {
      this.thirdPartyAnalyticService.trackTitleCarouselEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
  replaceTimeStrings = (text: string): string => {
    if(text?.includes('Mins')) {
      return text?.replace(/m(in)?s?/gi, 'minutes');
    }
    return text?.replace(/h(r?s)?/gi, ' hours').replace(/m(in)?s?/gi, ' minutes');
  };
}
