import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GoalsAndInsightsComponent } from '@home/goals-and-insights/goals-and-insights.component';
import { INSIGHT_GOAL } from '@home/shared/constants/home.constants';
import { InSightGoalModal, Insights } from '@home/shared/data/home.model';
import { HomeAnalyticsService } from '@home/shared/services/home.analytics.service';
import { HomeService } from '@home/shared/services/home.service';
import { SHIMMER_COMPONENTS } from '@shared/constants/app.constants';
import { setIvyCarouselProps } from '@shared/helper/app.util';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { GoalsInsightPopupComponent } from '../goals-insight-popup/goals-insight-popup.component';
import { MyShelfAnalyticsService } from '../../shared/services/my-shelf.analytics.service';
import { ConfigService } from '@shared/services/config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'axis360-goals-insights',
  templateUrl: './goals-insights.component.html',
  styleUrls: ['./goals-insights.component.scss']
})
export class GoalsInsightsComponent extends GoalsAndInsightsComponent {
  cellWidth = 275;
  cellMargin = 0;
  cellHeight = 160;
  compName = SHIMMER_COMPONENTS.GOALS;
  constructor(
    public homeService: HomeService,
    public matDialog: MatDialog,
    public snackBarService: SnackBarService,
    public homeAnalyticsService: HomeAnalyticsService,
    public myShelfAnalyticsService: MyShelfAnalyticsService,
    public configService: ConfigService,
    public liveAnnouncer: LiveAnnouncer
  ) {
    super(homeService, matDialog, snackBarService, homeAnalyticsService, configService, liveAnnouncer);
  }
  /**
   * To handle insights for UI display
   * @param insights insights data from API
   * @returns formatted insights data for UI
   */
  handleInsights(insights: Insights) {
    const { bookListened = { monthlyBookListened: 0, monthlyListenedGoal: 0, yearlyBookListened: 0, yearlyListenedGoal: 0 } } = insights;
    const insightInfo = super.handleInsights(insights);
    return {
      ...insightInfo,
      [INSIGHT_GOAL.MONTHLY_LISTENED]: this.getInsightsInfo(
        INSIGHT_GOAL.MONTHLY_LISTENED,
        bookListened.monthlyListenedGoal,
        bookListened.monthlyBookListened,
        bookListened.monthlyListenedGoal ? 'Edit Monthly Book Goal' : 'Set Monthly Book Goal',
        0
      ),
      [INSIGHT_GOAL.YEARLY_LISTENED]: this.getInsightsInfo(
        INSIGHT_GOAL.YEARLY_LISTENED,
        bookListened.yearlyListenedGoal,
        bookListened.yearlyBookListened,
        bookListened.yearlyListenedGoal ? 'Edit Yearly Book Goal' : 'Set Yearly Book Goal',
        0
      )
    };
  }

  /**
   * TO open the set goal pop up
   * @param insightDetails insight info
   */
  openSetGoalPopup(insightDetails: InSightGoalModal) {
    if (insightDetails.goal === 0) {
      this.myShelfAnalyticsService.trackGoalsEvent('ENTERS_YOUR_TARGET_GOAL');
    }
    const goalModal = this.matDialog.open(GoalsInsightPopupComponent, {
      data: insightDetails,
      panelClass: 'goals-insights-modal',
      ariaLabelledBy: insightDetails?.modalId,
      ariaDescribedBy: insightDetails?.modalDesID
    });
    goalModal.afterClosed().subscribe((res) => {
      setTimeout(() => {
        this.insightFocusElementId = insightDetails.id
        const badgesCardHidden = document?.getElementById('loc_badges_carosel');
        badgesCardHidden?.removeAttribute('aria-hidden');
      }, 2000);
      this.setGoal(res);
    });
  }
  /**
   * To update the carousel cell width and margin
   */
  onResize = setIvyCarouselProps.bind(this, 150, 0, 90, 275, 0, 160);

  trackCarousel(e: Event) {
    const target = e.target as any;
    if (typeof (target?.className) !== 'object' && target?.className.indexOf('carousel-arrow-next') > -1) {
      this.myShelfAnalyticsService.trackGoalsEvent('CLICK_CAROUSEL_RIGHT');
      target.previousSibling?.classList.remove('carousel-arrow-disabled');
      return;
    }
    if (typeof (target?.className) !== 'object' && target?.className?.indexOf('carousel-arrow-prev') > -1) {
      this.myShelfAnalyticsService.trackGoalsEvent('CLICK_CAROUSEL_LEFT');
      target.nextSibling?.classList.remove('carousel-arrow-disabled');
    }
  }
}
