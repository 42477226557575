<p class="kz-visually-hidden no-result-title heading-1" *ngIf="spinnerService.isLoading && (searchService.totalCount === 0)">
    <span id="searchResultsView" class="kz-visually-hidden" role="alert">
        Search Results Page Loading</span>
</p>
<div class="search-view"  *ngIf="!spinnerService.isLoading">
    <div class="search-view-container" >
        <h1 class="no-result-title heading-1" *ngIf="(searchService.totalCount > 0);else nodata">
            <span class="kz-visually-hidden" *ngIf="searchService.apiCompletedCount === searchService.apiCount"
              role="alert" aria-live="assertive" id="searchResultsViewCount">
              {{isTitletoRecommend?'Purchase Request Results count':'Results count'}} {{searchService.totalCount}}</span>
            <span class="result-text" aria-hidden="true" tabindex="-1">
                {{isTitletoRecommend?'Purchase Request Results':'Results'}}
            </span><span class="result-count" aria-hidden="true" tabindex="-1">({{searchService.totalCount
                |thousandSuff}})</span>
        </h1>
        <ng-template #nodata>
            <h2 class="no-result-title heading-1" *ngIf="searchService.apiCompletedCount >= searchService.apiCount">
                <span class="kz-visually-hidden" role="alert" id="zeroResult">0 {{isTitletoRecommend?'Purchase Request Results':'Results'}}
                    for {{searchby}}</span>
                <span class="result-text" aria-hidden="true">0
                    {{isTitletoRecommend?'Purchase Request Results':'Results'}} for
                </span>
                <span class="result-count" aria-hidden="true">{{searchby}}</span>
            </h2>
        </ng-template>
        <ol class="shorten-filter">
            <li>
                <a href="javascript:;" (click)="openFilter()" aria-label="Refine" role="button" class="filter"
                    (window:resize)="closeFilter()">
                    <mat-icon svgIcon="kz-filter-icon"></mat-icon>
                </a>
            </li>
        </ol>
    </div>
    <h2 class="selected-category heading-1 mt-2" *ngIf="!noResult && !carousel">
        eBook, eAudio, Video, vBook ({{titleContents.length}})
    </h2>
    <p class="no-result-text primary-para mt-2"
        *ngIf=" !spinnerService.isLoading && searchService.totalCount===0 && (searchService.apiCompletedCount >= searchService.apiCount)">
        No results found, please check your spelling, or try a different search term.
    </p>
    <ng-container *ngIf="enablePurchaseRequest && !isProgramCTAShow">
        <p class="primary-para mt-3"
            *ngIf="(!spinnerService.isLoading && searchService.totalCount===0) && !isTitletoRecommend && !mileStoneProgram && (searchService.apiCompletedCount >= searchService.apiCount) ">
            Suggest a Purchase –
            <a href="javascript:void(0);" class="primary-link kz-purchase-link" (click)="searchPurchaseRequest()">
                <span>Search</span></a> titles that you can request to your library for purchase.
        </p>
    </ng-container>
    <div class="pillsShow" *ngIf="!spinnerService.isLoading && searchService.totalCount !== 0">
        <axis360-navigation-tabs [isSearchListView]="isSearchListView"
            [programsDetails]="programs"></axis360-navigation-tabs>
    </div>
    <div class="search-carousel-view" *ngIf=" !spinnerService.isLoading">
        <ng-container *ngFor="let titles of studyTitlesInfo">
            <div class="search-carousel-sec mt-2" id="EBT" *ngIf="titles.Filter.Format === 'EBT'">
                <div class="carousel-title-group btn-group" *ngIf="titles.Items?.length > 0">
                    <h2 class="carousel-title heading-2 no-transform"
                        attr.aria-label="e Books count {{titles.TotalItems |thousandSuff}}">
                        <span aria-hidden="true">eBooks </span>
                        <span aria-hidden="true" class="result-count">({{titles.TotalItems |thousandSuff}})</span>
                    </h2>
                    <a href="javascript:;" class="see-all" *ngIf="titles.TotalItems > 10"
                        (click)="seeAll(category.TITLES, titles.Filter.Format )" aria-label="See All e Books">See
                        All<mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
                </div>
                <axis360-titles-carousel [addCard]="true" [titlesList]="titles.Items"
                    [isProgramCTAShow]="isProgramCTAShow" [searchResult]="searchResult" [cellsToScrollNums]="[4,4,2,1]"
                    [parentRoute]="parentRoute" [isParticipant]="true">
                </axis360-titles-carousel>
            </div>
        </ng-container>
        <ng-container *ngFor="let titles of studyTitlesInfo">
            <div class="search-carousel-sec mt-2 " id="ABT" *ngIf="titles.Filter.Format === 'ABT'">
                <div class="carousel-title-group btn-group" *ngIf="titles.Items?.length > 0">
                    <h2 class="carousel-title heading-2 no-transform"
                        attr.aria-label="e Audios count {{titles.TotalItems |thousandSuff}}">
                        <span aria-hidden="true">eAudios </span>
                        <span aria-hidden="true" class="result-count">({{titles.TotalItems |thousandSuff}})</span>
                    </h2>
                    <a href="javascript:;" class="see-all primary-para" *ngIf="titles.TotalItems > 10"
                        (click)="seeAll(category.TITLES, titles.Filter.Format )" aria-label="See All e Audios">See
                        All<mat-icon svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
                </div>
                <axis360-titles-carousel [addCard]="true" [isProgramCTAShow]="isProgramCTAShow"
                    [titlesList]="titles.Items" [searchResult]="searchResult" [cellsToScrollNums]="[4,4,2,1]"
                    [parentRoute]="parentRoute" [isParticipant]="true">
                </axis360-titles-carousel>
            </div>
        </ng-container>
        <ng-container *ngIf="layoutComponents?.length > 0">
            <div class="web-resource-panel mt-2" *ngFor="let layout of layoutComponents; let i =index;">
                <ng-container *ngFor="let component of layout?.components; let i =index;">
                    <axis360-third-party-carousel-type [componentData]="component" [vendorCode]="layout.vendorCode"
                        [parentRoute]="parentRoute" [pageTitle]="component.title" [isShowCount]="true"
                        (count)="emitCount($event)">
                    </axis360-third-party-carousel-type>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="learningActivityInfo?.data.length > 0">
            <div class="search-carousel-sec mt-2">
                <div class="carousel-title-group btn-group">
                    <h2 class="carousel-title heading-2 no-transform"
                        attr.aria-label="Resource Hub count {{learningActivityInfo?.totalCount}}">
                        <span aria-hidden="true">Resource Hub </span>
                        <span aria-hidden="true" class="result-count">({{learningActivityInfo?.totalCount}})</span>
                    </h2>
                    <a href="javascript:;" class="see-all primary-para" *ngIf="learningActivityInfo?.data.length > 9"
                        (click)="seeAll(category.ACTIVE_RESOURCES)" aria-label="See All Resource Hub">See All<mat-icon
                            svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
                </div>
            </div>
            <axis360-kz-learning-activities-carousel [learningActivityInfo]="learningActivityInfo?.data"
                [parentRoute]="parentRoute">
            </axis360-kz-learning-activities-carousel>
        </ng-container>
        <ng-container *ngIf="sitesTitlesInfo?.length > 0">
            <div class="search-carousel-sec mt-2">
                <div class="carousel-title-group btn-group">
                    <h2 class="carousel-title heading-2 no-transform"
                        attr.aria-label="Web Resources count {{totalSitesCount}}">
                        <span aria-hidden="true">Web Resources </span>
                        <span aria-hidden="true" class="result-count">({{totalSitesCount}})</span>
                    </h2>
                    <a href="javascript:;" class="see-all primary-para" *ngIf="sitesTitlesInfo?.length > 9"
                        (click)="seeAll(category.WEB_RESOURCES)" aria-label="See All Web Resources">See All<mat-icon
                            svgIcon="kz-right-arrow" class="kz-right-arrow"></mat-icon></a>
                </div>
            </div>
            <div class="web-resource-panel">
                <axis360-kz-web-resources-carousel [titleCardsInfo]="sitesTitlesInfo">
                </axis360-kz-web-resources-carousel>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="enablePurchaseRequest && !isProgramCTAShow">
        <p class="primary-para mt-3"
            *ngIf="(!spinnerService.isLoading && searchService.totalCount !== 0) && !isTitletoRecommend">
            Suggest a Purchase –
            <a href="javascript:void(0);" class="primary-link kz-purchase-link" (click)="searchPurchaseRequest()">
                <span>Search</span></a> titles that you can request to your library for purchase.
        </p>
    </ng-container>
    <ng-container *ngIf="configService.currentLibrary.isPhysitalEnabled && !isTitletoRecommend">
       <ng-container *ngIf="showPhygitalLink && phygitalUrlData.ilsLinkText">
        <p  class="primary-para mt-3"> <a href="javascript:;"  role="link" attr.aria-label="{{phygitalUrlData.ilsLinkText}}, opens in a new tab" (click)="callPhygitalLibrary()"  class="primary-link kz-purchase-link new-tab-link">{{phygitalUrlData.ilsLinkText }}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.25 2.75H2.75C1.7835 2.75 1 3.5335 1 4.5V13.25C1 14.2165 1.7835 15 2.75 15H11.5C12.4665 15 13.25 14.2165 13.25 13.25V9.75M9.75 1H15M15 1V6.25M15 1L6.25 9.75" stroke="#00508B" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
        </a> – {{phygitalUrlData.ilsHelpText}}</p>
       </ng-container>       
    </ng-container>
</div>
<axis360-spinner-loader [isLoading]="true"
    *ngIf="!(searchService.apiCompletedCount >= searchService.apiCount)"></axis360-spinner-loader>
