import { ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { IAction } from 'app/third-party/shared/data/third-party-model';
import { ThirdPartyService } from 'app/third-party/shared/services/third-party.service';
import { ThirdPartyBaseComponent } from '../../../third-party/shared/components/third-party-base/third-party-base.component';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'axis360-third-party-cards',
  templateUrl: './third-party-cards.component.html',
  styleUrls: ['./third-party-cards.component.scss']
})
export class ThirdPartyCardsComponent extends ThirdPartyBaseComponent {
  @Input() thirdPartyClass: string;
  @Input() vendorCode = '';
  @Input() parentRoute;
  @Input() carouselClass = '';
  @Input() myStuffThirdParty: string;
  @Input() iscarousel = false;

  constructor(
    public datePipe: DatePipe,
    public cdref: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public thirdPartyService: ThirdPartyService,
    private elRef: ElementRef) {
    super(datePipe, cdref, activatedRoute, thirdPartyService);
  }
  executeAction(action: IAction) {
    if (!action?.data?.type) {
      return;
    }
    this.thirdPartyService.cardAction(action, this.vendorCode, this.parentRoute);
  }
  setAriaLabel() {
    const elm = this.elRef.nativeElement.querySelector('.parent-elm');
    const { article, publication, tabName, DisplayStatus, altLabel, ItemId, formatType } = (this.componentData as any);
    if (formatType?.toUpperCase() === 'VIDEO')
    { this.componentData.altLabel = altLabel.replace('Format Videobook', 'Format Video'); }   
    let title = elm.children[0]?.children[0]?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
    if (!title && this.isCurrentlyCheckout) {
      const element = elm.children[0]?.children[0]?.children[0];
      elm.children[0]?.children[0]?.removeAttribute('title');
      elm.children[0]?.children[0]?.children[0]?.children[0]?.removeAttribute('title');
      const bookLabel = DisplayStatus !== '' ? altLabel
        : element?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      title = bookLabel;
    };    
    const setBookTitle = altLabel ? altLabel : title;
    const eleSetValue = (elm.children[0].getAttribute('aria-label') && elm?.children[0].getAttribute('aria-label') !== 'undefined ');
    elm?.setAttribute('aria-label', eleSetValue ? elm?.children[0]?.getAttribute('aria-label') : setBookTitle?.replace(/<[^<>]*?>/g, ''));
    if (!elm?.children[0].getAttribute('aria-label') && article?.title !== undefined) {
      const ariaTitle = article?.altLabel || publication?.title
      elm?.setAttribute('aria-label', ariaTitle.replace(/<[^<>]*?>/g, ''));
      elm?.removeAttribute('tabindex');
      return;
    }
    if (elm?.children[0].getAttribute('aria-label') === null && tabName) {
      const element = elm.children[0]?.children[0]?.children[0];
      const bookLabel = DisplayStatus !== '' ? altLabel
        : element?.children[2]?.children[0]?.children[0]?.children[0]?.getAttribute('alt');
      const bookAltTitle = bookLabel ? bookLabel : altLabel;
      element?.setAttribute('aria-label', bookAltTitle);
      element?.setAttribute('id', ItemId);
      element?.setAttribute('role', 'link');
      element?.children[0]?.removeAttribute('title');
      elm.children[0]?.children[0]?.setAttribute('title', bookAltTitle.replace(/<[^<>]*?>/g, ''));
      elm.children[0]?.children[0]?.removeAttribute('title');
      // elm.children[0].setAttribute('aria-label', bookAltTitle);
    }
    // elm?.children[0].setAttribute('aria-hidden', 'true');
    elm?.children[0]?.removeAttribute('aria-label');
  }
}
