<ng-container *ngIf="isCurrentlyCheckout; else link">
  <div class="third-party-id my-stuff-id-{{componentData.ItemId}}" >
  <a tabindex="0" [href]="getPlayerURLLink()" class="card-link  {{carouselClass}}" target="_blank" aria-hidden="true"
[attr.aria-label]="setAriaLabel()" class="parent-elm" rel="noopener">

    <div class="{{thirdPartyClass}}" #adaptiveCardSection></div>
  </a>
  </div>
</ng-container>

<ng-template #link>
  <ng-container *ngIf="myStuffThirdParty === 'my-stuff'; else notMyStuffThirdParty">
    <div class="third-party-id my-stuff-id-{{componentData.ItemId}}">
      <a tabindex="0" class="card-link  {{carouselClass}}" [routerLink]="routerLinkURL" aria-hidden="true"
         [attr.aria-label]="setAriaLabel()" [queryParams]="getRouterParams(vendorCode)" class="parent-elm">
        <div class="{{thirdPartyClass}}" #adaptiveCardSection></div>
      </a>
    </div>
  </ng-container>
  <ng-template #notMyStuffThirdParty>
    <a tabindex="0" class="card-link  {{carouselClass}}" id="loc_title_{{itemId?itemId:componentData.id}}"
       [routerLink]="routerLinkURL"
       [attr.aria-label]="setAriaLabel()" [queryParams]="getRouterParams(vendorCode)" class="parent-elm">
      <div class="{{thirdPartyClass}}" #adaptiveCardSection></div>
    </a>
  </ng-template>
</ng-template>
