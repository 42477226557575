import { Component, OnDestroy, OnInit, ViewChild, AfterViewChecked} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { ACTION_BUTTON_DATA, FORMAT_ICON_MAP, SHIMMER_COMPONENTS, SNAKE_BAR_MODE } from '@shared/constants/app.constants';
import { cdkRemoveVisualHidden, cdkVisualHidden, deepClone, focusElement, forceRedirect, formatHoldPosition, getBookIcon, getTitleFormatType, setFocusToText } from '@shared/helper/app.util';
import { CommonService } from '@shared/services/common.service';
import { SharedService } from '@shared/services/shared.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import {
  AGE_RANGE,
  COMING_SOON,
  EMAIL_SHARE_LINK,
  FACEBOOK_SHARE_LINK,
  FORMAT_TYPE_MAP,
  HIDE_TABS_DETAILS,
  PAGE_NUMBER,
  PAGE_SIZE,
  REVIEW_ALERT,
  SHARE_ACTIONS_BUTTON,
  SHARE_BASE_URL,
  SOCIAL_MEDIA_LIST,
  TITLE_DETAILS_TABS_KEY,
  TITLE_REVIEWS_TEST,
  TWITTER_SHARE_LINK,
  UNLIMITED_COPIES,
  WITH_OUT_INTERNET_MSG_RATING,
  WITH_OUT_INTERNET_MSG_REVIEW
} from './shared/constants/title-details.constants';
import {
  ITitleDetails,
  ITitleDetailsRating,
  IButtonDetails,
  ITitleDetailsActions,
  IOtherUserReview,
  ISubjectList,
  ILearningActivityData,
  IRecommendTitle,
  IBookDetail,
  ITitleActionsContent,
  IShareActionButton,
  ITitleDetailsWithActions
} from './shared/data/title-details.model';
import { TitleDetailsAnalyticsService } from './shared/services/title-details.analytics.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { TitleDetailsService } from './shared/services/title-details.service';
import { Subscription } from 'rxjs';
import { InfiniteScrollService } from '@shared/services/infinite-scroll.service';
import { CHECKOUT_LIMIT, HOLD_POSITION_TOOLTIP, RECORDS_COUNT } from './shared/data/mock/title-details.mock';
import { ConfigService } from '@shared/services/config.service';
import { TITLE_DETAILS_EVENTS_KEY } from './shared/constants/title-details.analytics.constants';
import { UserService } from '@shared/services/user.service';
import { AccountService } from '@shared/services/account.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountComponent } from '../account/account.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BookDetail } from '@shared/data/engage-base.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { OverlayContainer } from '@angular/cdk/overlay';
import { KzSearchService } from '../kz-search-results/shared/services/kz-search.service';
import { Title } from '@angular/platform-browser';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'axis360-title-details',
  templateUrl: './title-details.component.html',
  styleUrls: ['./title-details.component.scss']
})
export class TitleDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  isbn: string;
  itemId: string;
  defaultSelectedTabs: string = 'Details';
  navUrl: string;
  titleDetailsData: ITitleDetails;
  titleButtonViews: IButtonDetails;
  isCopiesLoad = false;
  titleButtonCTA: IButtonDetails;
  isRecommendable: boolean;
  showParagraph: boolean = false;
  avgStarRating: number = 0;
  reviewsCount: number = 0;
  totalReviewsCount: number = 0;
  userName: string;
  titleDetailsActions: ITitleDetailsActions;
  titleDetailsRating: ITitleDetailsRating;
  synopsisSubstring: string;
  isLoading = true;
  titleError = false;
  reviewList: IOtherUserReview[];
  matLabel: string;
  recordsCount = RECORDS_COUNT;
  CHECKOUT_LIMIT = CHECKOUT_LIMIT;
  isPPCOnlyLibrary: boolean;
  HOLD_POSITION_TOOLTIP = HOLD_POSITION_TOOLTIP;
  holdPositionFormat: string;
  offset = 0;
  scrollSubscription: Subscription;
  upDateButtonViewSubscriptions: Subscription;
  patronReviewsEnabled: boolean;
  relatedItemTab: boolean = false;
  moreLikeItemTab: boolean = false;
  learningActivityBooks: ILearningActivityData[] = [];
  alternateFormatTitle: IBookDetail[] = [];
  pageSize = PAGE_SIZE;
  pageNumber = PAGE_NUMBER;
  forceRedirect = forceRedirect;
  setFocusToText: any = setFocusToText;
  formatHoldPosition = formatHoldPosition;
  getTitleFormatType = getTitleFormatType;
  recommendedTitleDetails: IRecommendTitle[];
  titleContent: BookDetail;
  titleActionContent: ITitleActionsContent;
  shareActionsButton: IShareActionButton;
  ACTION_BUTTON_DATA = ACTION_BUTTON_DATA;
  @ViewChild('tabGroup') tabGroup;
  realatedItemLoader: boolean = false;
  moreLikeItemLoader: boolean = false;
  titleActions: boolean = false;
  isProgressBarShown = false;
  COMING_SOON = COMING_SOON;
  UNLIMITED_COPIES = UNLIMITED_COPIES;
  titleDetailsDataWithActions: ITitleDetailsWithActions;
  compName = SHIMMER_COMPONENTS.TITLE_DETAILS_MORE_INFO;
  compNameCopies = SHIMMER_COMPONENTS.TITLE_DETAILS_COPIES_INFO;
  compNameButtons = SHIMMER_COMPONENTS.TITLE_DETAILS_BUTTONS;
  browserHistory: number;
  isVisibleMobile = false;
  bookTitle = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private titleDetailsService: TitleDetailsService,
    public commonService: CommonService,
    private sharedService: SharedService,
    public searchService: KzSearchService,
    private spinnerService: SpinnerService,
    public config: ConfigService,
    public userService: UserService,
    public snackBarService: SnackBarService,
    private infiniteScroll: InfiniteScrollService,
    public profileInfoService: ProfileInfoService,
    public accountService: AccountService,
    public matDialog: MatDialog,
    public titleDetailsAnalyticsService: TitleDetailsAnalyticsService,
    public breadcrumbService: BreadcrumbService,
    public clipboard: Clipboard,
    public overlayContainer: OverlayContainer,
    public titleService: Title,
    public liveAnnouncer: LiveAnnouncer,
  ) { }

  /**
   * To init home components
   */

  ngOnInit(): void {
    focusElement('loc_headerId');
    this.browserHistory = window.history.length;
    this.commonService.titleBreadCrumb = true;
    // Call cdkVisualHidden after 4 seconds
    setTimeout(() => {
      cdkVisualHidden();
    }, 2000);
    this.activatedRoute.params.subscribe((param) => {
      if (this.config.currentLibrary.consortia?.consortiaType?.toLowerCase() === 'parent') {
        this.forceRedirect(`/Consortia/Picklibrary?returnurl=/ng/view/library/title/${param.itemId}`);
        return;
      }

      const titleItemDetails = this.titleDetailsService.itemsTitleDetails;
      if (!navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/i)) {
        this.titleService.setTitle('Title Detail');
      }
      if (navigator.userAgent.match(/(Android|iPhone|iPad|iPod)/i)) {
        this.titleService.setTitle('');
      }
      !titleItemDetails && (this.isLoading = true);
      if (titleItemDetails) {
        const bookTitle = titleItemDetails.Title || titleItemDetails.title;
        this.bookTitle = `${bookTitle} Title Detail Page`
        this.titleService.setTitle(this.bookTitle);
        this.liveAnnouncer.announce(`${this.bookTitle} Title Detail Page`, 'assertive');
        document.getElementById('isDetailPage')?.removeAttribute('tabIndex');
        setTimeout(() => {
          this.commonService.toStopAnnoucedPage(false, false, false, false, false, true);
        }, 4000);
        this.isLoading = false;
        this.titleDetailsData = {
          author: [titleItemDetails.Author || titleItemDetails.author],
          narrator: [''],
          synopsis: '',
          title: titleItemDetails.Title || titleItemDetails.title,
          subTitle: ''
        };
      }
      this.titleDetailsActions = undefined;
      this.defaultSelectedTabs = null;
      this.recommendedTitleDetails = [];
      this.moreLikeItemTab = false;
      this.isbn = param.isbn;
      this.itemId = param.itemId;
      this.titleDetailsService.setUpdateButtonView(null);
      this.titleButtonViews = undefined;
      this.isCopiesLoad = false;
      this.userName = this.profileInfoService.profileInfo.profileName;
      this.isRecommendable = false;
      this.scrollSubscription = this.infiniteScroll.subscribe(this.loadMore);
      this.isPPCOnlyLibrary = this.config.getIsPPCOnlyLibrary(this.profileInfoService.currentProfile.profileType.toLowerCase());
      this.getDetailsData();
      this.config.setBanner(true);
      this.upDateButtonViewSubscriptions = this.titleDetailsService.getUpdateButtonView().subscribe((updateCTA) => {
        if (!updateCTA) {
          this.isCopiesLoad = true;
          return;
        }
        this.titleButtonCTA = deepClone(updateCTA);
        this.titleButtonViews = updateCTA;
        this.isCopiesLoad = true;
        this.holdPositionFormat = this.formatHoldPosition(updateCTA.availability.holdPosition);
      });
    });
  }
  /**
   * Unsubscribe the ScrollSubscriptions Destory
   */
  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this.upDateButtonViewSubscriptions && this.upDateButtonViewSubscriptions.unsubscribe();
    sessionStorage.removeItem('breadcrumb');
    // sessionStorage.setItem('titleListFilters', JSON.stringify({}));
    cdkRemoveVisualHidden();
  }
  onResize() {
    if (window.innerWidth <= 560) {
      this.isVisibleMobile = true;
    } else {
      this.isVisibleMobile = false;
    }
  }

  /**
   * getDetailsData
   *
   */
  async getDetailsData() {
    try {
      this.defaultSelectedTabs = 'Details';
      await Promise.all([this.getTitleDetails()]);
      this.titleDetailsActions = { titleButtonDetails: this.titleButtonViews, titleDetails: this.titleDetailsData };
      this.patronReviewsEnabled = this.config.currentLibrary.reviews.patronReviewsEnabled;
      Promise.all([this.getTitleReview(), this.getAllUserReviews(this.recordsCount, this.offset)]);
      this.getLearningActivityByISBN();
      this.getTitleAlternateFormat();
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.titleDetailsActions = undefined;
      this.titleError = true;
    }
  }

  /**
   * To get title details from API
   */
  async getTitleDetails() {
    this.titleActions = false;
    this.moreLikeItemLoader = false;
    this.titleDetailsDataWithActions = await this.titleDetails();
    const titleDetailsData = this.titleDetailsDataWithActions?.bookModel;
    this.isbn = titleDetailsData.id;
    if (titleDetailsData) {
      this.titleDetailsData = titleDetailsData;
      if (!this.titleDetailsService.itemsTitleDetails) {
        this.titleService.setTitle(`${this.titleDetailsData.title} Title Detail Page`);
        this.liveAnnouncer.announce(`${this.titleDetailsData.title} Title Detail Page`, 'assertive');
        this.bookTitle = `${this.titleDetailsData.title} Title Detail Page`;
        document.getElementById('isDetailPage')?.removeAttribute('tabIndex');
        setTimeout(() => {
          this.commonService.toStopAnnoucedPage(false, false, false, false, false, true);
        }, 4000);
      }
      this.titleDetailsService.itemsTitleDetails = undefined;
      this.titleDetailsService.titleDetailsActions = this.titleDetailsDataWithActions.titleAvailabilityInfo;
      this.getButtonViews(this.titleDetailsDataWithActions.titleAvailabilityInfo);
      const { itemId, id, subject, genre, author, formatType } = titleDetailsData;
      const collectionType = this.titleDetailsDataWithActions?.titleAvailabilityInfo?.availability?.collectionType || '';
      this.titleDetailsService
        .getRecommendedTitleDetails(itemId, id, subject, genre, author.toString(), this.isRecommendable, formatType, collectionType)
        .then((recommendedTitleDetails) => {
          this.recommendedTitleDetails = recommendedTitleDetails;
          this.titleDetailsService.recommendedTitleBooks = undefined;
          this.titleDetailsService.recommendedSeriesTitleBooks = undefined;
          if (recommendedTitleDetails?.length) {
            this.moreLikeItemTab = true;
          }
          this.moreLikeItemLoader = false;
        }, (() => {
          this.moreLikeItemLoader = false;
          this.titleDetailsService.recommendedTitleBooks = undefined;
          this.titleDetailsService.recommendedSeriesTitleBooks = undefined;
        }));
      this.synopsisSubstring = titleDetailsData.synopsis.substring(0, 358);
      const FormatType = this.getTitleFormatType(titleDetailsData.formatType);
      this.titleDetailsData.formatType = FormatType;
      this.breadcrumbService.set('title/:itemId/:isbn', this.titleDetailsData.title);
      this.getBreadCrumbs();
    }
  }

  /**
   * Gets the list of button views for this title.
   */
  getButtonViews(buttonViewData: IButtonDetails) {
    if (buttonViewData) {
      this.titleButtonViews = buttonViewData;
      this.isCopiesLoad = true;
      this.titleButtonCTA = buttonViewData;
      this.titleDetailsService.setTitleCheckOutOrNot(buttonViewData.actions.filter(action => action.actionButtonId === 'btnCheckoutNow').length > 0);
      this.holdPositionFormat = this.formatHoldPosition(buttonViewData.availability.holdPosition);
      const { availability } = this.titleButtonViews || {};
      const { isRTV, totalQuantity, collectionType } = availability || {};
      const { id, itemId, title, author: authors, narrator: narrators, formatType, publisher, series } = this.titleDetailsData || {};
      this.isPPCOnlyLibrary = this.config.getIsPPCOnlyLibrary(this.profileInfoService.currentProfile.profileType.toLowerCase());
      this.shareActionsButton = { ...SHARE_ACTIONS_BUTTON, formatType: formatType, isbn: id, itemId: itemId };
      this.titleContent = {
        ISBN: id,
        ItemId: itemId,
        title,
        isbn: id,
        itemId,
        FormatType: formatType,
        format: formatType ? this.getTitleFormatType(formatType) : '',
        formatType,
        author: authors[0],
        authors,
        Narrator: narrators,
        IsAvailable: true,
        isRTV,
        collectionType,
        IsRecommendable: false,
        TotalQuantity: totalQuantity,
        publisher,
        series,
        titleStateInfo: { ...this.titleButtonViews }
      };
      this.titleActions = true;
    } else {
      this.titleContent = {} as any;
      this.titleButtonViews = {} as any;
      this.titleActions = false;
      this.isCopiesLoad = true;
    }
  }

  /**
   * Switch type base on social media options by clicking Share icon
   * @param [type] Pass by facebook,twitter,email,copyLink
   */
  shareTitleSocialMedia(type: string) {
    let searchParams = new URLSearchParams();
    const shareUrl = SHARE_BASE_URL.replace('<originUrl>', `${window.origin}`)
      .replace('<itemId>', `${this.titleContent.itemId}`)
      .replace('<isbn>', `${this.titleContent.isbn}`);
    const { FACEBOOK, TWITTER, EMAIL, COPY_LINK } = SOCIAL_MEDIA_LIST;
    switch (type) {
      case FACEBOOK:
        searchParams.set('u', shareUrl);
        this.navUrl = FACEBOOK_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case TWITTER:
        searchParams.set('url', shareUrl);
        this.navUrl = TWITTER_SHARE_LINK.replace('<params>', searchParams.toString());
        window.open(this.navUrl, '_blank');
        break;
      case EMAIL:
        {
          const mailText = EMAIL_SHARE_LINK.replace('<subject>', this.titleContent.title).replace('<body>', encodeURIComponent(shareUrl));
          window.open(mailText);
        }
        break;
      case COPY_LINK:
        this.clipboard.copy(shareUrl);
        this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, 'Link has been copied to clipboard');
        break;
      default:
        break;
    }
  }

  /**
   * getSubjectListObject action button
   * @param [subject] pass the Object of Subject list to split the key and value
   * @returns
   */
  getSubjectListObject(subject: ISubjectList) {
    const keyArray = Object.keys(subject);
    const subjectList = [];
    for (const key of keyArray) {
      subjectList.push(subject[key]);
    }
    return subjectList;
  }

  /**
   *  Track by Title details Actions in href values
   * @param action  Selected action to pass
   * @param value   set the value in pass the action
   */
  trackByTitleDetailsActions(action: keyof typeof TITLE_DETAILS_EVENTS_KEY) {
    this.showParagraph ? focusElement('less') : focusElement('more');
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action);
  }

  /**
  *  Track by Title details Actions in href values
  * @param action  Selected action to pass
  * @param value   set the value in pass the action
  */
  trackByTitleDetailsActionsLike(action: keyof typeof TITLE_DETAILS_EVENTS_KEY, value: string) {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent(action, { actionName: value });
    this.searchService.updatedSearchText.next(value);
  }

  /**
   * Get the breadcrumbs.
   */
  getBreadCrumbs() {
    const b = sessionStorage.getItem('breadcrumb')
    if (b) {
      this.commonService.breadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumb'))
      let isSameTitle = this.commonService.breadcrumbs.find((breadcrumb) => breadcrumb.text === this.titleDetailsData.title)
      if (!isSameTitle) { sessionStorage.removeItem('breadcrumb'); this.getBreadCrumbs() }
    }
    else {
      this.breadcrumbService.breadcrumbs$.subscribe((response: any) => {
        if (response.filter(res => res.info === 'titledetail').length > 0 && this.commonService.titleBreadCrumb) {
          this.commonService.breadcrumbs = [];
          const homePage = sessionStorage.getItem('home') ? sessionStorage.getItem('home') : 'Library';
          const url = homePage === 'Library' ? '/view/library' : '/view/myshelf'
          response.forEach((res) => {
            try {
              let routeLink = res.label === 'Title' ? this.commonService.previousUrl : res.routeLink
              const routeText = res.label === 'history' ? "Checkout History" : res.label;

              const breadcrumbData = {
                text: routeText,
                url: routeLink,
                queryParams: res.queryParams ? res.queryParams : this.checkRoute(res.queryParams)
              }
              this.commonService.breadcrumbs.push(breadcrumbData)
            }
            catch (error) { };
          })
          this.commonService.breadcrumbs[0] = { text: homePage, url: url, queryParams: undefined }
          let unique = this.commonService.breadcrumbs.filter((set => f => !set.has(f.text) && set.add(f.text))(new Set));
          this.commonService.breadcrumbs = unique;
          sessionStorage.setItem("breadcrumb", JSON.stringify(this.commonService.breadcrumbs));
          sessionStorage.setItem('breadcrumbDetail', JSON.stringify(this.commonService?.breadcrumbs[1]?.queryParams));
        }
      });
    }
  }
  checkRoute(queryParams: any) {
    const url = window.location.href;
    return (url.includes('search/title/') || url.includes('title/list/') || url.includes('title/alwaysavailable')
      || url.includes('content/list/') || url.includes('title/subject')
    )
      && sessionStorage.getItem('breadcrumbDetail') !== 'undefined'
      ? JSON.parse(sessionStorage.getItem('breadcrumbDetail')) : queryParams;
  }
  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType: string) {
    return getBookIcon(`KZ_${formatType}`);
  }

  /**
   * Returns the icon for the given format type.
   * @param formatType format type
   * @returns icon name
   */
  getFormatType(formatType: string) {
    return FORMAT_TYPE_MAP[FORMAT_ICON_MAP[formatType]?.icon];
  }

  /**
   * get All User Reviews
   * @param [recordsCount]
   * @param [offSet]
   */
  async getAllUserReviews(recordsCount: number, offSet: number) {
    this.spinnerService.showLoader();
    this.titleDetailsService.getAllUserReviews(this.itemId, recordsCount, offSet).subscribe((reviewUserList) => {
      if (!reviewUserList) {
        return;
      }
      this.spinnerService.showLoader(false);
      if (offSet === 0) {
        const firstName = this.extractFirstName(reviewUserList.userReview?.userName);
        const displayName = firstName ? firstName : 'Anonymous';
        const isReviewApproved = reviewUserList.userReview?.isReviewApproved;
        if (displayName !== 'Anonymous') {
          this.processReview(isReviewApproved, reviewUserList);
          reviewUserList.userReview.userName = displayName;
          isReviewApproved === '1' ? reviewUserList.otherUserReview.unshift(reviewUserList.userReview) : reviewUserList.otherUserReview;
        }
        reviewUserList.otherUserReview = [...new Map(reviewUserList.otherUserReview.map(item => [item['id'], item])).values()];
        reviewUserList.otherUserReview.forEach(review => {
          const fName = this.extractFirstName(review.userName);
          review.userName = fName ? fName : 'Anonymous';
        });
        this.reviewList = reviewUserList.otherUserReview;
        this.titleDetailsService.setReviewSubject(this.reviewList);
        this.reviewsCount = reviewUserList.otherUserReview?.length;
        this.totalReviewsCount = this.reviewList.length;
        return;
      }
      reviewUserList.otherUserReview?.sort((a, b) => {
        return (new Date(b.reviewDate) as any) - (new Date(a.reviewDate) as any);
      });

      reviewUserList.otherUserReview.forEach(review => {
        const fName = this.extractFirstName(review.userName);
        review.userName = fName ? fName : 'Anonymous';
      });
      reviewUserList.otherUserReview = [...new Map(reviewUserList.otherUserReview.map(item => [item['id'], item])).values()];
      this.reviewList?.push(...reviewUserList.otherUserReview);
      this.totalReviewsCount = this.reviewList?.length;
      this.reviewsCount = reviewUserList.otherUserReview?.length;
    });
  }
  extractFirstName(displayName: string): string {
    if (!displayName || /^\s*$/.test(displayName) || /^[!@#$%^&*(),.?":{}|<>]+$/g.test(displayName) || /^(?:Primary|Teen|Kid)$/i.test(displayName)) {
      return ''; 
    }
    const firstName = displayName.split(' ')[0];
    return firstName;
  }

  /**
   * Process Review
   * @param [isReviewApproved] Pass to string value
   * @param [reviewUserList] Pass title user review List
   * @returns
   */
  processReview(isReviewApproved: string, reviewUserList: { otherUserReview: any[]; userReview: any; }) {
    reviewUserList.otherUserReview.sort((a, b) => {
      return (new Date(b.reviewDate) as any) - (new Date(a.reviewDate) as any);
    });
    
    return isReviewApproved === '1' ?
      reviewUserList.otherUserReview.unshift(reviewUserList.userReview) :
      reviewUserList.otherUserReview;
  }

  /**
   * To verify can load more or not.
   * @returns returns can load more or not
   */
  canLoadMore = (): boolean => !this.spinnerService.isLoading && this.reviewsCount < RECORDS_COUNT && this.reviewsCount >= 0;

  /**
   * Load more content
   * @returns returns can load more or not
   */
  loadMore = () => {
    if (this.canLoadMore()) {
      return;
    }
    this.offset = this.offset + RECORDS_COUNT;
    this.getAllUserReviews(this.recordsCount, this.offset);
  };

  /**
   * Check the Patron Review in title details
   * @returns PatronReviews data
   */
  async checkPatronReview() {
    return await this.titleDetailsService.getPatronReviews(this.itemId);
  }

  /**
   * To get book Reviews from getTitleReview API
   */
  async getTitleReview() {
    if (!this.itemId) {
      return;
    }
    this.titleDetailsRating = undefined;
    const {
      itemId,
      title,
      language,
      languageDesc,
      publicationDate,
      audience,
      length,
      axisAttribute,
      axisAttributeList,
      subjectList,
      bookLength,
      formatType,
      attribute,
      runTime
    } = this.titleDetailsData || {};
    const subjectNameList = this.getSubjectListObject(subjectList);
    this.titleDetailsService.getAllUserRatings(this.itemId).then((bookReviewResponse) => {
      if (!bookReviewResponse) {
        return;
      }
      const { totalRatingCount, averageRating, userReview, otherUserReview } = bookReviewResponse;

      this.titleDetailsRating = {
        itemId,
        title,
        language,
        languageDesc,
        publicationDate,
        ageRange: AGE_RANGE[audience],
        length,
        formatType: this.titleDetailsData ? this.getIcon(formatType) : { icon: '', alt: '' },
        formatTypeName: this.titleDetailsData ? this.getFormatType(formatType) : '',
        reviewsCount: totalRatingCount,
        userAvgStarRating: userReview?.rating,
        otherUserReviewCount: otherUserReview.length || 0,
        avgStarRating: averageRating ? Number(averageRating.toFixed(1)) : 0,
        axisAttribute: axisAttribute,
        axisAttributeList: axisAttributeList,
        axisAttributeIndex: axisAttributeList.findIndex((x) => x.attribute === 'eRead-Along'),
        patronReviewsEnabled: this.patronReviewsEnabled,
        subjectName: subjectNameList[0].subject,
        bookLength,
        attribute: attribute.includes('Enabled') ? 'Text to Speech' : attribute,
        runTime
      };
    }, () => {
      this.titleDetailsRating = TITLE_REVIEWS_TEST;
    });
  }

  /**
   * Open the review Popup to Write the Review on title
   */
  async writeReview() {
    if (!this.userService.userInfo.isLoggedIn) {
      this.openLoginModel();
      return;
    }
    await this.spinnerService.withPromise(this.checkPatronReview()).then((res) => {
      this.commonService.showAlertInputDialog({ ...REVIEW_ALERT, submitBtnFunc: this.handleReview, patronReviewDetails: res });
      this.titleDetailsAnalyticsService.trackReviewEvent('WRITE_A_REVIEW');
    });
  }
  /**
   * Submits a review in title
   */
  handleReview = () => {
    const reviewContent = this.sharedService.getInputAlertDialogValue();
    this.spinnerService
      .withPromise(this.titleDetailsService.submitReview(this.itemId, reviewContent.value, new Date().toISOString(), this.userName, '0'))
      .then(
        (res) => {
          if (!res.submitted) {
            this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, res.msg);
            return;
          }
          this.titleDetailsAnalyticsService.trackReviewEvent('SUBMIT_REVIEW');
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.THANK_YOU, res.msg);
        },
        () => {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, WITH_OUT_INTERNET_MSG_REVIEW);
        }
      );
  };

  /**
   * Save the Title rating in submitReview API
   */
  async handleRating(rating: string) {
    if (rating.length === 0) {
      return;
    }
    await this.spinnerService
      .withPromise(this.titleDetailsService.submitRating(this.itemId, '', new Date().toISOString(), this.userName, rating))
      .then(
        (res) => {
          if (!res.ratingReviewResponse.ratingSubmitted) {
            this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, res.message);
            return;
          }
          const { profileType } = this.profileInfoService.currentProfile || {};
          const { totalRatingCount, otherUserReview, ratingSubmitted, averageRating } = res.ratingReviewResponse;
          this.titleDetailsAnalyticsService.trackRatingEvent('RATING');
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.INFO, `Thanks for adding your rating!.`);

          this.titleDetailsService.setUserRatingTitle({
            avgStarRating: Number(averageRating.toFixed(1)),
            reviewLength: totalRatingCount,
            userAvgStartRating: Number(rating),
            reviews: otherUserReview,
            otherUserReviewCount: otherUserReview.length || 0,
            submitted: ratingSubmitted,
            profileType: profileType
          });
        },
        () => {
          this.snackBarService.showSnackBar(SNAKE_BAR_MODE.ERROR_UH_OH, WITH_OUT_INTERNET_MSG_RATING);
        }
      );
  }

  /**
   * To be called on selecting tab to change the active tab
   */
  selectedTabChange = (matTabEvent: MatTabChangeEvent) => {
    if (matTabEvent.tab) {
      this.titleDetailsAnalyticsService.trackTitleDetailsEvent(TITLE_DETAILS_TABS_KEY[matTabEvent.tab.textLabel]);
      this.matLabel = matTabEvent.tab.textLabel;
      this.defaultSelectedTabs = matTabEvent.tab.textLabel;
    }
  };
  openLoginModel() {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true
            }
          })
          .afterClosed();
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }

  async getTitleAlternateFormat() {
    const titleAlternateFormat = await this.titleDetailsService.getTitleAlternateFormat(this.isbn, this.itemId);
    if (titleAlternateFormat.statusCode !== 200) {
      this.relatedItemTab = !this.relatedItemTab;
      return;
    }
    this.alternateFormatTitle = titleAlternateFormat.alternateResultModel?.data;
  }
  async getLearningActivityByISBN() {
    this.realatedItemLoader = true;
    const { profileType } = this.profileInfoService.currentProfile || {};
    const learningActivityData = {
      ISBN: this.isbn,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      profileType: profileType
    };
    this.titleDetailsService.getLearningActivityByISBN(learningActivityData).then((learningActivityBooks) => {
      if (learningActivityBooks.statusCode !== 200) {
        this.learningActivityBooks = [];
        this.relatedItemTab = !this.relatedItemTab;
        return;
      }
      this.learningActivityBooks = learningActivityBooks.learningActivityIsbn?.data;
      if (this.learningActivityBooks && this.learningActivityBooks.length === 0 && !this.moreLikeItemTab) {
        this.defaultSelectedTabs = 'Details';
      }
      if (this.learningActivityBooks && this.learningActivityBooks.length !== 0) {
        if (!this.moreLikeItemTab) {
          this.defaultSelectedTabs = 'Details';
        }
      }
      this.realatedItemLoader = false;
    });
  }

  titleDetailsTabsHide(type: string) {
    const { RELATED_ITEM } = HIDE_TABS_DETAILS;
    if (type === RELATED_ITEM) {
      this.relatedItemTab = !this.relatedItemTab;
      this.defaultSelectedTabs = 'Details';
    }
  }
  resetTab() {
    setTimeout(() => {
      this.tabGroup.selectedIndex = 0;
    }, 0);
  }

  async titleDetails() {
    if (this.isbn) {
      return await this.titleDetailsService.getTitleDetails(this.itemId, this.isbn, this.isRecommendable);
    }
    return await this.titleDetailsService.getTitleDetailItem(this.itemId);
  }

  /**
  * Removes the kz - mat - menu - overlay class from the container
  * @param [state]
  * @returns string
  */

  menuState(state: boolean) {
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementsByTagName('html')[0].setAttribute('class', 'hide-scroll');
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementsByTagName('html')[0].classList.remove('hide-scroll');
  }

  /**
   * Tracks the ACTIONS_WISHLIST event.
   * And
   * Action by TitleActionHandlerWrapper
   * @param [event] get click event
   * @param [addWishlist] ACTION_BUTTON_DATA is only wishlist
   * @param [action] get button action for getButtonViews
   */
  addToWishlist() {
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent('ACTIONS_WISHLIST');
    this.titleDetailsAnalyticsService.trackTitleDetailsEvent('TITLE_PRIMARY_ACTIONS');
  }
  progressBarEvent(value: boolean) {
    this.isProgressBarShown = value;
  }

  scrollTitleReview(count) {
    if (count === 0) {
      this.writeReview();
      setTimeout(() => {
        (document.querySelector('#write_review') as HTMLElement)?.focus();
      }, 0);
      return;
    }
    if (this.defaultSelectedTabs === 'More Like This' || this.defaultSelectedTabs === 'Related Items') {
      this.defaultSelectedTabs = 'Details';
      setTimeout(() => {
        this.tabGroup.selectedIndex = 0;
        (document.querySelector('#review-btn') as HTMLElement)?.focus();
      }, 0);
      return;
    }
    (document.querySelector('#review-btn') as HTMLElement)?.focus();
  }

  synopsisMoreLess(showSynopsis, id) {
    this.showParagraph = showSynopsis;
    setTimeout(() => {
      const showSynopsisId = document.getElementById(`${id}`);
      showSynopsisId?.focus();
      showSynopsisId && showSynopsisId?.setAttribute('aria-live', 'assertive');
    }, 1000);
  }
  ngAfterViewInit() {
    window.scrollTo(0, this.commonService.scrollPostion);
    focusElement('loc_headerId');
  }
  ngAfterViewChecked(): void {
    const liveAnnouncerElement = document.querySelector('.cdk-live-announcer-element');
    if (liveAnnouncerElement) {
      liveAnnouncerElement.setAttribute('aria-hidden', 'false');
    }
  }
}
