import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListContent, BookDetail } from '@shared/data/config.model';
import { LIST_CONTENT_MOCK } from '@shared/data/mock/list.mock';
import { SearchRefinersComponent } from '@shared/components/search-refiners/search-refiners.component';
import { KzSearchService } from '../shared/services/kz-search.service';
import { TitleCardInfo } from '@search/shared/data/title-card-info-model';
import { Router } from '@angular/router';
import { ILearningActivity } from '../shared/data/search.model';
import { CAROUSEL_TITLE, CATEGORY_TYPE } from '../shared/constant/search.constant';
import { SpinnerService } from '@shared/services/spinner.service';
import { SearchAnalyticsService } from '@search/shared/services/search.analytics.service';
import { pageSize } from '@shared/constants/search.constant';
import { IThirdPartySearch } from '@shared/data/third-party-model';
import { focusElement } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
@Component({
  selector: 'axis360-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent {
  @Input() programs: string = '';
  noResult = false;
  titleContents: BookDetail[];
  collectionData: ListContent = LIST_CONTENT_MOCK;
  carouselTitle = CAROUSEL_TITLE;
  carousel = true;
  isSearchListView = true;
  filterOpened = false;
  titleCard = true;
  activityResourcesCard = false;
  webResourcesCard = true;
  @Input() searchList: any;
  @Input() studyTitlesInfo: ListContent[];
  @Input() searchby: string;
  @Input() sitesTitlesInfo: TitleCardInfo[] = [];
  @Input() learningActivityInfo: ILearningActivity;
  @Input() totalSitesCount: number;
  @Input() isTitletoRecommend: boolean = false;
  @Input() layoutComponents?: IThirdPartySearch[];
  @Input() searchResult?: boolean;
  @Input() isProgramCTAShow: boolean;
  @Input() enablePurchaseRequest: boolean;
  @Input() phygitalUrlData:any;
  @Input() showPhygitalLink:boolean = false;
  @Output() count = new EventEmitter<{ type: string; count: number }>();
  refiner = [];
  sortby: string = '';
  selectedOption: any;
  EMPTY = '';
  parentRoute = 'search';
  category = CATEGORY_TYPE;
  totalCount = 0;
  mileStoneProgram: any;

  constructor(
    public matDialog: MatDialog,
    public searchService: KzSearchService,
    private router: Router,
    public searchAnalyticsService: SearchAnalyticsService,
    public spinnerService: SpinnerService,
    public configService: ConfigService,
  ) { }
  /**
   * on changes
   */
  /**
   * Opens filter
   * @returns
   */
  ngOnInit() {
    this.mileStoneProgram = this.programs;
    const regExString = /(?:view\/)((.[\s\S]*))(?:\/search)/ig; //set ig flag for global search and case insensitive
    const regExString1 = regExString.exec(this.router.url)
    this.parentRoute = regExString1 ? regExString1[1] + '/search' : 'search';
    setTimeout(() => {
      document.getElementById('searchResultsView')?.classList.add('hidden');
    }, 1000);
  }
  openFilter() {
    if (this.filterOpened) {
      return;
    }
    this.filterOpened = !this.filterOpened;
    const searchRefinersDialog = this.matDialog.open(SearchRefinersComponent, {
      panelClass: 'filter-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'filter-title'
    });
    searchRefinersDialog.afterClosed().subscribe(() => (this.filterOpened = false));
  }
  /**
   * Navigating the Search item from carousel view  to Expanded List View based the Selected Category.
   * format is used to identity Audio or Ebook
   */
  seeAll(category: string, format: string = '') {
    setTimeout(() => {
      const matchResult = /(Android|iPhone|iPod|iPad|Macintosh)/i.exec(navigator.userAgent);
      if (matchResult) {
        focusElement('loc_btnHamburgerMenu');
      }
    }, 2000);
    if (category === this.category.TITLES) {
      this.studyTitles(format);
    } else if (category === this.category.WEB_RESOURCES) {
      this.studySites();
    } else {
      this.learningActivity();
    }
    this.searchAnalyticsService.trackSearchEvent('SEE_ALL');
  }
  /**
   * Close dialog on window resize
   */
  closeFilter() {
    if (window.innerWidth > 768 && this.filterOpened) {
      this.matDialog.closeAll();
      this.filterOpened = false;
    }
  }
  /**
   * Navigating the page to corresponding page based on category and passing corresponding queryParams
   */
  studyTitles(format: string) {
    let reqData = this.searchService.studyTitles;
    reqData.pageSize = pageSize;
    reqData.format = format;
    const links = format === 'EBT' ? this.category.EBOOKS_TITLES : this.category.EAUDIO_TITLES;
    this.router.navigate([`/view/${this.parentRoute}`, links], { queryParams: reqData });
  }
  /**
   * Studys sites
   */
  studySites() {
    let queryParams = this.searchService.studySites;
    queryParams.pageSize = pageSize;
    this.router.navigate(['/view/search', this.category.WEB_RESOURCES], { queryParams: queryParams });
  }
  /**
   * Learning activity
   */
  learningActivity() {
    let queryParams = this.searchService.learningActivity;
    queryParams.pageSize = pageSize;
    this.router.navigate(['/view/search', this.category.ACTIVE_RESOURCES], { queryParams: queryParams });
  }
  /**
   * Emits count
   * @param event
   */
  emitCount(event) {
    this.count.emit(event);
  }
  searchPurchaseRequest() {
    this.searchService.totalCount = 0;
    let reqData = this.searchService.studyTitles;
    reqData.collections = 'Titles to Recommend';
    reqData.ref = Math.random();
    this.searchService.collections = reqData.collections;
    this.router.navigate(['/view/search'], { queryParams: reqData });
  }
  callPhygitalLibrary(){
    this.spinnerService.showLoader();
    window.open(this.phygitalUrlData.ilsUrl, '_blank').focus();
    this.spinnerService.showLoader(false);
  }
}
