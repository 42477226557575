<div class="kz-interest-survey interest-{{KZ_INTEREST_SURVEY_CLASS_PROFILE[profileType]}} {{isPopUp}}"
     (window:resize)="onResize()"
    [ngStyle]="backgroundImageUrl ? {'background': 'url(' + backgroundImageUrl + ') no-repeat top center / contain, url(' + backgroundImageUrlBottom + ') no-repeat bottom center / contain'} : ''">
    <div class="bread-crum-main">
        <axis360-breadcrumb *ngIf="!popUpData" [breadcrumbs]="breadcrumbs" [breadcrumbsClass]="'kz-breadcrumb'">
        </axis360-breadcrumb>
    </div>
    <p class="kz-visually-hidden" role="alert" *ngIf="commonService.isAnnouncedPage" aria-live="polite">{{pageAnnounce}}</p>
    <div class="intersectwrapper">
        <div class="interest-heading">
            <div class="kz-close-container" *ngIf="dialogData">
                <button id="myInterestsCloseButton" cdkFocusInitial class="closeDialogButton" aria-label="close"
                    (click)="closeKzInterestsSurveyDialog()">
                    <mat-icon svgIcon="kz-close" aria-hidden="true"></mat-icon>
                </button>
            </div>
            <h1 class="interest-title" id="loc_txtSetyourReadingInterest">Set Your Reading Interests</h1>
            <p class="interest-description">The Interests you select will be used to recommend titles to you. You can
                update your Interests at any time via your Profile page.</p>
        </div>
        <axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
        <div *ngIf="!isLoading" class="interest-cards kz-interest-card" role="list">
            <ng-container *ngFor="let interest of interests; let i = index">
                <div role="listitem">
                    <div role="button"
                        class="cards-tiles {{KZ_INTEREST_SURVEY_CLASS_PROFILE[profileType]}}" tabindex="0"
                        *ngIf="interest.imageUrl" [ngClass]="{'selected': interest.selected}"
                        attr.aria-label="{{interest.interestName}}" [attr.aria-pressed]="interest.selected"
                        (click)="selectInterest(i)" (keyup.enter)="selectInterest(i)">
                        <div class="card-img">
                            <img [src]="interest.imageUrl[interest.imageUrl.length - 1]" alt="catagories" srcset="">
                        </div>
                        <div class="card-text">
                            <p class=" double-ellipsis" axis360EllipsisTitle>{{interest.interestName}}</p>
                        </div>
                    </div>
                    <div aria-live="assertive" *ngIf="isMacOrTab" aria-atomic="true" class="kz-visually-hidden">
                        {{ announcement }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="kz-interst-filter-sec" *ngIf="INTERESTS_AUDIENCE_LEVEL.KID.value !== profileType">
        <div class="interst-filter-main">
            <div class="interst-filter-col">
                <h2 class="filter-title" id="loc_preferredAgeLevel">PREFERRED AGE LEVEL(S)</h2>
                <div class="interst-filter-check">
                    <div class="filter-list" role="list" >
                        <div role="listitem" *ngFor="let aud of audience; let i = index;">
                            <mat-checkbox axis360MatCheckboxAda (change)="checkAudience($event.checked, i)"
                                class="filter-checkbox-list" id="loc_checkbox{{aud.audienceLevelName}}"
                                 [attr.aria-checked]="aud.checked"
                                [checked]="aud.checked" [disabled]="INTERESTS_AUDIENCE_LEVEL.KID.value === profileType">
                                {{aud.audienceLevelName}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kz-interst-save-btn">
        <button class="save-btn" [disabled]="!isDirty" id="loc_btnSaveinterest"
            (click)="saveInterests(true, popUpData?closePopup:redirectToPrevPage)">Save Interests</button>
        <ng-container *ngIf="popUpData;else redirect">
            <button class="save-btn back-btn" id="loc_btncancelInterest" aria-label="Cancel"
                (click)="saveInterests(false, closePopup,true)">
                Cancel
            </button>
        </ng-container>
        <ng-template #redirect>
            <button class="save-btn back-btn" id="loc_btncancelInterest" aria-label="Cancel"
                (click)="redirectToPrevPage()">
                Cancel
            </button>
        </ng-template>
    </div>
</div>