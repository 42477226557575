<axis360-spinner-loader [isLoading]="isLoading" *ngIf="isLoading"></axis360-spinner-loader>
<div [hidden]="isLoading" class="title-details-info">

    <div class="custom-table-row">
        <div class="custom-table col-lg-6 col-xs-12">
            <div class="custom-row" *ngIf="titleDetailsData?.author">
                <div class="custom-colunm book-title-head single-ellipsis">AUTHOR</div>
                <div class="custom-colunm book-title-detail">
                    <a class="primary-link" [routerLink]="['/view/search']"
                        [queryParams]="{page:1,pageSize:10,searchText:authorName,searchby:'author',q:authorName, addeddate:'',format:'All',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}"
                        *ngFor="let authorName of titleDetailsData.author;let i = index"
                        (click)="trackByTitleDetailsActions('ACTIONS_AUTHOR',authorName)">
                        {{authorName}}
                    </a>
                </div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.narrator[0].trim()">
                <div class="custom-colunm book-title-head single-ellipsis">NARRATOR</div>
                <div class="custom-colunm book-title-detail">
                    <a class="primary-link" *ngFor="let narratorName of titleDetailsData.narrator;let i = index"
                        [routerLink]="['/view/search']"
                        [queryParams]="{page:1,pageSize:10,searchText:narratorName,searchby:'author',q:narratorName,addeddate:'',format:'All',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}"
                        (click)="trackByTitleDetailsActions('ACTIONS_NARRATOR',narratorName)">
                        {{narratorName}} {{ (i < titleDetailsData.narrator.length - 1) ? ';' : '' }} </a>
                </div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.series">
                <div class="custom-colunm book-title-head single-ellipsis">SERIES</div>
                <div class="custom-colunm book-title-detail">
                    <a class="primary-link" [routerLink]="['/view/search']"
                        [queryParams]="{page:1,pageSize:10,searchText:titleDetailsData.series,searchby:'series',q:titleDetailsData.series,addeddate:'',format:'All',agelevel:'',availability:'',collections:'',language:'',ereadalong:false,sortby:''}"
                        (click)="trackByTitleDetailsActions('ACTIONS_SERIES',titleDetailsData.series)">
                        {{titleDetailsData.series}}</a>
                </div>
            </div>
            <div class="custom-row" *ngIf="isRTV && estimatedWaitTime !== 0 && holdsAllowed && holdPosition !== 0 && consortiaType !== 'MEMBER' && 
                ((titleDetailsActions.titleButtonDetails.actions[0].actionButtonId !== 'btnAddHold') && 
                (titleDetailsActions.titleButtonDetails.actions[0].actionButtonId !== 'btnReadNow')) ">
                <div class="custom-colunm book-title-head single-ellipsis">ESTIMATED WAIT</div>
                <div class="custom-colunm book-title-detail">{{estimatedWaitTime}} day(s)</div>
            </div>

            <div class="custom-row" *ngIf="formatType && titleDetailsData">
                <div class="custom-colunm book-title-head single-ellipsis">FORMAT</div>
                <div class="custom-colunm book-title-detail">{{formatType}}</div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.edition">
                <div class="custom-colunm book-title-head single-ellipsis">EDITION</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.edition}}</div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.length && titleDetailsData?.length !== 'NA'">
                <div class="custom-colunm book-title-head single-ellipsis">LENGTH</div>
                <div class="custom-colunm book-title-detail book-title-hours">
                    <div class="custom-colunm book-title-detail sr-only">{{titleDetailsData.length}}</div><span
                        aria-hidden="true">{{titleDetailsData?.runTime}}</span>
                </div>
            </div>

            <div class="custom-row" *ngIf="titleDetailsData?.bookLength > 0 && formatType === 'eBook'">
                <div class="custom-colunm book-title-head single-ellipsis">PAGES</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.bookLength}}</div>
            </div>

            <div class="custom-row" *ngIf="titleDetailsData?.fileSize && titleDetailsData?.fileSize !== 0">
                <div class="custom-colunm book-title-head single-ellipsis">DOWNLOAD SIZE</div>
                <div class="custom-colunm book-title-detail" [attr.aria-label]="getAriaLabel(fileSize)">{{fileSize}}</div>
            </div>

            <div class="custom-row" *ngIf="titleDetailsData?.languageDesc">
                <div class="custom-colunm book-title-head single-ellipsis">LANGUAGE</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.languageDesc}}</div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.attribute">
                <div class="custom-colunm book-title-head single-ellipsis">ATTRIBUTE</div>
                <div class="custom-colunm book-title-detail"> {{titleDetailsData.attribute}} </div>
            </div>
        </div>
        <div class="custom-table col-lg-6 col-xs-12">
            <div class="custom-row" *ngIf="titleDetailsData?.publisher">
                <div class="custom-colunm book-title-head single-ellipsis">PUBLISHER</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.publisher}}</div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.publicationDate">
                <div class="custom-colunm book-title-head single-ellipsis">PUBLISH DATE</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.publicationDate | date: 'MMM dd,
                    yyyy'}}
                </div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.id">
                <div class="custom-colunm book-title-head single-ellipsis isbn-label" aria-label="International Standard Book Number">I S B N</div>
                <div class="custom-colunm book-title-detail">{{titleDetailsData.id}}</div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.audience">
                <div class="custom-colunm book-title-head single-ellipsis">AUDIENCE</div>
                <div class="custom-colunm book-title-detail">{{replaceHyphenToEndashTitleDetails(titleDetailsData.audience.split(',')[0])}}</div>
            </div>
            <div class="custom-row" *ngIf="AGE_RANGE[this.titleDetailsData?.audience]">
                <div class="custom-colunm book-title-head single-ellipsis">AGE RANGE</div>
                <div class="custom-colunm book-title-detail">
                    {{AGE_RANGE[this.titleDetailsData?.audience].age_range.replace('-',' to ')}}
                </div>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.lexile">
                <div class="custom-colunm book-title-head single-ellipsis">LEXILE</div>
                <a class="custom-colunm book-title-detail primary-text" href="javascript:void(0);"> NA </a>
            </div>
            <div class="custom-row" *ngIf="titleDetailsData?.awards">
                <div class="custom-colunm book-title-head single-ellipsis">AWARDS</div>
                <a class="custom-colunm book-title-detail primary-text" href="javascript:void(0);"> NA </a>
            </div>
            <div class="custom-row subject-heading" *ngIf="titleDetailsData?.subject && titleDetailsData?.subjectList">
                <div class="custom-colunm book-title-head single-ellipsis">SUBJECT</div>
                <div class="custom-colunm book-title-detail primary-text subject-wrapper">
                    <a class="btn-primary btn-primary-blue subject-btn d-flex"
                        [routerLink]="['/view/title']" [queryParams]="{subject:encodeURIComponent(subjectName.term),isTitle:true}"
                        *ngFor="let subjectName of titleDetailsData.subjectList"
                        (click)="checkSubjectToClearInSessionRefiner();trackByTitleDetailsActions('ACTIONS_SUBJECT',subjectName.subject)">
                        {{subjectName.subject}}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="titleDetailsData === undefined || titleDetailsData === null">
        <p class="primary-heading-2">No Details books available</p>
    </ng-container>
</div>