<p class="kz-visually-hidden" role="alert" *ngIf="commonService.isProfileSettingPage" aria-live="polite" id="profileSettingPage">{{pageAnnounce}}</p>
<div class="kz-main kz-pro-edit-section" *ngIf="!isAvatar" (window:resize)="onResize()"
  [ngStyle]="backgroundImageUrl ? {'background-image': 'url(' + backgroundImageUrl + ')'} : ''">
  <div class="profileWrapper">
    <button class="btn-nobg-bluecolor d-flex back-btn" id="profile-back"
      (click)="back(true);profilesAnalyticsService.trackProfilesEvent('BACK')"> <mat-icon aria-hidden="true"
        class="back-arrow" svgIcon="kz-back-arrow" aria-hidden="true"></mat-icon>back</button>
    <axis360-spinner-loader *ngIf="isLoading;else profileEditContent" isLoading="true"></axis360-spinner-loader>
    <ng-template #profileEditContent>
      <div class="kz-pro-edit-div" *ngIf="!isAPIFailed">
        <h1 class="sr-only" aria-hidden="true" hidden>Edit profile</h1>
        <div class="kz-pro-edit-lft kz-pro-edit-rgt">
          <div class="kz-pro-edit-img">
            <axis360-profile-avatar-image [imgSrc]="!avatar ? profileInfo.profileImage: avatar"
              [imgAlt]="avatarName != '' ? 'avatar ' + avatarName : 'avatar ' + editProfileForm.value.displayName"
              [profileType]="profileInfo.profileType" [isEdit]="true" isImageEdit="true" (editEvent)="onImageEdit()"
              editAriaLabel="Edit Profile Image">
            </axis360-profile-avatar-image>
          </div>
          <div class="kz-pro-edit-checkbox mt-3">
            <a *ngIf="isShowInterests" class="viewMyInterestsLink" href="javascript:;" id="loc_linkViewMyinterests"
              (keyup.enter)="profilesAnalyticsService.trackProfilesEvent('VIEW_MY_INTERESTS');viewInterestsurvey()"
              (click)="profilesAnalyticsService.trackProfilesEvent('VIEW_MY_INTERESTS');viewInterestsurvey()">View
              My Interests
              <mat-icon class="kz-right-arrow" svgIcon="kz-right-arrow"></mat-icon>
            </a>
          </div>

          <div class="kz-add-form mt-1">
            <form [formGroup]="editProfileForm">

              <p class="primary-para mb-1" tabindex="-1" aria-hidden="true"> <span class="req kz-required">*</span>
                indicates a required field.</p>
              <div class="kz-pro-name-type"
                [ngClass]="this.configService.currentLibrary.axis360Enabled && !configService.currentLibrary.kidsZoneEnabled ? 'boundless-only' : '' ">
                <mat-form-field class="kz-name-form" appearance="fill">
                  <mat-label [ngClass]="{'label-with-data': editProfileForm.value.displayName}">Display
                    Name</mat-label>
                  <input matInput placeholder="" class="single-ellipsis" formControlName="displayName" maxlength="26"
                    autocomplete="off" id="loc_txtDisplayName" (keyup)="formChange()" (input)="onKeyPress($event)"
                    (change)="profilesAnalyticsService.trackProfilesEvent('EDIT_DN');" required>
                  <mat-error *ngIf="editProfileForm.controls['displayName'].errors?.required && isCreateProfile">Please
                    create a name for your Profile.</mat-error>
                  <mat-error *ngIf="editProfileForm.controls['displayName'].errors?.required && !isCreateProfile">Please
                    enter your profile name</mat-error>
                  <mat-error *ngIf="editProfileForm.controls['displayName'].errors?.isDuplicate">Name
                    already taken</mat-error>
                  <mat-error *ngIf="editProfileForm.controls['displayName'].errors?.maxlength">
                    Display Name can not exceed 25 characters
                  </mat-error>
                </mat-form-field>
                <div class="adultwrapper {{type}}" id="loc_dpProfileType"
                  *ngIf="!(this.configService.currentLibrary.axis360Enabled && !configService.currentLibrary.kidsZoneEnabled)">
                  <p class="p-type">Profile Type</p>
                  <ng-container *ngFor="let profileType of profileTypes">
                    <p class="a-type" *ngIf="profileType.profileType.toLowerCase() === type">
                      <span *ngIf="profileType.profileType.toLowerCase() === 'adult'">General</span>
                      <span *ngIf="profileType.profileType.toLowerCase() !== 'adult'">{{profileType.profileType}}
                        {{profileType.profileCode}}</span>

                    </p>
                  </ng-container>
                </div>
              </div>
              <ng-container>
                <ng-container *ngIf=" patronTypeAuthentication ||  (profileInfo.profileTypeId !== '3' && (isPrimary || !patronProfileData.email || !sameAsAdultEmail))">
                  <mat-form-field class="kz-name-form kz-email-form"
                                  [ngClass]="{'kz-email-form-error': editProfileForm.get('email').hasError('pattern') || editProfileForm.get('email').hasError('required')}"
                                  appearance="fill">

                    <mat-label for="email-input" id="email-input-label"
                               [ngClass]="{'label-with-data': editProfileForm.value.profileTypeId}">
                      Email
                    </mat-label>

                    <input matInput id="email-input" type="email" placeholder="" formControlName="email"
                           autocomplete="email"
                           aria-labelledby="email-input-label"
                           aria-describedby="kz-email-desc"
                           (keyup)="formChange();applyEmailValidators()"
                           pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$">

                    <mat-error *ngIf="editProfileForm.controls['email'].errors?.required">
                      Please
                      enter your email
                    </mat-error>

                    <mat-error *ngIf="!editProfileForm.controls['email'].errors?.required && editProfileForm.controls['email'].errors?.pattern">
                      Enter a Valid Email
                    </mat-error>


                  </mat-form-field>
                  <p id="kz-email-desc" style="margin-top:-25px; margin-bottom:10px;" class="kz-email-text">
                    Email notifications will be sent to
                    this email address
                  </p>

                </ng-container>
                <div class="kz-checkbox-sec kz-pro-edit-checkbox p-0"
                  [style.margin-top.px]="(patronTypeAuthentication && profileInfo.profileTypeId == '3') ? 36 : 0">
                  <div class="pro-edit-checkbox-div mb-0">
                    <div class="kz-check-info mb-16">
                      <mat-checkbox axis360MatCheckboxAda [(ngModel)]="isEmailEnabled" formControlName="isEmailEnabled"
                        (change)="enableEmail($event);formChange();profilesAnalyticsService.trackProfilesEvent(isEmailEnabled ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED');">
                        Enable email notifications
                      </mat-checkbox>
                      <button class="btn-nobg-bluecolor checkout-hint" (click)="enableEmailInfo()"
                        (keydown.enter)="enableEmailInfo()" aria-label="Email notification info"><mat-icon matSuffix
                          svgIcon="kz-no-info-icon" aria-hidden="true">
                        </mat-icon></button>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!patronTypeAuthentication">
                  <ng-container
                    *ngIf="patronProfileData.email && ((profileInfo.profileTypeId === '2' && sameAsAdultEmail) || profileInfo.profileTypeId === '3')">
                    <div class="kz-teen-profile">
                      <p class="kz-email" id="loc_textParentalEmail">
                        <span *ngIf="profileInfo.profileTypeId === '3'">Primary Email</span>
                        <span *ngIf="profileInfo.profileTypeId === '2'">Email</span>
                      </p>
                      <p class="parent-email">{{patronProfileData.email}}</p>
                      <p class="kz-email-text mb-16" id="kz-email-desc">Email notifications will be sent to
                        this email address</p>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="kz-checkbox-sec kz-pro-edit-checkbox p-0" *ngIf="profileInfo.profileTypeId === '2'">
                  <div class="pro-edit-checkbox-div mb-2">
                    <mat-checkbox axis360MatCheckboxAda name="sameAsAdultEmail" formControlName="sameAsAdultEmail"
                      (change)="setParentEmail($event.checked);formChange();" [disabled]='!patronProfileData.email'
                      [(ngModel)]="sameAsAdultEmail" [checked]="sameAsAdultEmail">
                      Add same email as primary profile
                    </mat-checkbox>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="isPrimary && isShowSecurityQuestion">
                <mat-form-field class="kz-name-form kz-securityque-form" appearance="fill">
                  <mat-label id="security-question"
                    [ngClass]="{'label-with-data': editProfileForm.value.securityQuestion}">
                    Security Question</mat-label>
                  <mat-select disableOptionCentering #securityQuestion aria-haspopup="listbox"
                    panelClass="kz-security-que-panel kz-mat-select-panel" aria-controls="listbox1" role="combobox" id="security-questionSelect"
                    formControlName="securityQuestion" (selectionChange)='onSecurityQuestionChange();formChange()'
                    (openedChange)="openMatSelect($event)" axis360MatSelectAriaExpanded
                    [required]="isShowSecurityQuestion" aria-labelledby="security-question"
                    [attr.aria-expanded]="securityQuestion.panelOpen">
                    <div id="listbox1">
                      <mat-option value="{{ques.value}}" attr.aria-label="{{ques.text}}" id="{{ques.id}}"
                        *ngFor="let ques of patronProfileData.securityQuestions">{{ques.text}}
                      </mat-option>
                    </div>
                  </mat-select>
                  <mat-icon matSuffix>keyboard_arrow_down
                  </mat-icon>
                </mat-form-field>
                <mat-form-field class="kz-name-form" appearance="fill">
                  <mat-label [ngClass]="{'label-with-data': editProfileForm.value.securityAnswer}">
                    Security Answer</mat-label>
                  <input matInput placeholder="Security Answer"
                    formControlName="securityAnswer" (keyup)="formChange()"
                    (change)="profilesAnalyticsService.trackProfilesEvent('SECURITY_ANSWER');"
                    [required]="isShowSecurityQuestion">
                  <mat-error>Security Answer is required</mat-error>
                </mat-form-field>
              </ng-container>


            </form>

          </div>
          <div class="kz-pro-edit-btns done_Submit mt-10" *ngIf="!isCreateProfile">
            <button class="saveBtn" id="loc_btnSave" [ngClass]="{'active': enableSave && !editProfileForm.invalid}"
              [disabled]="editProfileForm.invalid || !enableSave" (click)="saveProfile()"
              (keydown.enter)="saveProfile()">Save</button>
          </div>
          <div class="kz-pro-edit-btns done_Submit" *ngIf="!isCreateProfile">
            <button id="loc_linkDeleteProfile" *ngIf="!isPrimary" class="deleteBtn" (click)="deleteProfileWithPin()"
              (keydown.enter)="deleteProfileWithPin()">Delete Profile</button>
            <button id="loc_linkDeleteAccount"
              *ngIf="isPrimary && (currentLibrary.authSettings.ilsVendorID == 'PASSWORD' || currentLibrary.authSettings.ilsVendorID == 'PREFIX')"
              class="deleteBtn" (click)="deleteAccountwithPin()" (keydown.enter)="deleteAccountwithPin()">Cancel my
              Boundless Account</button>
          </div>
          <div class="kz-pro-edit-btns done_Submit" *ngIf="isCreateProfile">
            <button class="saveBtn" id="loc_btnSave" [ngClass]="{'active': enableSave && !editProfileForm.invalid}"
              [disabled]="editProfileForm.invalid || !enableSave" (click)="createProfile()"
              (keydown.enter)="createProfile()">Save</button>
          </div>
        </div>
        <div class="kz-pro-edit-rgt">
          <div class="kz-checkbox-sec kz-pro-edit-checkbox">
            <div class="pro-edit-checkbox-div" id="profileSetting">
              <div class="auto-checkout enable_pin kz-check-info  mb-16" *ngIf="configService.currentLibrary.isAutoCheckout">
                <mat-slide-toggle disableRipple [(ngModel)]="patronProfileData.isAutoCheckout" aria-label="Auto checkout available Holds"
                  (change)="save(true);profilesAnalyticsService.trackProfilesEvent(patronProfileData.isAutoCheckout ? 'CHECKOUT_SELECTED':'CHECKOUT_UNSELECTED');"
                  [checked]="patronProfileData.isAutoCheckout" [disabled]="!isPrimary">
                  Auto checkout available Holds
                </mat-slide-toggle>
                <button *ngIf="configService.currentLibrary.kidsZoneEnabled" class="btn-nobg-bluecolor checkout-hint"
                  (click)="autoCheckoutInfo()" (keydown.enter)="autoCheckoutInfo()"
                  aria-label="Auto checkout info"><mat-icon matSuffix svgIcon="kz-no-info-icon" aria-hidden="true">
                  </mat-icon></button>
              </div>

              <!--PBI201344-->
              <div class="enable_pin kz-check-info mb-16" *ngIf="!currentLibrary.partnerSiteEnabled">
                <mat-slide-toggle disableRipple [(ngModel)]="checkoutHistory" id="loc_chkboxDisplayCheckoutHistory" aria-label="Display Checkout History"
                  (change)="profilesAnalyticsService.trackProfilesEvent(checkoutHistory ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED'); checkoutHistoryPopup(checkoutHistory)"
                  [checked]="checkoutHistory">
                  Display
                  Checkout History
                </mat-slide-toggle>
              </div>
              <div class="enable_pin kz-check-info mb-16" *ngIf="showInsightsAndBadges">
                <mat-slide-toggle disableRipple [(ngModel)]="enableTracking"
                  id="showInsightsAndBadges{{showInsightsAndBadges}}" aria-label="Display Insights & Badges"
                  (change)="profilesAnalyticsService.trackProfilesEvent(enableTracking ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED'); disableBadgesPopup(enableTracking)"
                  [checked]="enableTracking">
                  Display Insights &
                  Badges
                </mat-slide-toggle>
              </div>
              <div class="enable_pin kz-check-info mb-16">
                <ng-container>
                  <mat-slide-toggle disableRipple id="myShelfDefaultLandingPage{{myShelfDefaultLandingPage}}" aria-label="Set My Shelf as my home page"
                    (change)="save(true);profilesAnalyticsService.trackProfilesEvent(myShelfDefaultLandingPage ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED'); "
                    [checked]="myShelfDefaultLandingPage" [(ngModel)]="myShelfDefaultLandingPage">
                    Set My Shelf as my home page
                  </mat-slide-toggle>
                </ng-container>
              </div>
              <div class="enable_pin kz-check-info mb-16">
                <mat-slide-toggle disableRipple [(ngModel)]="highContrast" aria-label="Display high contrast"
                  (change)="save(true);profilesAnalyticsService.trackProfilesEvent(highContrast ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED');"
                  [checked]="highContrast">
                  Display high contrast
                </mat-slide-toggle>
              </div>
              <div class="allow-pin enable_pin kz-check-info mb-16"
                *ngIf="(profileInfo.profileTypeId === '1' && isPrimary) && (configService.currentLibrary.kidsZoneEnabled) && !isCreateProfile">
                <mat-slide-toggle disableRipple id="universalPINFlag{{universalPINFlag}}" aria-label="Allow PINs for other Profiles"
                  (change)="enableUniversalPIN();profilesAnalyticsService.trackProfilesEvent(universalPINFlag ? 'CHECKBOX_SELECTED':'CHECKBOX_UNSELECTED');"
                  [checked]="universalPINFlag" [(ngModel)]="universalPINFlag">
                  Allow PINs for other Profiles
                </mat-slide-toggle>
                <button class="btn-nobg-bluecolor checkout-hint" (click)="enableUniversalPinInfo()"
                  (keydown.enter)="enableUniversalPinInfo()" aria-label="Enable universal pin info"><mat-icon matSuffix
                    svgIcon="kz-no-info-icon" aria-hidden="true">
                  </mat-icon></button>
              </div>
              <div class="enable_pin kz-check-info"
                *ngIf=" patronTypeAuthentication || (configService.currentLibrary.kidsZoneEnabled && (isPrimary || universalPINFlag))">
                <mat-slide-toggle disableRipple #pinStatus id="pintoggle{{isEnablePin}}" aria-label="Enable universal pin info"
                  (change)="setEnablePin($event);" [checked]="isEnablePin">
                  Enable My Profile PIN
                </mat-slide-toggle>
                <button class="btn-nobg-bluecolor checkout-hint" (click)="setPinInfo()" (keydown.enter)="setPinInfo()"
                  aria-label="Pin info"><mat-icon matSuffix svgIcon="kz-no-info-icon" aria-hidden="true">
                  </mat-icon></button>
              </div>
            </div>

          </div>
          <div class="kz-pro-edit-details" [ngClass]="type + '-bg'">
            <h2 class="edit-details-heading double-ellipsis" axis360EllipsisTitle>
              {{patronProfileData.libraryName}}</h2>
            <div class="edit-details-div" *ngIf="myStuff">
              <ng-container>
                <p class="edit-det-sub-head" [ngClass]="type + '-heading'" *ngIf="libraryCardId">Library
                  Card ID
                </p>
                <div class="edit-details-sub-div" *ngIf="libraryCardId">
                  <div class="edit-details-sub-div-right">
                    <p class="edit-details-value">{{libraryCardId}}</p>
                  </div>
                </div>
              </ng-container>
              <p class="edit-det-sub-head checkout-limit" [ngClass]="type + '-heading'">
                Checkout Limit</p>
              <div class="edit-details-sub-div">
                <div class="edit-details-sub-div-left" *ngIf="myStuff.eBook.limit > 0 || myStuff.audioBook.limit > 0">
                  <span class="edit-details-name" *ngIf="myStuff.eBook.limit > 0">eBook:</span>
                  <span class="edit-details-value" *ngIf="myStuff.eBook.limit > 0" role-old="text">
                    <span class="count">{{myStuff.eBook.limit -
                      patronProfileData.activeeBookCheckout}}
                      out of {{myStuff.eBook.limit}} available</span>
                  </span>
                </div>
                <div class="edit-details-sub-div-right" role-old="text">
                  <span class="edit-details-name" *ngIf="myStuff.audioBook.limit > 0">eAudio:</span>
                  <span class="edit-details-value" *ngIf="myStuff.audioBook.limit > 0">
                    <span class="count">{{myStuff.audioBook.limit -
                      patronProfileData.activeeAudiobookCheckout}} out
                      of {{myStuff.audioBook.limit}} available </span>
                  </span>
                  <span class="edit-details-value" *ngIf="myStuff.eBook.limit <= 0 && myStuff.audioBook.limit <= 0">
                    <span class="count">{{myStuff.checkout.limit -
                      patronProfileData.activeCheckout}} out of
                      {{myStuff.checkout.limit}} available</span>
                  </span>
                </div>

                <div class="edit-details-sub-div-left"
                  *ngIf="(myStuff.eBook.limit != 0 || myStuff.audioBook.limit != 0) && currentLibrary?.enableLibrarySettingsFormatLevel && videoText?.length > 1">
                  <span class="edit-details-name" *ngIf="myStuff.maxVideoCheckoutLimit >= 0">{{videoText}}: </span>
                  <span class="edit-details-value" *ngIf="myStuff.maxVideoCheckoutLimit >= 0" role-old="text">
                    <span class="count">{{myStuff.maxVideoCheckoutLimit - myStuff.currentVidVbkCheckoutCount}}
                      out of {{myStuff.maxVideoCheckoutLimit}} available</span>
                  </span>
                </div>
              </div>
              <p class="edit-det-sub-head hold-limit" *ngIf="myStuff.hold.limit" [ngClass]="type + '-heading'">Hold
                Limit</p>
              <p class="edit-det-sub-head hold-limit" *ngIf="!myStuff.hold.limit && myStuff.currentHoldCount != null"
                [ngClass]="type + '-heading'">Holds</p>
              <div class="edit-details-sub-div">
                <p class="edit-details-value" *ngIf="myStuff.hold.limit" role-old="text">
                  <span class="count">{{patronProfileData.remainingHold}} out of
                    {{myStuff.hold.limit}} available </span>
                </p>
                <p class="edit-details-value" role-old="text"
                  *ngIf="!myStuff.hold.limit && myStuff.currentHoldCount != null">
                  <span class="count">{{myStuff.currentHoldCount}}</span> Holds
                </p>
              </div>
              <p class="edit-det-sub-head recommendation-limit" *ngIf="myStuff.recommend.limit"
                [ngClass]="type + '-heading'">Purchase Request Limit</p>
              <p class="edit-det-sub-head recommendation-limit"
                *ngIf="!myStuff.recommend.limit && myStuff.currentRecommendCount != null" [ngClass]="type + '-heading'">
                Purchase Request Limit</p>
              <div class="edit-details-sub-div">
                <p class="edit-details-value" *ngIf="myStuff.recommend.limit" role-old="text">
                  <span class="count">{{myStuff.recommend.limit -
                    patronProfileData.numberOfRecommendations}} out of
                    {{myStuff.recommend.limit}} available this week </span>
                </p>
                <p class="edit-details-value" *ngIf="!myStuff.recommend.limit && myStuff.currentRecommendCount != null">
                  <span class="count">{{myStuff.currentRecommendCount}}</span> Purchase Requests Limit
                </p>
              </div>
            </div>

          </div>


        </div>
      </div>
    </ng-template>
  </div>
</div>
<axis360-avatars [type]="profileInfo.profileType" *ngIf="isAvatar"
  (avatarChanged)="avatarChanged($event)"></axis360-avatars>
