<div class="book-title-details" [hidden]="isLoading">
    <ng-container *ngIf="titleStateInfo && titleStateInfo.actions?.length > 0">
        <div class="btn-group checkoutbtn-group"
            [ngClass]="{'btncheck': (titleStateInfo?.actions[0].actionButtonId !== 'btnCheckoutNow') }">
            <ng-container *ngIf="titleStateInfo.actions?.length > 0">
                <ng-container [ngSwitch]="titleStateInfo?.actions[0].actionButtonId">
                    <ng-container *ngSwitchCase="'btnMarkAsRead'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.MARK_AS_READ, titleStateInfo.actions[0])">
                            <span>Mark as Done</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCompleted'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction">
                            <span>Completed</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCheckoutNow'">
                        <button class="btn-primary btn-primary-blue" id="loc_Checkout" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CHECKOUT, titleStateInfo.actions[0],{btn:'moreOption', btnId: focusElementCTA, errorId:'loc_Checkout'},titleDetailsData);trackByTitleDetailsActions('CHECKOUT');">
                            <span>Checkout</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnReturn'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RETURN, titleStateInfo.actions[0],bookDetail?.title, {btn:'moreOption', btnId:'loc_Checkout', errorId:'loc_more_option'})">
                            <span>Return</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRenew'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RENEW, titleStateInfo.actions[0],bookDetail?.title, {btn:'moreOption', btnId:'loc_more_option', errorId:'loc_more_option'})">
                            <span>Renew</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnUnsuspendHold'">
                        <button class="btn-primary btn-primary-blue" id="loc_unsuspendHold" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, titleStateInfo.actions[0])">
                            <span>Activate Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAddHold'">
                        <button class="btn-primary btn-primary-blue" id="loc_placeHoldCTA" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, titleStateInfo.actions[0], {btn:'moreOption', btnId:'loc_removeHold',errorId:'loc_placeHoldCTA'});trackByTitleDetailsActions('PLACE_HOLD');">
                            <span>Place Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemoveHold'">
                        <button class="btn-primary btn-primary-blue" id="loc_removeHold" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, titleStateInfo.actions[0], {btn:'moreOption', btnId:'loc_placeHoldCTA'});trackByTitleDetailsActions('REMOVE');">
                            <span>Remove Hold</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnReadNow'">
                        <a href="/Action/ReadNowClick?itemId={{titleStateInfo.actions[0].itemId}}&ISBN={{titleStateInfo.actions[0].isbn}}"
                            (click)="trackByTitleDetailsActions('READ_BOOKS');" id="loc_readCTA" target="_blank"
                            class="btn-primary btn-primary-blue" role="button">
                            <span>Read</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnViewPack'">
                        <a href="/coursepack" class="btn-primary btn-primary-blue" role="button">
                            <span>View Pack</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAccessIndvidualCourse'">
                        <a href="/coursepack" class="btn-primary btn-primary-blue" role="button">
                            <span>View Pack</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnListenNow'">
                        <a href="/Action/ListenNowClick?itemId={{titleStateInfo?.actions[0].itemId}}&ISBN={{titleStateInfo?.actions[0].isbn}}"
                            onClick="window.open(this.href); return false;" id="loc_listenCTA"
                            class="btn-primary btn-primary-blue" role="button">
                            <span>Listen</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnDownload'">
                        <a href="/Action/DownloadNowClick?itemId={{titleStateInfo?.actions[0].itemId}}" target="_blank"
                            class="btn-primary btn-primary-blue" role="button"
                            (click)="trackByTitleDetailsActions('DOWNLOAD');">
                            <span>Download</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAccessinApp'">
                        <a class="btn-primary btn-primary-blue" id="loc_Access_On_App" tabindex="0" role="button">
                            <span class="upper-text">Access </span>
                            <span class="lower-text">&nbsp;on App</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRecommend'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, titleStateInfo?.actions[0])">
                            <span>Request Purchase</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnCancelRecommend'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_RECOMMEND, titleStateInfo?.actions[0])">
                            <span>Cancel Request</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnAddWishlist'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, titleStateInfo?.actions[0]);trackByTitleDetailsActions('ACTIONS_WISHLIST');">
                            <span>Add to Wishlist</span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnRemoveWishlist'">
                        <div class="book-favorite">
                            <mat-icon svgIcon="favorite"></mat-icon>
                        </div>
                        <button class="btn-primary btn-primary-blue btn-remove-wish" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, titleStateInfo?.actions[0]);trackByTitleDetailsActions('REMOVE');">
                            <span class="action-box">
                                <span class="wishlist-action">
                                    <span class="lower-text wishlist-text-transform">Remove from Wishlist</span>
                                </span>
                            </span>
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnBuyNow'">
                        <a class="btn-primary btn-primary-blue" href="{{titleStateInfo?.actions[0].buyNowUrl}}"
                            target="_blank" rel="noopener"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, titleStateInfo?.actions[0])"
                            role="button">
                            <span>Buy Now</span>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'btnSuspendHold'">
                        <button class="btn-primary btn-primary-blue" [disabled]="!showAction"
                            (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD, titleStateInfo?.actions[0], {btn:'moreOption', btnId:'loc_unsuspendHold'});trackByTitleDetailsActions('SUSPEND_HOLD');">
                            <span>Suspend</span>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="titleStateInfo?.actions[0].actionButtonId !== 'btnCheckoutNow'">
                <div class="btn-group d-flex" *ngIf="titleStateInfo?.actions.length > 1">
                    <button class="expand-sec d-flex" aria-label="more option" id="loc_more_option" axis360MatMenuFocus
                        [matMenuTriggerFor]="menu" #matMenuItem="matMenuTrigger" (menuOpened)="menuState(true)"
                        (menuClosed)="menuState(false)"
                        (click)="trackByTitleDetailsActions('TITLE_SECONDARY_ACTIONS');">
                        <small class="icon-text">More Options </small>
                        <mat-icon *ngIf="!matMenuItem.menuOpen" svgIcon="kz-down-arrow"></mat-icon>
                        <mat-icon *ngIf="matMenuItem.menuOpen" svgIcon="kz-up-arrow"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="currentLibrary.isPhysitalEnabled && this.isRequestedTitleCheck">
            <ng-container *ngIf="titleButtonViews?.availableBookStatus === 'COMING SOON' || titleStateInfo?.actions[0].actionButtonId == 'btnAddHold' || titleStateInfo?.actions[0].actionButtonId == 'btnRemoveHold' || titleStateInfo?.actions[0].actionButtonId == 'btnUnsuspendHold'">
               <div *ngIf="physicalInventoryData && physicalInventoryData.ilsLinkText && titleDetailsService.titleDetailsActions.availableBookStatus !== 'REQUESTED'" class="align-phygital-link">
                <a href="javascript:;" role="link" attr.aria-label="{{physicalInventoryData.ilsLinkText}}, opens in a new tab" (click)="checkPrintAvailability()" class="primary-link kz-purchase-link new-tab-link">{{physicalInventoryData.ilsLinkText}}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.25 2.75H2.75C1.7835 2.75 1 3.5335 1 4.5V13.25C1 14.2165 1.7835 15 2.75 15H11.5C12.4665 15 13.25 14.2165 13.25 13.25V9.75M9.75 1H15M15 1V6.25M15 1L6.25 9.75" stroke="#00508B" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> 
                </a>
               </div>               
            </ng-container> 
        </ng-container>  
        <mat-menu #menu="matMenu" class="kz-action-menu-item kz-title-details" yPosition="below" xPosition="after">
            <ng-container *ngFor="let action of titleStateInfo?.actions;let i = index;">
                <ng-container *ngIf="i > 0">
                    <ng-container [ngSwitch]="action.actionButtonId">
                        <div *ngSwitchCase="'btnViewPack'">
                            <a href="/coursepack?itemId={{action.itemId}}&ISBN={{action.isbn}}" mat-menu-item
                                class="action-expand action-links">View Pack</a>
                        </div>
                        <div *ngSwitchCase="'btnRenew'">
                            <button type="button" mat-menu-item alt="renew" class="action-expand action-links "
                                (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RENEW, action,bookDetail?.title, {btn:'moreOption', btnId:'loc_more_option', errorId:'loc_more_option'})">
                                Renew
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReturn'">
                            <button type="button" mat-menu-item alt="return" class="action-expand action-links"
                                (click)="confirmPopupByTitleActionHanlder($event, ACTION_BUTTON_DATA.RETURN, action,bookDetail?.title, {btn:'moreOption', btnId:'loc_Checkout',errorId:'loc_more_option'})">
                                Return
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnReadNow'">
                            <a href="/Action/ReadNowClick?itemId={{action.itemId}}&ISBN={{action.isbn}}" target="_blank"
                                mat-menu-item class="action-expand action-links"
                                (click)="trackByTitleDetailsActions('READ_BOOKS');">Read</a>
                        </div>
                        <div *ngSwitchCase="'btnListenNow'">
                            <a href="/Action/ListenNowClick?itemId={{action.itemId}}&ISBN={{action.isbn}}"
                                target="_blank" mat-menu-item class="action-expand action-links">Listen
                            </a>
                        </div>
                        <div *ngSwitchCase="'btnDownload'">

                            <a href="/Action/DownloadNowClick?itemId={{action.itemId}}" target="_blank" mat-menu-item
                                class="action-expand action-links"
                                (click)="trackByTitleDetailsActions('DOWNLOAD');">Download</a>

                        </div>
                        <div *ngSwitchCase="'btnAccessinApp'">
                            <button type="button" mat-menu-item alt="access on app" class="action-expand action-links">
                                Access on App
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddHold'">
                            <button type="button" mat-menu-item alt="place hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_HOLD, action,{btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('PLACE_HOLD');">
                                Place Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveHold'">
                            <button type="button" mat-menu-item alt="remove hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_HOLD, action,{btn:'moreOption', btnId:'loc_placeHoldCTA'});trackByTitleDetailsActions('REMOVE');">
                                Remove Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRecommend'">
                            <button type="button" mat-menu-item alt="recommend" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.RECOMMEND, action, {btn:'moreOption', btnId:'loc_more_option'})">
                                Request Purchase
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnCancelRecommend'">
                            <button type="button" mat-menu-item alt="Cancel recommendation"
                                class="action-expand action-links wrap-cancel-recommendation"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.CANCEL_RECOMMEND, action, {btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('REMOVE');">
                                Cancel Request
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnUnsuspendHold'">
                            <button type="button" mat-menu-item alt="unsuspend" lass="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.UNSUSPEND_HOLD, action, {btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('ACTIVATE_SUSPEND');">
                                Activate Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnAddWishlist'">
                            <button type="button" mat-menu-item alt="add to wishlist" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.ADD_WISHLIST, action, {btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('ACTIONS_WISHLIST');">
                                Add to Wishlist
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnRemoveWishlist'">
                            <button type="button" mat-menu-item alt="remover from wishlist"
                                class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.REMOVE_WISHLIST, action, {btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('REMOVE');">
                                Remove from Wishlist
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnBuyNow'">
                            <a href="{{action.buyNowUrl}}" mat-menu-item target="_blank" rel="noopener"
                                class="action-expand action-links buynow"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.BUY_NOW, action)">Buy
                                Now</a>
                        </div>
                        <div *ngSwitchCase="'btnSuspendHold'">
                            <button type="button" mat-menu-item alt="suspend hold" class="action-expand action-links"
                                (click)="titleActionHandlerWrapper($event, ACTION_BUTTON_DATA.SUSPEND_HOLD,action, {btn:'moreOption', btnId:'loc_more_option'});trackByTitleDetailsActions('SUSPEND_HOLD');">
                                Suspend Hold
                            </button>
                        </div>
                        <div *ngSwitchCase="'writeAReview'">
                            <button type="button" mat-menu-item alt="write a review" class="action-expand action-links">
                                Write A Review
                            </button>
                        </div>
                        <div *ngSwitchCase="'btnShareNow'">
                            <button type="button" mat-menu-item alt="share" class="action-expand action-links"
                                (click)="trackByTitleDetailsActions('ACTIONS_SHARE');openShareDialog()"
                                (keydown.enter)="trackByTitleDetailsActions('ACTIONS_SHARE');openShareDialog()">
                                Share
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-menu>
    </ng-container>
</div>
