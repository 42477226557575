import { NotificationsService } from './../core/notifications/shared/services/notifications.service';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { forceRedirect } from '@shared/helper/app.util';
import { ConfigService } from '@shared/services/config.service';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { SidenavService } from '@shared/services/sidenav.service';
import { UserService } from '@shared/services/user.service';
import { HeaderAnalyticsService } from './shared/services/header.analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountComponent } from 'app/core/account/account.component';
import { AccountService } from '@shared/services/account.service';
import { AdvancedSearchComponent } from 'app/header/advanced-search/advanced-search.component';
import { SpinnerService } from '@shared/services/spinner.service';
import { CommonService } from '@shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from '@shared/constants/search.constant';
import { KzSearchService } from 'app/core/kz-search-results/shared/services/kz-search.service';
import { fromEvent } from 'rxjs';
import { TitleListService } from 'app/core/title-list/shared/services/title-list.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'axis360-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input() isLoading: boolean;
  @Input() advancedSearchToolbar:boolean=true;
  @Input() signInButton:boolean=true;
  @Input() sidebarNav:boolean=true;
  forceRedirect = forceRedirect;
  notificationOpened = false;
  loginOpened = false;
  advanceSearch = false;
  searchText = '';
  skipLink = '';
  hideHeaderFooter = false;
  currentProfile: string;
  programsName: string;
  parentRoute: string;
  programId: string;
  epopupValue: boolean;
  searchEnabled = false;
  currentURL = '';
  isVisibleMobile = false;
  fromEvent = fromEvent;
  constructor(
    public sidenavService: SidenavService,
    public profileInfoService: ProfileInfoService,
    public headerAnalyticsService: HeaderAnalyticsService,
    public userService: UserService,
    public accountService: AccountService,
    public configService: ConfigService,
    private matDialog: MatDialog,
    public spinnerService: SpinnerService,
    public commonService: CommonService,
    public notificationsService: NotificationsService,
    public router: Router,
    private location: Location,
    private searchService: KzSearchService,
    private cdr: ChangeDetectorRef,
    public activeRouter: ActivatedRoute,
    public titleListService: TitleListService,
    public titleService: Title,
    public liveAnnouncer: LiveAnnouncer
  ) { }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    const userAgent = navigator.userAgent;
    const regex = /(Android|android|Samsung)/i;
    const match = regex.exec(userAgent);
    this.onResize();
    if (match) {
      document.getElementById('middle-initial')?.setAttribute('aria-label', 'Search for content');
    }
    const matchResult = /(iPhone)/i.exec(navigator.userAgent);
    if (matchResult) {
      document.getElementById('middle-initial')?.setAttribute('aria-label', 'Search for content');
    }
    this.commonService.hideHeaderFooter.subscribe((hideHeaderFooter: boolean) => {
      this.hideHeaderFooter = hideHeaderFooter;
      document.body.classList.remove('header-hide');
      if (hideHeaderFooter) {
        document.body.classList.add('header-hide');
      }
    });
    this.searchService.updatedSearchText.subscribe((value: string) => {
      this.searchText = value;
      this.searchService.searchText = value;
    });
    this.skipLink = `/ng${this.location.path(false)}#skip-nav`;
    this.currentURL = `${this.location.path(false)}`;
    this.location.onUrlChange((url: string) => {
      if (url.includes('#skip-nav')) {
        return;
      }
      this.skipLink = `${url}#skip-nav`;
      this.currentURL = `${this.location.path(false)}`;
    });
    if (this.userService.menuEmitter) {
      this.fromEvent(window, 'scroll').subscribe(() => {
        this.userService.menuEmitter.next(false);
      });
    }
  }
  /**
   * To open side menu on hamburger button click
   */
  openSidebar() {
    this.sidenavService.isHamburgerExpanded = true;
    this.headerAnalyticsService.trackHeaderEvent('VIEW_HAMBURGER_MENU');
    this.sidenavService.sidenavAction.next('open');
    const bodySelect = document.querySelector('.mat-drawer-content');
    bodySelect.setAttribute('aria-hidden', 'true');
    const howSelect = document.querySelector('.cdk-live-announcer-element');
    const helpSelect = document.querySelector('#walkme-player');
    helpSelect?.setAttribute('aria-hidden', 'true');
    howSelect?.setAttribute('aria-hidden', 'true');
  }
  ngAfterViewInit() {
    const howSelect = document.querySelector('.cdk-visually-hidden');
    howSelect.removeAttribute('aria-atomic');
  }
  /**
   * Skip navigation to main content
   */
  skipToContent() {
    window.location.hash = '';
    window.location.hash = '#skip-nav';
  }
  redirectToEdit() {
    this.router.navigate([`/view/profiles/edit/${this.profileInfoService.currentProfile.profileId}`])
  }
  /**
   * To redirect user to my profile page on profile image click
   */
  profilePicClick() {
    this.headerAnalyticsService.trackHeaderEvent('ACC_PIC');
  }
  getProgramId() {
    const regExString = /(?:view\/)((.[\s\S]*))(?:\/search)/ig;  // set ig flag for global search and case insensitive
    const regExString1 = regExString.exec(this.router.url)
    this.parentRoute = regExString1 ? regExString1[1] + '/' : '';
    const newprogramvalue = this.parentRoute.split('/');
    this.programId = newprogramvalue[1];
  }
  /**
   * To open the advanced search options
   */
  advancedSearch() {
    this.getProgramId()
    this.programsName = this.activeRouter.snapshot.queryParams?.pageName ?? '';
    this.headerAnalyticsService.trackHeaderEvent('ADVANCED_SEARCH');
    if (this.advanceSearch) {
      return;
    }
    this.programsName = this.programId ? 'programs' : '';
    this.advanceSearch = !this.advanceSearch;
    this.searchService.searchText = this.searchText;
    const searchDialog = this.matDialog.open(AdvancedSearchComponent, {
      panelClass: 'search-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'search-title',
      ariaLabel: 'Advanced Search Options',
      data: {
        searchText: this.searchText,
        pageName: this.programsName,
        programId: this.programId,
        searchEnabled: this.searchEnabled
      }
    });
    const removeRoleById = ['searchResultsViewCount', 'loc_title_list_count', 'searchResultsResouceHub', 'searchCarouselTitleCount', 'resultsCountReourceHub', 'resultsCountWPE', 'tier2-heading-count', 'zeroResult', 'noResultText', 'no-result-text'];
    searchDialog.afterClosed().subscribe(() => {
      this.advanceSearch = false;
      this.searchText = this.searchService.searchText;
      removeRoleById.map(x => {
        const getRemoveRoleId = document.getElementById(x);
        getRemoveRoleId && getRemoveRoleId?.removeAttribute('role');
      });
    });
  }

  /**
   * To click the logo and open newtab URL
   */
  trackByHeaderLogo() {
    this.headerAnalyticsService.trackHeaderEvent('VIEW_LOGO');
  }

  /**
   * Logins click
   */
  loginClick() {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        const loginDialog = this.matDialog.open(AccountComponent, {
          panelClass: 'account-modal-container',
          autoFocus: true,
          ariaLabelledBy: 'dialogHeading',
          data: {
            openLoginForm: true
          }
        });
        loginDialog.afterClosed().subscribe(() => (this.loginOpened = false));
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }
  /**
   * Searchs header component
   */
  search() {
    this.searchService.addingAlertCount();
    this.getProgramId()
    let agelevel = ''
    this.currentProfile = this.profileInfoService.currentProfile.profileType;
    if (this.configService.currentLibrary.axis360Enabled && (this.currentProfile === 'Adult' || this.currentProfile === "")) {
      agelevel = 'All'
    }
    this.programsName = this.programId ? 'programs' : '';
    const regex = /^\s*$/;
    const matchResult = regex.exec(this.searchText);
    if (!matchResult && this.searchText !== '') {
      this.headerAnalyticsService.trackHeaderEvent('SEARCH_FOR_CONTENT');
      this.epopupValue = this.titleListService.configService.currentLibrary.partnerSiteEnabled;
      const data = {
        page: 1,
        pageSize: 10,
        searchText: this.searchText,
        searchby: 'all',
        recentlyadded: EMPTY,
        format: 'All',
        agelevel,
        availability: this.epopupValue ? 'Available' : 'All',
        collections: '',
        releasedate: EMPTY,
        language: EMPTY,
        axisattribute: '',
        sortby: 'Relevancy',
        subject: '',
        vendor_code: '',
        q: this.searchText,
        pageTitle: '',
        pageName: this.programsName,
        isFromProgramMilestone: (this.programsName === 'programs'),
        programId: this.programId
      };
      this.titleService.setTitle('Search Results');
      this.liveAnnouncer.announce('Search Results Page', 'assertive');
      this.searchService.selectedOptions.searchText = this.searchText;
      this.searchEnabled = true;
      this.searchService.format = data.format;
      const searchRoute = this.programId ? `view/programs/${this.programId}/search` : '/view/search'
      this.router.navigate([searchRoute], { queryParams: data });
    }
  }
  clearSearch() {
    this.searchText = '';
    this.searchService.selectedOptions.searchText = '';
    this.searchService.searchText = '';
    const elem = document.getElementById('middle-initial');
    elem.focus();
  }
  inputValidation() {
    this.searchService.searchText = this.searchText;
  }

  onResize() {
    if (window.innerWidth <= 980) {
      this.isVisibleMobile = true;
    } else {
      this.isVisibleMobile = false;
    }
  }

}
