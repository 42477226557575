import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, OnDestroy, ViewChild } from '@angular/core';
import { ActionClickResponse, Actions, BookDetail, TitleStateInfo } from '@readingprograms/shared/data/engage.model';
import { EngageService } from '@readingprograms/shared/services/engage.service';
import { checkLoginAndHandleAction } from '@search/shared/helper/search.util';
import {
  ACTION_BUTTON_DATA,
  DEFAULT_SNAKE_BAR_MSG,
  FORMAT_ICON_MAP,
  SNAKE_BAR_MODE,
  ACTION_BUTTON,
  ACTION_BUTTON_TEXT,
  POPUP_ACTION_BUTTON,
  EMAIL_HOLD_NOTIFICATION,
  DEFAULT_ACCESS_DENIED_MSG
} from '@shared/constants/app.constants';
import {
  displayErrorMessage,
  forceRedirect,
  getFormatType,
  getValueFromCaseinsensitiveAttr,
  getYesOrNo,
  strToBool
} from '@shared/helper/app.util';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { UserService } from '@shared/services/user.service';
import { noop, Subscription } from 'rxjs';
import { ConfigService } from '@shared/services/config.service';
import { ActionData, CurrentLibrary } from '@shared/data/config.model';
import { MatDialog } from '@angular/material/dialog';
import { ProfileInfoService } from '@shared/services/profile-info.service';
import { ProfileInfo } from '@shared/data/profile-info.model';
import { PROFILE_TYPES } from '../../../profile/shared/constants/profiles.constants';
import { AccountComponent } from 'app/core/account/account.component';
import { SharedService } from '@shared/services/shared.service';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { CommonService } from '@shared/services/common.service';
import { AccountService } from '@shared/services/account.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { NotificationsService } from '@notifications/shared/services/notifications.service';
import { BOOLIAN_FLAGS } from 'app/core/kz-reading-programs/kz-programs/shared/constants/kz-programs.constants';
import { ProfileDetail } from '@profile/shared/data/profiles.model';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { Router } from '@angular/router';
import { MileStoneProgramBook } from '@shared/data/programs-base.model';
import { PROGRAM_TYPE } from '@readingprograms/shared/constants/programs.constants';
import { ADD_CONFIRM_ALERT } from '@titledetails/shared/constants/title-details.constants';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
declare const window: any;
@Component({
  selector: 'axis360-title-action-buttons',
  templateUrl: './title-action-buttons.component.html',
  styleUrls: ['./title-action-buttons.component.scss']
})
export class TitleActionButtonsComponent implements OnInit, OnDestroy {
  MileStoneProgramBook: MileStoneProgramBook = {};
  @Input() bookDetail: BookDetail;
  @Input() bookProgress = 0;
  @Input() bookSequence = 0;
  @Input() showAction = true;
  @Input() isProgressCalculate = true;
  @Input() showPercentage = false;
  @Input() fromHomeCarousel = false;
  @Input() isMyStuff = false;
  @Input() isDueDate = false;
  @Input() isHold = false;
  @Input() isRecommendation = false;
  @Input() isHistory = false;
  @Output() titleMarkAsReadEvent = new EventEmitter();
  @Output() menuStateChangeEvent = new EventEmitter<boolean>();
  @Output() titleCheckoutEvent = new EventEmitter();
  @Output() titleActionEvent = new EventEmitter();
  @ViewChild('matMenuItem') matMenuTrigger: MatMenuTrigger;
  @Input() programType = '';
  @Input() isParticipant;
  @Input() isSuggestedBook;
  @Input() seeAll: boolean;
  @Input() searchResult: boolean;
  @Input() isAriaLabel: string;
  public emailUpdateOpened = false;
  public loginOpened = false;
  currentLibrary: Partial<CurrentLibrary>;
  currentProfile: Partial<ProfileInfo>;
  titleStateInfo: TitleStateInfo;
  upDateButtonViewSubscription: Subscription;
  upDateAllButtonViewSubscription: Subscription;
  ACTION_BUTTON_DATA = ACTION_BUTTON_DATA;
  getValueFromCaseinsensitiveAttr = getValueFromCaseinsensitiveAttr;
  programId: string;
  sequenceVal: number;
  disableAdd: any;
  searchBtnFunc?: () => void;

  showAddToWishList = true;

  fromLogin = false;
  focusElementCTA = '';
  physicalInventoryData:any;
  constructor(
    public engageService: EngageService,
    public spinnerService: SpinnerService,
    public toastService: SnackBarService,
    public userService: UserService,
    public configService: ConfigService,
    public profileService: ProfileInfoService,
    public liveAnnouncer: LiveAnnouncer,
    public overlayContainer: OverlayContainer,
    @Inject(DOCUMENT) public document: Document,
    public matDialog: MatDialog,
    public elRef: ElementRef,
    public sharedService: SharedService,
    public sharedAnalyticsService: SharedAnalyticsService,
    public accountService: AccountService,
    public commonService: CommonService,
    public location: Location,
    public kzProgramsService: KzProgramsService,
    public notificationsService: NotificationsService,
    public programAnalyticsService: ProgramsAnalyticsService,
    public router: Router,
    public titleDetailsService: TitleDetailsService
  ) { }

  ngOnInit(): void {
    this.disableAdd = !this.isParticipant;
    this.initBookDetail();
    this.addMarkAsReadAction(this.bookDetail?.titleStateInfo);
    this.addRemoveButton(this.bookDetail?.titleStateInfo);
    this.currentLibrary = this.configService.currentLibrary;
    if (this.currentLibrary.partnerSiteEnabled) {
      this.showAddToWishList = false;
    }
    this.currentProfile = this.profileService.profileInfo;
    this.upDateButtonViewSubscription = this.sharedService.getUpdateButtonView().subscribe((updateCTA) => {
      if (updateCTA !== null && this.bookDetail.itemId === updateCTA.itemId) {
        this.getBookActions(updateCTA, false, this.isHistory === true);
      }
    });
    this.userService.menuEmitter?.subscribe(this.toggleMenu.bind(this));
    this.upDateAllButtonViewSubscription = this.sharedService.getUpdateAllButtonView().subscribe((updatedBookDetail) => {
      if (
        updatedBookDetail &&
        this.bookDetail.itemId === updatedBookDetail.itemId &&
        this.bookDetail.titleStateInfo.actions[0].actionButtonId !== 'btnAddProgram'
      ) {
        this.titleStateInfo = updatedBookDetail.titleStateInfo;
      }
    });
    this.kzProgramsService.programId.subscribe((id: string) => {
      if (!id) {
        return;
      }
      this.programId = id;
    });
  }

  toggleMenu(state: boolean): void {
    if (!state) {
      this.matMenuTrigger?.closeMenu();
    }
  }

  ngOnDestroy(): void {
    this.upDateButtonViewSubscription?.unsubscribe();
    this.upDateAllButtonViewSubscription?.unsubscribe();
  }

  menuStateChange(state: boolean) {
    this.menuStateChangeEvent && this.menuStateChangeEvent.emit(state);
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('refiner-overlay-container');
      this.document.body.classList.add('scroll-menu');
      this.userService.menuEmitter.next(true);
      return;
    }
    this.overlayContainer.getContainerElement().classList.remove('refiner-overlay-container');
    this.document.body.classList.remove('scroll-menu');
    this.userService.menuEmitter.next(false);
  }

  addMarkAsReadAction(titleStateInfo: TitleStateInfo) {
    const isEnableTracking = strToBool(this.profileService.currentProfile.enableTracking);
    if (!isEnableTracking && titleStateInfo?.actions &&
      titleStateInfo?.actions[0]?.actionButtonId !== 'btnCheckoutNow' &&
      this.bookDetail?.isAvailable &&
      this.bookDetail?.isRTV
    ) {
      if (this.programType === PROGRAM_TYPE.MileStone) {
        if (!this.bookDetail.isManual && !this.fromHomeCarousel && !this.isSuggestedBook) {
          const titleActionsOld = titleStateInfo?.actions.filter((action) => action.actionButtonId !== ACTION_BUTTON_DATA.MARK_AS_DONE);
          titleStateInfo.actions = [];
          if (this.bookProgress < 100) {
            titleStateInfo?.actions.push({ actionButtonId: ACTION_BUTTON_DATA.MARK_AS_DONE });
          } else {
            titleStateInfo?.actions.push({ actionButtonId: 'btnCompleted' });
          }
          titleStateInfo?.actions.push(...titleActionsOld);
        }
      } else {
        if (this.programType) {
          const titleActionsOld = titleStateInfo?.actions;
          titleStateInfo.actions = [];
          if (!this.fromHomeCarousel) {
            if (this.bookProgress < 100) {
              titleStateInfo?.actions.push({ actionButtonId: ACTION_BUTTON_DATA.MARK_AS_READ });
            } else {
              titleStateInfo?.actions.push({ actionButtonId: 'btnCompleted' });
            }
          }
          titleStateInfo?.actions.push(...titleActionsOld);
        }
      }
    }
    this.titleStateInfo = titleStateInfo;
    const progressLabel = this.showPercentage && this.bookProgress > 0 ? `, and completed ${this.bookProgress} % ` : ', ';
    if (this.bookSequence > 0) {
      const detAriaLable = `${this.bookSequence} ,book type is ${this.getIcon(this.bookDetail?.formatType).alt}, title is ${this.bookDetail?.title
        } , and author is ${this.bookDetail?.author}${progressLabel}${this.titleStateInfo?.displayStatus}`;
      document.getElementById('title-img-' + this.bookDetail?.itemId)?.setAttribute('aria-label', detAriaLable);
      return;
    }
    const joinAriaLable1 = `book type is ${this.getIcon(this.bookDetail?.formatType).alt} ,title is ${this.bookDetail?.title
      }, and author is ${this.bookDetail?.author}${progressLabel}${this.titleStateInfo?.displayStatus}`;
    document.getElementById('title-img-' + this.bookDetail?.itemId)?.setAttribute('aria-label', joinAriaLable1);
  }
  markAsDone() {
    const isEnableTracking = strToBool(this.profileService.currentProfile.enableTracking);
    const markAsDone = isEnableTracking || this.bookProgress === 100 ? 'No' : 'Yes';
    const markAsNotDone = this.bookProgress === 0 ? 'No' : 'Yes';
    this.spinnerService
      .withObservable(
        this.engageService.markAsRead(
          this.bookDetail?.itemId,
          this.bookDetail?.isbn,
          this.bookDetail.formatType,
          this.bookDetail.isManual ? this.bookDetail.isManual : false,
          markAsDone,
          markAsNotDone
        )
      )
      .subscribe(
        () => {
          const actionButton = markAsNotDone === 'Yes' ? ACTION_BUTTON_DATA.MARK_AS_DONE : ACTION_BUTTON_DATA.MARK_AS_NOT_DONE;
          const titleActionsOld = this.titleStateInfo?.actions.filter(
            (action) =>
              action.actionButtonId !== ACTION_BUTTON_DATA.MARK_AS_DONE && action.actionButtonId !== ACTION_BUTTON_DATA.MARK_AS_NOT_DONE
          );
          this.titleStateInfo.actions = [];
          if (this.bookDetail.isManual) {
            this.titleStateInfo.actions.push({ actionButtonId: actionButton });
          }
          if (!this.bookDetail.isManual) {
            this.titleStateInfo.actions.push({ actionButtonId: 'btnCompleted' });
          }
          this.titleStateInfo.actions.push(...titleActionsOld);
          this.bookDetail.titleStateInfo = this.titleStateInfo;
          this.titleMarkAsReadEvent.emit(actionButton);
        },
        () => {
          this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        }
      );
  }
  removeTitlefromProgram() {
    const removeObj = {
      milestoneBooks: {
        externalBooks: [],
        libraryBooks: []
      }
    };
    if (this.bookDetail.isManual) {
      removeObj.milestoneBooks.externalBooks.push({
        bookId: this.bookDetail.itemId
      });
    } else {
      removeObj.milestoneBooks.libraryBooks.push({
        bookId: this.bookDetail.itemId
      });
    }
    this.spinnerService.withObservable(this.kzProgramsService.deleteMilestoneBook(this.programId, removeObj)).subscribe((result) => {
      if (result.responseCode === 200) {
        this.toastService.showSnackBar(SNAKE_BAR_MODE.INFO, result.message);
        this.titleMarkAsReadEvent.emit(ACTION_BUTTON_DATA.REMOVE);
        this.searchResult && this.backToProgramDetial();
      }
    });
  }
  addBooktoProgram(action: Actions) {
    Object.assign(this.MileStoneProgramBook, {
      MilestoneBooks: {
        libraryBooks: [
          {
            bookId: this.bookDetail.itemId,
            sequence: this.kzProgramsService.tempSequenceId + 1
          }
        ]
      }
    });
    this.spinnerService.withObservable(this.kzProgramsService.addBooks(this.programId, this.MileStoneProgramBook)).subscribe((result) => {
      if (result.errors) {
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, displayErrorMessage(result));
        return;
      }
      this.openPopup(action.isSuggestedbook);
    });
  }

  openPopup(isSuggestedbook) {
    this.commonService.showAddDialog(
      {
        ...ADD_CONFIRM_ALERT,
        searchBtnFunc: () => this.backToProgramDetial(isSuggestedbook)
      },
      isSuggestedbook
    );
  }
  backToProgramDetial = (suggestedBook = false) => {
    this.kzProgramsService.titleId = 'ADD_NEW_TITLE';
    if (suggestedBook) { this.kzProgramsService.titleId = 'title_' + this.titleStateInfo.actions[0].itemId }
    !suggestedBook && this.router.navigateByUrl(`/view/programs/${this.programId}`);
  };
  getIcon(formatType) {
    return FORMAT_ICON_MAP[getFormatType(formatType)];
  }
  getFormatType(formatType: string) {
    const formatTypeMap = { ['ebook']: 'ebook', ['audio']: 'audiobook', ['book']: 'Print Book' };
    return formatTypeMap[FORMAT_ICON_MAP[formatType].icon];
  }

  markAsRead() {
    this.spinnerService
      .withObservable(
        this.engageService.markAsRead(this.bookDetail?.itemId, this.bookDetail?.isbn, this.getFormatType(this.bookDetail?.formatType))
      )
      .subscribe(
        () => {
          setTimeout(() => {
            const titleActionsOld = this.titleStateInfo?.actions.filter(
              (action) => action.actionButtonId !== ACTION_BUTTON_DATA.MARK_AS_READ
            );
            this.titleStateInfo.actions = [];
            this.titleStateInfo.actions.push({ actionButtonId: 'btnCompleted' });
            this.titleStateInfo.actions.push(...titleActionsOld);
            this.titleMarkAsReadEvent.emit(ACTION_BUTTON_DATA.MARK_AS_READ);
          }, 500);
        },
        () => {
          this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
        }
      );
  }

  confirmPopupByTitleActionHanlder(event, actionType?: string, action?: Actions, title?: string, eventId?) {
    const bookTitle = title?.charAt(title.length - 1);
    const skipLinkContent = this.document?.getElementById('loc_btnSkiptoMain');
    skipLinkContent?.setAttribute('aria-hidden', 'true');
    this.commonService.showAlertDialog({
      content: `Are you sure you want to ${ACTION_BUTTON_TEXT[actionType]} ${title} ${bookTitle === '?' ? '' : '?'} `,
      submitBtnText: 'Ok',
      cancelBtnText: 'Cancel',
      submitBtnFunc: () => {
        this.titleActionHandlerWrapper(event, actionType, action, eventId);
      },
      cancelBtnFunc: () => {
        this.secondaryOptionsCTA(eventId, 'Cancelbtn');
      }
    });
  }
  isValidateProfileTitle(titleDetailsData) {
    if (this.currentProfile.profileType.toLowerCase() === PROFILE_TYPES.TEEN) {
      return !titleDetailsData.audience.includes('General Adult');
    } else if (this.currentProfile.profileType.toLowerCase() === PROFILE_TYPES.KID) {
      return titleDetailsData.audience.includes('Children');
    } else {
      return true;
    }
  }

  titleActionHandlerWrapper = (event, actionType: string, action: Actions, eventId?, titleDetailsData?) => {
    if (titleDetailsData && !this.isValidateProfileTitle(titleDetailsData)) {
      this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_ACCESS_DENIED_MSG);
      return;
    }
    this.kzProgramsService.removeEditProgramNotification.next(false);
    if (actionType === 'checkout') {
      this.sharedService?.updateActionsButtonCheckoutCTA?.next('checkout');
    }
    const parentId = this.elRef.nativeElement.closest('.homepage-carousel');
    this.engageService.homeCarouselListId = parentId?.id;
    if (this.fromHomeCarousel && !this.userService.isLoggedIn()) {
      const ngTitleActionHandler = 'ngTitleActionHandler';
      window[ngTitleActionHandler] = this.titleActionHandler;
      event.data = 'fromHomeCarousel';
      event.actionType = actionType;
      event.action = action;
      checkLoginAndHandleAction(event);
      return;
    }
    this.toReturnActionHandler(event, actionType, action, eventId);
    this.titleActionAnalytics(actionType);
    if (action.actionButtonId === 'btnMarkAsDone' || action.actionButtonId === 'btnMarkAsNotDone') {
      setTimeout(() => {
        this.isCheckActionButtonId(action.actionButtonId === 'btnMarkAsDone' ? 'btnMarkAsNotDone' : 'btnMarkAsDone');
      }, 2000);
    }
  };
  isCheckActionButtonId(id) {
    const btnId = id + '_' + this.bookDetail.itemId;
    if (btnId) {
      setTimeout(() => {
        const btnMarkAsFocus: HTMLElement = document.getElementById(btnId);
        btnMarkAsFocus && btnMarkAsFocus.focus();
      }, 2000);
    }
  }
  titleActionHandler = (event: PointerEvent, actionType: string, action: Actions, eventId?) => {
    if (actionType === ACTION_BUTTON_DATA.BUY_NOW) {
      window.BuyNowHandler(event);
      return;
    }
    if (actionType === ACTION_BUTTON_DATA.ADD_PROGRAM) {
      this.addBooktoProgram(action);
      return;
    }
    if (actionType === ACTION_BUTTON_DATA.MARK_AS_DONE || actionType === ACTION_BUTTON_DATA.MARK_AS_NOT_DONE) {
      this.markAsDone();
      return;
    }
    if (actionType === ACTION_BUTTON_DATA.REMOVE) {
      this.commonService.showAlertDialog({
        content: `Are you sure you want to delete the title?`,
        submitBtnText: 'Ok',
        cancelBtnText: 'Cancel',
        submitBtnFunc: () => this.removeTitlefromProgram(),
        cancelBtnFunc: () => {
          setTimeout(() => {
            this.document.getElementById('moreoption_' + this.bookDetail.itemId)?.focus();
          }, 200);
          this.programAnalyticsService.trackNotificationsEvent('CANCEL')
        }
      });
      return;
    }
    if (actionType === ACTION_BUTTON_DATA.ADD_HOLD) {
      const data = this.getDataForAction(actionType, action);
      this.actionHandler(data, eventId);
      return;
    }
    if (actionType === ACTION_BUTTON_DATA.MARK_AS_READ) {
      this.markAsRead();
      return;
    }
    const actionData = this.getDataForAction(actionType, action);
    this.actionHandler(actionData, eventId);
  };
  toReturnActionHandler = (event: PointerEvent, actionType: string, action: Actions, eventId?) => {
    if (!this.userService.userInfo.isLoggedIn) {
      this.openLoginModel(event, actionType, action);
      return;
    }
    this.titleActionHandler(event, actionType, action, eventId);
  };
  updateProfile() {
    const profileId = this.profileService.currentProfile.profileId;
    const currentProfile = this.profileService.currentProfile;
    currentProfile.defaultLandingPageID = currentProfile.defaultLandingPageID ? currentProfile.defaultLandingPageID : '2';
    currentProfile.displayCheckoutHistory = getYesOrNo(strToBool(currentProfile.displayCheckoutHistory));
    currentProfile.enableTracking = getYesOrNo(strToBool(currentProfile.enableTracking));
    currentProfile.displayTrackingPrompt = getYesOrNo(strToBool(currentProfile.displayTrackingPrompt));
    currentProfile.highContrast = getYesOrNo(strToBool(currentProfile.highContrast));
    currentProfile.highContrast = getYesOrNo(strToBool(currentProfile.highContrast));
    currentProfile.isEmailEnabled = getYesOrNo(strToBool(currentProfile.isEmailEnabled));
    currentProfile.pinStatus = getYesOrNo(strToBool(currentProfile.pinStatus));
    currentProfile.emailNotificationAlert = 'N';
    currentProfile.displayTrackingPrompt = BOOLIAN_FLAGS.FALSE;
    const request: ProfileDetail = { ...this.profileService.currentProfile };
    this.profileService.updateProfile(profileId, request).subscribe(() => {
      this.currentProfile.emailNotificationAlert = 'False';
    });
  }
  getDataForAction = (actionType: string, action: Actions) => {
    switch (actionType) {
      case 'checkout':
      case 'renewtitle':
      case 'returntitle':
      case 'removefromcheckouthistory':
        return {
          actiontype: actionType,
          itemId: action.itemId,
          reservationId: action.reservationId,
          formatType: action.formatType,
          title: this.bookDetail?.title,
          isbn: action.isbn,
          collectionType: this.bookDetail?.CollectionType || this.bookDetail?.collectionType
        };
      case 'addtohold':
      case 'cancelhold':
      case 'suspendhold':
      case 'unsuspendhold':
        return {
          actiontype: actionType,
          itemId: action.itemId,
          isRecommendable: this.bookDetail?.IsRecommendable,
          formatType: action.formatType,
          title: this.bookDetail?.title,
          isRTV: this.bookDetail?.IsRTV
        };

      case 'addtowishlist':
      case 'removefromwishlist':
        return {
          actiontype: actionType,
          itemId: action.itemId,
          formatType: action.formatType,
          title: this.bookDetail?.title,
          collectionType: this.bookDetail?.CollectionType || this.bookDetail?.collectionType
        };
      case 'recommend':
      case 'cancelrecommendation':
        return {
          actiontype: actionType,
          itemId: action.itemId,
          formatType: action.formatType,
          isRecommendable: this.bookDetail?.IsRecommendable,
          title: this.bookDetail?.title
        };
    }
  };

  getBookActions(bookDetail: BookDetail, isHold: boolean = false, isHistory = false) {
    this.engageService.getBookActions(bookDetail, isHistory).subscribe((response) => {
      const titleAriaLabel = document.getElementById(`title_${bookDetail.itemId}`) as any;
      if (response[0].state === 3 && isHold) {
        this.sharedService.setUpdateButtonView(bookDetail);
        return;
      }
      this.bookDetail.titleStateInfo = response[0];
      this.sharedService.setUpdateAllButtonView(this.bookDetail);
      this.addMarkAsReadAction(response[0]);
      this.addRemoveButton(response[0]);
      if (titleAriaLabel && titleAriaLabel?.ariaLabel?.includes('Waitlist') && bookDetail.titleStateInfo.displayStatus === 'ON HOLD' && bookDetail.titleStateInfo.holdPosition != '') {
        titleAriaLabel?.setAttribute(
          'aria-label',
          titleAriaLabel?.ariaLabel?.replace('Book Status Waitlist', `Book Status ${bookDetail.titleStateInfo.holdPosition}`)
        );
      }
      if (titleAriaLabel && titleAriaLabel?.ariaLabel?.includes('Book Status Hold Position') && bookDetail.titleStateInfo.displayStatus === 'UNAVAILABLE') {
        const holdPostion = bookDetail.activeHolds + 1;
        titleAriaLabel?.setAttribute(
          'aria-label',
          titleAriaLabel?.ariaLabel?.replace(`Book Status ON HOLD, Hold Position: ${holdPostion}`, 'Book Status Waitlist')
        );
      }
    }, noop);
  }
  /*
    Add Remove CTA for Milestone Program title card
  */
  addRemoveButton(titleStateInfo: TitleStateInfo) {
    const titleActionsOld = this.titleStateInfo?.actions?.filter((action) =>
      action.actionButtonId !== ACTION_BUTTON_DATA.REMOVE)
    const removeAction = this.titleStateInfo?.actions?.filter((action) =>
      action.actionButtonId === ACTION_BUTTON_DATA.REMOVE).length > 0
    const searchResultProgramRemoveCTA = this.searchResult && removeAction
    if ((this.programType === PROGRAM_TYPE.MileStone && !this.isSuggestedBook && !this.seeAll) || searchResultProgramRemoveCTA) {
      titleActionsOld.push({ actionButtonId: ACTION_BUTTON_DATA.REMOVE });
    }
    titleStateInfo.actions = (!titleActionsOld || titleActionsOld?.length === 0) ? [] : [...titleActionsOld];
    this.titleStateInfo = titleStateInfo;
  }

  getActionHandlerMessage(response: ActionClickResponse) {
    if (response.primaryStatusMessage) {
      return response.primaryStatusMessage;
    }
    if (response.secondaryMessage) {
      return response.secondaryMessage;
    }
    if (response.bottomMessage) {
      return response.bottomMessage;
    }
  }

  actionHandler(data: any, eventId?) {
    this.spinnerService.withObservable(this.engageService.bookActionClick(data), undefined, false).subscribe(
      (response: ActionClickResponse) => {
        this.getMyStuffData();
        this.spinnerService.isOverlayLoading = false;
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        if (response.secondaryMessage === 'Already added by another profile.') {
          this.openAddWishListCTAModel(response.primaryStatusMessage, data);
          return;
        }
        const isShowSnackBar = response.primaryStatusMessage.includes('To borrow this title, return a checked out item.')
          ? SNAKE_BAR_MODE.ERROR_UH_OH
          : SNAKE_BAR_MODE.ERROR;
        this.toastService.showSnackBar(
          response.status
            ? SNAKE_BAR_MODE.INFO
            : isShowSnackBar,
          this.getActionHandlerMessage(response)
        );
        this.fromLogin && this.accountService.isActionCompleted.next(true);
        this.fromLogin = false;
        this.getBookActions(this.bookDetail, data?.actiontype === 'addtohold', this.isHistory === true);
        if ((data?.actiontype === 'checkout' || data?.actiontype === 'returntitle') && this.fromHomeCarousel) {
          this.titleCheckoutEvent.emit();
        }
        if (data?.actiontype === 'checkout' || (data?.actiontype === 'returntitle' && !this.fromHomeCarousel)) {
          this.notificationsService.updateMessagesCount();
        }
        if (response.status) {
          this.showEmailNotificationAlert(data);
        }
        this.titleActionEvent.emit();
        if (!response.status && eventId) {
          eventId.btnId = eventId?.errorId
        };
        this.secondaryOptionsCTA(eventId);
        this.sharedService.setIsCheckTitleActionCTAList(data?.actiontype);
        this.sharedService.setRefinerSubject('checkCount');
      },
      () => {
        this.liveAnnouncer.announce('Finished Loading.', 'polite');
        this.toastService.showSnackBar(SNAKE_BAR_MODE.ERROR, DEFAULT_SNAKE_BAR_MSG);
      }
    );
  }
  showEmailNotificationAlert(data, eventId?: any) {
    const currentProfile = this.profileService.currentProfile;
    if (
      data?.actiontype === ACTION_BUTTON_DATA.ADD_HOLD &&
      !currentProfile.profileEmail &&
      strToBool(this.currentProfile.emailNotificationAlert)
    ) {
      const alertNewDialog = this.commonService.showEmailNotificationAlertDialog({
        ...EMAIL_HOLD_NOTIFICATION,
        panelClass: 'setpin-alert-dialog'
      });
      alertNewDialog.afterClosed().subscribe(() => {
        const unknownBtuView = this.titleStateInfo.actions.filter(x => x.actionButtonId === "btnAccessinApp").length > 0;
        const btnFocusId = unknownBtuView ? { btn: 'moreOption', btnId: 'loc_Access_On_App' } : eventId;
        this.secondaryOptionsCTA(btnFocusId);
      });
      this.updateProfile();
    }
  }
  showFavorite(action: Actions[]) {
    return action.some((ele) => ele.actionButtonId === 'btnRemoveWishlist') || false;
  }
  menuState(state: boolean, focusAction?: any) {
    this.menuStateChangeEvent && this.menuStateChangeEvent.emit(state);
    if (state) {
      this.overlayContainer.getContainerElement().classList.add('kz-mat-menu-overlay');
      document.getElementById('matSidenav')?.setAttribute('aria-hidden', 'true')
      document.getElementById('matSidenav')?.setAttribute('tabindex', '-1');
      this.userService.menuEmitter.next(true);
      this.focusFirstMenuItem();

      if (focusAction.length > 1) {
        const actionButtonId = focusAction[1].actionButtonId || '';
        const itemId = focusAction[1].itemId || '';
        setTimeout(() => {
          const focusActionMenuButtonId = document.getElementById(`loc_${actionButtonId}`);
          focusActionMenuButtonId.focus();
          const focusActionMenuItemId = document.getElementById(`moreoption_${itemId}`);
          focusActionMenuItemId.blur();
        }, 500);
      }
      return;
    }
    this.userService.menuEmitter.next(false);
    this.overlayContainer.getContainerElement().classList.remove('kz-mat-menu-overlay');
    document.getElementById('matSidenav')?.setAttribute('aria-hidden', 'false')
    document.getElementById('matSidenav')?.setAttribute('tabindex', '0');
  }

  // Method to focus the first menu item
  focusFirstMenuItem() {
    setTimeout(() => {
      const firstMenuItem = document.querySelector('.mat-menu-content .mat-menu-item');
      if (firstMenuItem) {
        (firstMenuItem as HTMLElement).focus();
      }
    }, 0);
  }

  initBookDetail() {
    if (this.bookDetail) {
      this.bookDetail.title = getValueFromCaseinsensitiveAttr(this.bookDetail.title, this.bookDetail.Title, '');
      this.bookDetail.isAvailable = getValueFromCaseinsensitiveAttr(this.bookDetail.isAvailable, this.bookDetail.IsAvailable, false);
      this.bookDetail.isRTV = getValueFromCaseinsensitiveAttr(this.bookDetail.isRTV, this.bookDetail.IsRTV, false);
      this.bookDetail.formatType = getValueFromCaseinsensitiveAttr(this.bookDetail.formatType, this.bookDetail.FormatType, '');
      this.bookDetail.author = getValueFromCaseinsensitiveAttr(this.bookDetail.author, this.bookDetail.Author, '');
      this.bookDetail.itemId = getValueFromCaseinsensitiveAttr(this.bookDetail.itemId, this.bookDetail.ItemId, '');
      this.bookDetail.isbn = getValueFromCaseinsensitiveAttr(this.bookDetail.isbn, this.bookDetail.ISBN, '');
      this.bookDetail.runTime = getValueFromCaseinsensitiveAttr(this.bookDetail.runTime, this.bookDetail.RunTime, '');
      this.focusElementCTA = (getFormatType(`${this.bookDetail.formatType}`) === 'EBT') ? 'loc_readCTA' : 'loc_listenCTA';
      if(this.configService.currentLibrary.isPhysitalEnabled == true && (this.bookDetail.format == 'EBT' || this.bookDetail.format == 'ABT')){
        this.getPhysicalInventoryData();
      }
    }
  }

  updateEmail(data: ActionData) {
    if (
      this.currentLibrary.kidsZoneEnabled &&
      (this.currentProfile.profileType.toLowerCase() !== PROFILE_TYPES.ADULT ||
        (!this.currentLibrary.axis360Enabled && this.currentProfile.profileType.toLowerCase() === PROFILE_TYPES.ADULT))
    ) {
      this.openEmailupdateModel(data);
    } else {
      window.updateEmailHandlerWhenPlaceHold(() => {
        this.actionHandler(data);
      });
    }
  }

  async openEmailupdateModel(data: ActionData) {
    if (this.emailUpdateOpened) {
      return false;
    }
    this.emailUpdateOpened = !this.emailUpdateOpened;
    this.matDialog
      .open(AccountComponent, {
        panelClass: 'account-modal-container',
        autoFocus: true,
        ariaLabelledBy: 'account-title',
        data: {
          emailUpdate: true
        }
      })
      .afterClosed()
      .subscribe((response: boolean) => {
        this.emailUpdateOpened = false;
        if (response) {
          this.actionHandler(data);
        }
      });
  }

  openAddWishListCTAModel(primaryStatusMessage?: string, data?: any) {
    if (primaryStatusMessage) {
      this.liveAnnouncer.announce(primaryStatusMessage, 'assertive');
    }
    this.commonService.showAlertDialog({
      heading: POPUP_ACTION_BUTTON[data.actiontype],
      panelClass: 'my-checkout-hold-dialog',
      content: primaryStatusMessage,
      closeIcon: true,
      buttonText: data.actiontype === 'recommend' ? 'Ok' : 'Add to Wishlist',
      type: 'INFO',
      submitBtnFunc: () => {
        const addToWishlist = {
          actiontype: 'addtowishlist',
          itemId: data.itemId,
          formatType: data.formatType,
          title: data?.title,
          collectionType: data?.collectionType
        };
        this.actionHandler(addToWishlist);
      }
    });
  }

  openLoginModel(event: PointerEvent, actionType: string, action: Actions) {
    this.spinnerService
      .withPromise(this.accountService.getLoginData())
      .then((response) => {
        if (response.isRedirect) {
          forceRedirect(response.redirectUrl);
          return;
        }
        if (this.loginOpened) {
          return;
        }
        this.loginOpened = !this.loginOpened;
        this.matDialog
          .open(AccountComponent, {
            panelClass: 'account-modal-container',
            autoFocus: true,
            ariaLabelledBy: 'account-title',
            data: {
              openLoginForm: true,
              fromActionHandler: true
            }
          })
          .afterClosed()
          .subscribe((res: boolean) => {
            this.loginOpened = false;
            if (res) {
              this.fromLogin = true;
              this.titleActionHandler(event, actionType, action);
            }
          });
      })
      .catch(() => {
        this.commonService.showErrorAlert();
      });
  }
  titleActionAnalytics(actiontype: string) {
    this.sharedAnalyticsService.trackActionButtonEvent('CLICK_TITLE_ACTION', { actionName: ACTION_BUTTON[actiontype] });
  }

  openNewTabCTA(linkData: { itemId?: string; isbn?: string; buyNowUrl?: string }, actions: string) {
    switch (actions) {
      case 'read':
        {
          const readURL = `/Action/ReadNowClick?itemId=${linkData.itemId}&ISBN=${linkData.isbn}`;
          window.open(readURL, '_blank');
          break;
        }
      case 'listen':
        {
          const listenURL = `/Action/ListenNowClick?itemId=${linkData.itemId}&ISBN=${linkData.isbn}`;
          window.open(listenURL, '_blank');
          break;
        }
      case 'download':
        {
          const downloadURL = `/Action/DownloadNowClick?itemId=${linkData.itemId}`;
          window.open(downloadURL, '_blank');
          break;
        }
      case 'viewPack':
        {
          const viewPackURL = `/coursepack?itemId=${linkData.itemId}&ISBN=${linkData.isbn}`;
          window.open(viewPackURL, '_blank');
          break;
        }
      case 'buyNow':
        window.open(linkData.buyNowUrl, '_blank');
        break;
      default:
        break;
    }
  }
  /**
   * Get MyShelfData list by calling API on init
   */
  getMyStuffData() {
    if (!this.userService.isLoggedIn()) {
      return;
    }
    this.sharedService.getMyShelfNavigation().subscribe((responce) => {
      this.profileService.getMyStuffCounts(responce);
    });
  }

  secondaryOptionsCTA(eventId, action?: string) {
    const sec = this.titleDetailsService.statusUserAgentCheck ? 5000 : 2000;
    setTimeout(() => {
      if (eventId?.btn === 'moreOption' && action === 'Cancelbtn') {
        this.document.getElementById(`${eventId.errorId}`)?.focus();
        return;
      }
      if (eventId?.btn === 'moreOption') {
        this.document.getElementById(`${eventId.btnId}`)?.focus();
      }
      if (eventId?.btn === 'moreOptionCTA') {
        const focusActionMenuButtonId = document.getElementById(`moreoption_${eventId.btnId}`);
        focusActionMenuButtonId?.focus();
      }

    }, sec);
  }
   /**
   * Check Print Availability for Physical Inventory Service (Phygital)
   */
   async getPhysicalInventoryData(){
    this.spinnerService.showLoader();
    this.physicalInventoryData = [];
    const title = encodeURIComponent(this.bookDetail.title);
    const author = encodeURIComponent(this.bookDetail.authors[0]);
    const getPhysicalInventory = await this.titleDetailsService.getPhysicalInventory(this.configService.currentLibrary.id,title,author,'ByTitleDetail');   
    this.physicalInventoryData = getPhysicalInventory;
    this.spinnerService.showLoader(false);
  }
}
