import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BookDetail } from '@shared/data/config.model';
import { cdkRemoveVisualHidden, cdkVisualHidden, forceRedirect, getBookIcon, getValueFromCaseinsensitiveAttr, replaceTimeStrings } from '@shared/helper/app.util';
import { SharedAnalyticsService } from '@shared/services/shared.analytics.service';
import { TitleStateInfo } from '@readingprograms/shared/data/engage.model';
import { LIBRARY_CAROUSEL_FOOTER_TEXT, CAROUSEL_CONST } from '@shared/constants/app.constants';
import { CommonService } from '@shared/services/common.service';
import { HeaderAnalyticsService } from 'app/header/shared/services/header.analytics.service';
import { ProgramsAnalyticsService } from '@readingprograms/shared/services/programs.analytics.service';
import { KzProgramsService } from 'app/core/kz-reading-programs/kz-programs/shared/service/kz-programs.service';
import { MatDialog } from '@angular/material/dialog';
import { PROGRAMS_STATUS_TEXT } from 'app/core/kz-reading-programs/kz-programs/shared/constants/kz-programs.constants';
import { AdvancedSearchComponent } from 'app/header/advanced-search/advanced-search.component';
import { ADDPOPUP_ALERT } from '@titledetails/shared/constants/title-details.constants';
import { Router } from '@angular/router';
import { ProgramDetails } from '@readingprograms/shared/data/programs.model';
import { TitleDetailsService } from '@titledetails/shared/services/title-details.service';
import { AlertDialogNewComponent } from '../alert-dialog-new/alert-dialog-new.component';
import { ConfigService } from '@shared/services/config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'axis360-title-card',
  templateUrl: './title-card.component.html',
  styleUrls: ['./title-card.component.scss']
})
export class TitleCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() myPrograms;
  @Input() titleContent: BookDetail;
  @Input() type = '';
  @Output() titleActionEvt = new EventEmitter();
  @Output() seeAllClickEvent = new EventEmitter();
  @Output() cardClickEvent = new EventEmitter();
  @Output() notificationEvent = new EventEmitter();
  @Output() ariaLabel = new EventEmitter<string>();
  @Input() showAction = true;
  @Input() parentRoute: string;
  @Input() isDueDate = false;
  @Input() isProgramCTAShow = false;
  @Input() isMyStuff = false;
  @Input() isHold = false;
  @Input() isRecommendation = false;
  @Input() isFeaturedProgram: boolean;
  @Input() isProgramDetail: boolean;
  @Input() isCurrentlyCheckout = false;
  @Input() programId = '';
  @Input() addCard: boolean;
  @Input() programDetails: ProgramDetails;
  @Input() bookProgress = 0;
  @Input() isParticipant: boolean;
  @Output() titleMarkAsReadEventData = new EventEmitter();
  @Input() isSuggestedBook;
  @Input() notificationClass = '';
  PROGRAMS_STATUS_TEXT = { ...PROGRAMS_STATUS_TEXT };
  @Input() isDeleteFlag: boolean;
  @Input() seeAll;
  @Input() searchResult;
  @Input() isCarousel?: string;
  @Input() isMyshuffBox = false;
  @Input() myShelfLoad: boolean;
  @Input() isReadingProgress: boolean = false;
  programType: string;
  advanceSearch = false;
  searchText = '';
  numberofBooks: any;
  isBookLimitExceed = false;
  isAddTitleDisable: boolean;
  initTitleAriaLabel: string;
  typeCardClick: string;
  BookImg = '/angular-app/assets/images/kz-images/9781843655008 1.png';
  unavailable = false;
  titleStateInfo: TitleStateInfo;
  eBook = LIBRARY_CAROUSEL_FOOTER_TEXT.eBook;
  eAudio = LIBRARY_CAROUSEL_FOOTER_TEXT.eAudio;
  printBook = LIBRARY_CAROUSEL_FOOTER_TEXT.printBook;
  waitList = LIBRARY_CAROUSEL_FOOTER_TEXT.Waitlist;
  onHold = LIBRARY_CAROUSEL_FOOTER_TEXT.onHold;
  unAvailableStatus = LIBRARY_CAROUSEL_FOOTER_TEXT.unAvailable;
  readyForCheckout = LIBRARY_CAROUSEL_FOOTER_TEXT.readyForCheckout;
  holdSuspended = LIBRARY_CAROUSEL_FOOTER_TEXT.holdSuspended;
  comingSoon = LIBRARY_CAROUSEL_FOOTER_TEXT.comingSoon;
  available = LIBRARY_CAROUSEL_FOOTER_TEXT.available;
  requested = LIBRARY_CAROUSEL_FOOTER_TEXT.requested;
  featured = CAROUSEL_CONST.featured;
  booktitle = CAROUSEL_CONST.booktitle;
  format = CAROUSEL_CONST.format;
  duration = CAROUSEL_CONST.duration;
  bookstatus = CAROUSEL_CONST.bookstatus;
  pastCheckout = LIBRARY_CAROUSEL_FOOTER_TEXT.pastCheckout;
  expieingSoon = LIBRARY_CAROUSEL_FOOTER_TEXT.expieingSoon;
  forceRedirect = forceRedirect;
  getValueFromCaseinsensitiveAttr = getValueFromCaseinsensitiveAttr;
  replaceTimeStrings = replaceTimeStrings;
  cdkVisualHidden = cdkVisualHidden;
  titleDetailLink: string;
  playerURL: string;
  addCardDialog: any;
  constructor(
    private sharedAnalyticsService: SharedAnalyticsService,
    private router: Router,
    private commonService: CommonService,
    public headerAnalyticsService: HeaderAnalyticsService,
    public programAnalyticsService: ProgramsAnalyticsService,
    public kzProgramsService: KzProgramsService,
    private matDialog: MatDialog,
    public titleDetailsService: TitleDetailsService,
    public configService: ConfigService,
    public titleService: Title
  ) { }
  ngOnInit(): void {
    this.programType = this.programDetails?.type;
    this.numberofBooks = this.programDetails?.numberOfBooks;
    this.checkMilestoneAddTitle();
    this.programType = this.programDetails?.type;
    this.initTitleContent();
    if (this.titleContent?.formatType === 'ABT' && (this.titleContent?.runTime === null || this.titleContent?.RunTime === null)) {
      this.titleContent.runTime = undefined;
      this.titleContent.RunTime = undefined;
    }
    if (this.isShowWaitList() && !this.titleContent?.isManual && !this.isCurrentlyCheckout && this.titleContent.DisplayStatus !== 'requested' && this.titleContent?.DisplayStatus !== this.pastCheckout && this.titleContent?.formatType) {
      this.initTitleAriaLabel = this.bookstatus + this.waitList + ', ' + this.initTitleAriaLabel;
    } else {
      const initTitleAriaLabel = this.initTitleAriaLabel
      this.initTitleAriaLabel = initTitleAriaLabel + (this.isCurrentlyCheckout ? ', Opens in a new tab': '');
    }
  }
  isMobile(): boolean {
    const matchResult = /(android|iPhone|iPod|iPad)/i.exec(navigator.userAgent);
    return matchResult !== null;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDeleteFlag) {
      this.checkMilestoneAddTitle();
    }
  }
  checkMilestoneAddTitle() {
    this.isBookLimitExceed =
      this.programDetails && this.programDetails.books?.length > 1 &&
      !(this.programDetails.numberOfBooks > this.programDetails.books?.length - 1);
    this.isAddTitleDisable =
      (this.isBookLimitExceed ||
        !(this.isParticipant || this.programDetails?.isParticipant) ||
        this.programDetails?.status === PROGRAMS_STATUS_TEXT.COMPLETED ||
        this.programDetails?.status === PROGRAMS_STATUS_TEXT.JOINED ||
        this.programDetails?.status === PROGRAMS_STATUS_TEXT.NOT_COMPLETED);
  }
  /*Program Card Validation*/
  validateProgram(programType) {
    if (programType === 'Milestone') {
      this.addCard = false;
    } else {
      this.addCard = true;
    }
  }
  titleMarkAsReadEvent(event) {
    this.titleMarkAsReadEventData.emit(event);
  }
  openPopup() {
    if (!this.isAddTitleDisable) {
      // Call cdkVisualHidden after 4 seconds
      setTimeout(() => {
        cdkRemoveVisualHidden();
      }, 2000);
      
      setTimeout(() => {
        const locAddaTitleHide = document.getElementById('isProgramDetailPage');
        locAddaTitleHide?.removeAttribute('role');
        locAddaTitleHide?.removeAttribute('aria-live');
      }, 100);
      this.kzProgramsService.programId.next(this.programDetails.id);
      const data = {
        ...ADDPOPUP_ALERT,
        searchBtnFunc: this.searchLibraryTitle,
        searchOwnBtnFunc: this.searchOwnLibraryTitle
      };
      this.isAddTitleDisable = true;
      this.addCardDialog = this.matDialog.open(AlertDialogNewComponent, {
        panelClass: ['profile-create-alert-dialog', data.panelClass],
        ariaDescribedBy: data.heading ? 'alert-heading' : '',
        ariaLabelledBy: 'loc_textalertcontent',
        ariaLabel: 'Add a Title',
        id: 'alert-dialog-title',
        role: 'dialog',
        disableClose: false,
        data
      });
      this.addCardDialog.afterClosed().subscribe(() => {
        setTimeout(() => {
          this.isAddTitleDisable = false;
          const locAddaTitle = document.getElementById('loc_AddaTitle');
          locAddaTitle && locAddaTitle.setAttribute('aria-hidden', 'true');
        }, 100);

        setTimeout(() => {
          const locAddaTitle = document.getElementById('loc_AddaTitle');
          locAddaTitle && locAddaTitle.removeAttribute('aria-hidden');
          const locAddaTitleHide = document.getElementById('isProgramDetailPage');
          locAddaTitleHide?.setAttribute('role', 'alert');
          locAddaTitleHide?.setAttribute('aria-live','assetive');
        }, 1000);
      })
      this.sharedAnalyticsService.trackCarouselEvent('ADD_TITLE_CTA');
    }
  }

  searchLibraryTitle = () => {
    this.headerAnalyticsService.trackHeaderEvent('ADVANCED_SEARCH');
    this.programAnalyticsService.trackScreen('ADD_TITLE_SCREEN');
    this.programAnalyticsService.trackNotificationsEvent('SEARCH_YOUR_LIBRARY');
    if (this.advanceSearch) {
      return;
    }
    this.advanceSearch = !this.advanceSearch;
    this.kzProgramsService.kzProgramType = this.programDetails?.type;
    const searchDialog = this.matDialog.open(AdvancedSearchComponent, {
      panelClass: 'search-modal-container',
      autoFocus: true,
      ariaLabelledBy: 'search-title',
      data: {
        searchText: this.searchText,
        pageName: 'programs',
        programId: this.programDetails?.id
      }
    });
    searchDialog.afterClosed().subscribe(() => {
      this.advanceSearch = false;
      setTimeout(() => {
        const addTitleBtn = document.getElementById('loc_AddaTitle');
        addTitleBtn?.focus();
      }, 500);
      this.isAddTitleDisable = false;
      this.addCardDialog.close();
    });
  };
  searchOwnLibraryTitle = () => {
    this.programAnalyticsService.trackNotificationsEvent('ADD_YOUR_OWN_TITLE');
    setTimeout(() => {
      this.router.navigate([`/view/programs/${this.programDetails.id}/addtitle`], {
        queryParams: {
          programName: this.programDetails.name,
          isParticipant: this.programDetails.isParticipant,
          programIds: this.programDetails.id,
          sequenceId: this.kzProgramsService.tempSequenceId
        }
      });
     }, 300);
  };

  fieldsChange(event, bookDetails) {
    bookDetails.ischecked = event.checked;
    this.kzProgramsService.setIsDelete(bookDetails);
  }

  ngOnDestroy() {
    this.kzProgramsService.programType.next('');
  }
  /**
   * Initialize the aria-label content
   */
  titleAriaLabel(element: any) {
    let ariaLabel;
    this.initTitleAriaLabel = this.initTitleAriaLabel
      .replace(/eAudio/g, 'e Audio')
      .replace(/eBook/g, 'e Book')
      .replace(/vBook/g, 'v Book');
    ariaLabel = this.initTitleAriaLabel;

    // Handle general cases for other formats and conditions
    if (
      (this.titleContent?.FormatType === 'ABT' ||
        this.titleContent?.formatType === 'ABT' ||
        this.titleContent?.iconInfo?.icon === 'audio') &&
      (this.titleContent?.runTime || this.titleContent?.RunTime)
    ) {
      if (
        this.titleContent?.DisplayStatus &&
        this.titleContent?.DisplayStatus !== this.onHold &&
        this.titleContent?.DisplayStatus !== this.readyForCheckout &&
        !(this.isShowWaitList() && !this.titleContent.isManual)
      ) {
        const updatedDisplayStatus = this.titleContent?.DisplayStatus === 'REQUESTED' ? 'Purchase Request' : this.titleContent?.DisplayStatus;
        ariaLabel = this.bookstatus + updatedDisplayStatus + ', ' + this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim() + ', ' + this.duration + this.replaceTimeStrings(this.titleContent.runTime || this.titleContent.RunTime);
      } else if (this.titleContent?.HoldPosition && this.titleContent?.HoldPosition !== '' && this.titleContent?.DisplayStatus === this.readyForCheckout) {
        ariaLabel = `${this.bookstatus} Ready for checkout, ${this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim()}` + ', ' + this.duration + this.replaceTimeStrings(this.titleContent.runTime || this.titleContent.RunTime);
      } else if (this.titleContent?.HoldPosition && this.titleContent?.HoldPosition !== '' && this.titleContent?.DisplayStatus !== 'REQUESTED') {
        ariaLabel = `${this.bookstatus} ${this.titleContent?.DisplayStatus} , ${this.titleContent?.HoldPosition} ${this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim()}` + ', ' + this.duration + this.replaceTimeStrings(this.titleContent.runTime || this.titleContent.RunTime);
      } else if (this.titleContent?.titleStateInfo?.displayStatus == "ON HOLD" && this.titleContent?.titleStateInfo?.holdPosition !== '' && this.titleContent?.DisplayStatus !== 'REQUESTED') {
        ariaLabel = `${this.bookstatus} ${this.titleContent?.titleStateInfo?.displayStatus} , ${this.titleContent?.titleStateInfo?.holdPosition} ${this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim()}`;
      } else {
        ariaLabel = this.initTitleAriaLabel + ', ' + this.duration + this.replaceTimeStrings(this.titleContent.runTime || this.titleContent.RunTime);
      }
    } else if (
      this.titleContent?.DisplayStatus &&
      this.titleContent?.DisplayStatus !== this.onHold &&
      this.titleContent?.DisplayStatus !== this.readyForCheckout &&
      !(this.isShowWaitList() && !this.titleContent.isManual)
    ) {
      const updatedDisplayStatus = this.titleContent?.DisplayStatus === 'REQUESTED' ? 'Purchase Request' : this.titleContent?.DisplayStatus;
      ariaLabel = this.bookstatus + updatedDisplayStatus + ', ' + this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim();
    } else if (this.titleContent?.HoldPosition && this.titleContent?.HoldPosition !== '' && this.titleContent?.DisplayStatus === this.readyForCheckout) {
      ariaLabel = `${this.bookstatus} Ready for checkout, ${this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim()}`;
    } else if (this.titleContent?.titleStateInfo?.displayStatus == "ON HOLD" && this.titleContent?.titleStateInfo?.holdPosition !== '' && this.titleContent?.DisplayStatus !== 'REQUESTED') {
      ariaLabel = `${this.bookstatus} ${this.titleContent?.titleStateInfo?.displayStatus} , ${this.titleContent?.titleStateInfo?.holdPosition} ${this.initTitleAriaLabel.replace("Book Status Waitlist", "").trim()}`;
    } else if (
      this.titleContent?.FormatType === 'EBT' &&
      this.titleContent?.Attribute === 'Read Along' &&
      !this.titleContent?.DisplayStatus
    ) {
      ariaLabel =
        this.initTitleAriaLabel.replace(/e Book/g,
          `${this.getTitleFormat(this.titleContent).replace(/eRead-Along/g, 'e Read Along')}`
        );
    } else if (
      (this.titleContent?.FormatType === 'EBT' || this.titleContent?.iconInfo?.icon === 'ebook') &&
      this.titleContent?.Attribute !== 'Read Along' &&
      (!this.titleContent?.DisplayStatus || this.titleContent?.DisplayStatus === 'ON HOLD')
    ) {
      ariaLabel = this.initTitleAriaLabel;
    } else if (this.titleContent?.DisplayStatus === this.comingSoon) {
      ariaLabel = this.initTitleAriaLabel + ' ' + this.titleContent?.DisplayStatus.toLocaleLowerCase();
    } else if (this.titleContent?.FormatType === 'eAudio') {
      // Special handling for eAudio: Ensure "Opens in a new tab" is at the end
      ariaLabel = this.initTitleAriaLabel + ', ' + this.duration + this.replaceTimeStrings(this.titleContent.runTime || this.titleContent.RunTime);
    } else {
      ariaLabel = this.initTitleAriaLabel.replace(/eRead-Along/g, 'e Read Along');
    }

    // Check if "Opens in a new tab" is already part of the ariaLabel and move it to the end
    if (ariaLabel.includes('Opens in a new tab,')) {
      // Remove "Opens in a new tab" from its current position
      ariaLabel = ariaLabel.replace('Opens in a new tab', '').trim();
      // Add a comma before "Opens in a new tab" only if there is already something before it (like duration)
      if (ariaLabel && !ariaLabel.endsWith(',')) {
        ariaLabel += ', Opens in a new tab';
      }       
    }

    // Remove unnecessary commas between segments before duration
    ariaLabel = ariaLabel.replace(/,\s*,/, ',');

    // Set the final aria-label
    element?.closest('.aria_label_attr')?.setAttribute('aria-label', ariaLabel);

    // Optionally append reading progress if available
    const readingProgress = this.bookProgress > 0 ? `Reading Progress  ${this.bookProgress}%` : '';
    ariaLabel = this.isReadingProgress ? ariaLabel + readingProgress : ariaLabel;

    if (ariaLabel == null && this.titleContent?.altLabel) {
      ariaLabel = this.titleContent?.altLabel;
    }
    return ariaLabel;
  }




  /**
   * Initialize the title content
   */
  initTitleContent() {
    if (this.titleContent) {
      this.titleContent.title = getValueFromCaseinsensitiveAttr(this.titleContent.title, this.titleContent.Title, '');
      this.titleContent.isAvailable = getValueFromCaseinsensitiveAttr(this.titleContent.isAvailable, this.titleContent.IsAvailable, false);
      this.titleContent.isRTV = getValueFromCaseinsensitiveAttr(this.titleContent.isRTV, this.titleContent.IsRTV, false);
      this.titleContent.formatType = getValueFromCaseinsensitiveAttr(this.titleContent.formatType, this.titleContent.FormatType, '');
      this.titleContent.author = getValueFromCaseinsensitiveAttr(this.titleContent.author, this.titleContent.Author, '');
      this.titleContent.itemId = getValueFromCaseinsensitiveAttr(this.titleContent.itemId, this.titleContent.ItemId, '');
      this.titleContent.isbn = getValueFromCaseinsensitiveAttr(this.titleContent.isbn, this.titleContent.ISBN, '');
      this.titleContent.author = this.titleContent.author.replace('#', ';');
      this.titleContent.isManual = getValueFromCaseinsensitiveAttr(this.titleContent.isManual, this.titleContent.IsManual, '');

      const pageCount = this.titleContent.bookLength || this.titleContent.BookLength;
      const isBookPageCount = (pageCount !== '0') ? `${pageCount} Pages` : '';
      const pageCountText = pageCount ? isBookPageCount : '';
      if (this.titleContent.formatType) {
        this.initTitleAriaLabel = `${this.format} ${this.getIcon(this.titleContent?.formatType)?.alt}, ${this.booktitle
          } ${this.titleContent?.title?.replace(/<[^<>]*?>/g, '')}${this.titleContent?.author ? ', Author' : ''} ${this.titleContent?.author
          } ${pageCountText}`;
      } else {
        this.initTitleAriaLabel = `${this.format} ${this.titleContent?.iconInfo?.alt}, ${this.booktitle
          } ${this.titleContent?.title?.replace(/<[^<>]*?>/g, '')} ${this.titleContent?.author ? ', Author' : ''} ${this.titleContent?.author
          } ${pageCountText}`;
      }

      /*********based on parentRoute the title-details router-url will be change********/

      if (this.isCurrentlyCheckout) {
        this.playerURL = this.titleContent.formatType === 'EBT' ? 'ReadNowClick' : 'ListenNowClick';
        this.titleDetailLink = decodeURIComponent(
          `/Action/${this.playerURL}?itemId=${this.titleContent.itemId}&ISBN=${this.titleContent.isbn}`
        );
        return;
      }

      if (
        this.parentRoute &&
        (this.parentRoute.includes('subject') ||
          (this.parentRoute.includes('list') && !this.parentRoute.includes('wishlist')) ||
          this.parentRoute.includes('detail') ||
          this.parentRoute.includes('alwaysavailable') || this.parentRoute.includes('Based_your_Interests'))
      ) {
        const routeTitle = this.parentRoute.includes('Based_your_Interests') ? 'list': this.parentRoute
       
        this.titleDetailLink = `/view/title/${routeTitle}/${this.titleContent.itemId}/${this.titleContent.ISBN || this.titleContent.isbn
          }`;
        return;
      }
      if (this.isFeaturedProgram && this.programId) {
        this.titleDetailLink = `/view/programs/${this.programId}`;
        return;
      }
      if (this.programType === 'Milestone' && this.titleContent.isManual) {
        this.titleDetailLink = null;
        return;
      }
      this.titleDetailLink = `/view/${this.parentRoute}/title/${this.titleContent.itemId}/${this.titleContent.ISBN || this.titleContent.isbn
        }`;
    }
  }

  /**
   * Get image for the title card by ISBN
   * @param isbn ISBN number of the title
   * @returns image url
   */
  getTitleImage(isbn: string) {
    return this.configService.appSettings?.titleImageSrcFormat?.replace('{0}', isbn);
  }

  /**
   * get icon for the format type
   * @param formatType format type
   * @returns icon name
   */
  getIcon(formatType) {
    return getBookIcon(`KZ_${formatType}`);
  }
  /**
   * get Icon
   * @param iconImage icon Image
   * @returns icon name
   */
  getImage(iconImage) {
    return 'kz-' + iconImage;
  }

  /**
   * emit the event title action
   */
  titleActionEvent() {
    this.titleActionEvt.emit();
  }

  /**
   * redirect to press reader detail page
   */
  cardClick(type?: string) {
    this.commonService.breadcrumbs = [];
    this.commonService.titleBreadCrumb = false;
    sessionStorage.removeItem('breadcrumb');
    this.cardClickEvent.emit();
    this.titleDetailsService.itemsTitleDetails = this.titleContent;
    this.typeCardClick = type;
    if (this.titleDetailLink !== null && this.programDetails?.type === 'Milestone') {
      this.notificationEvent.emit();
    }
    this.sharedAnalyticsService.trackCarouselEvent('CLICK_TITLE_CARD', {
      titleName: getValueFromCaseinsensitiveAttr(this.titleContent.Title, this.titleContent.title, '')
    });
  }

  /**
   * redirect to see all page.
   */
  seeAllClick() {
    this.seeAllClickEvent.emit();
  }

  onMousedown(event: MouseEvent) {
    event.preventDefault();
  }

  getTitleFormat(titleContent: any) {
    if (titleContent && (titleContent.Attribute || titleContent.attribute)) {
      const format = titleContent.Attribute || titleContent.attribute;
      return format.replace('Read Along', 'eRead-Along');
    }
  }

  isShowDisplayStatus() {
    let isShowDisplayStatus = false;

    isShowDisplayStatus =
      this.titleContent?.DisplayStatus &&
      this.titleContent.ListName !== 'Featured' &&
      ![this.onHold,
      this.unAvailableStatus,
      this.holdSuspended,
      this.readyForCheckout,
      this.requested, this.pastCheckout, this.expieingSoon].some(
        (x) => x === this.titleContent?.DisplayStatus
      ) &&
      !this.titleContent?.DisplayStatus?.startsWith('Due');

    return isShowDisplayStatus;
  }

  isShowEbookStatus() {
    let isShowEbookStatus = false;
    if (typeof this.titleContent.isManual !== 'string' && !this.titleContent.isManual) {
      isShowEbookStatus =
        (!this.titleContent?.DisplayStatus ||
          [this.onHold, this.unAvailableStatus, this.holdSuspended, this.readyForCheckout, this.requested, this.pastCheckout].some(
            (x) => x === this.titleContent?.DisplayStatus
          ) ||
          this.titleContent?.DisplayStatus?.startsWith('Due') ||
          this.titleContent?.ListName === 'Featured') &&
        (this.titleContent.OnOrderQuantity < 1 || this.titleContent.onOrderQuantity < 1);
    } else {
      isShowEbookStatus =
        (!this.titleContent?.DisplayStatus ||
          [this.onHold, this.unAvailableStatus,
          this.holdSuspended, this.readyForCheckout,
          this.requested, this.pastCheckout, this.expieingSoon].some(
            (x) => x === this.titleContent?.DisplayStatus
          ) ||
          this.titleContent?.DisplayStatus?.startsWith('Due'));
    }
    return isShowEbookStatus;
  }

  isShowWaitList() {
    let showWaitList = false;

    showWaitList =
      (this.titleContent?.DisplayStatus === this.unAvailableStatus ||
        this.titleContent?.DisplayStatus === this.comingSoon ||
        (!this.titleContent?.IsAvailable && !this.titleContent?.isAvailable)) &&
      ![this.onHold, this.holdSuspended,
      this.readyForCheckout, this.requested,
      this.pastCheckout].some((x) => x === this.titleContent?.DisplayStatus) &&
      !this.titleContent?.DisplayStatus?.startsWith('Due');

    return showWaitList;
  }
  getSanitizedTitle(title ): string {
    return title.replace(/<[^<>]*?>/g, '');
  }
}
