import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'axis360-alert-dialog-new',
  templateUrl: './alert-dialog-new.component.html',
  styleUrls: ['./alert-dialog-new.component.scss']
})
export class AlertDialogNewComponent extends AlertDialogComponent implements OnInit, AfterViewInit {
  ngOnInit(): void {
    this.modaldata.content = this.modaldata.content.replace('role="link"', 'role="link" aria-label="Profiles Help. Opens in a new tab"');
    document.querySelectorAll('.ng-trigger-dialogContainer').forEach((n) => {
      n.removeAttribute('tabindex');
    });
    if (this.modaldata.heading === 'Add a Title' && /android|iPhone|iPod|iPad/i.exec(navigator.userAgent)) {
      document.getElementById('alert-dialog-title').setAttribute("aria-describedby", "alert-heading loc_SearchYourLibrary Loc_AddyourOwnTitle");
    }
  }
  listenEscape(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancelClick();
    }
  }
  ngAfterViewInit(): void {
    if (/Mac/i.exec(navigator.userAgent) && this.modaldata.content) {
      setTimeout(() => {
        this.liveAnnouncer.announce(this.modaldata.content, 'assertive');
      }, 2000);
    }
    document.addEventListener('keydown', (e: any) => this.listenEscape(e));
  }
}
