<div class="d-flex title-filter-sec">
    <div class="title-filter-main">
        <div class="kz-filter-select availability-select" [ngClass]="{'disabled-option': (selectedFormat === 'VID' || selectedFormat === 'VBK' || !enableFilter || partnerSiteEnabled)}">
            <mat-form-field appearance="fill" class="filter-type" *ngIf="!epopupValue">
                <mat-label id="loc_txtAvailability">Availability&nbsp;<span class="kz-visually-hidden">filter</span></mat-label>
                <mat-select panelClass="kz-title-filter-select" disableOptionCentering
                    [(value)]="selectedAvailability" id="loc_availabilitySelect" aria-haspopup="listbox"
                    [attr.aria-hidden]="(selectedFormat === 'VID' || selectedFormat === 'VBK' || !enableFilter || partnerSiteEnabled)"
                    (openedChange)="openMatSelect($event)" aria-owns="loc_txtavialbleSelect"
                    (selectionChange)="filterChangeForCarousel('Availability',$event.value)"
                    aria-labelledby="loc_txtAvailability" role="combobox" #availabilitySelect [attr.aria-expanded]="availabilitySelect.panelOpen"
                    axis360MatSelectAriaExpanded>
                    <mat-option *ngFor="let availability of homeAvailability" [id]="availability.id" attr.aria-label="{{availability.text}}" [value]="availability.value">
                        {{availability.text}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="filter-icon" svgIcon="kz-filter-down-arrow"></mat-icon>
            </mat-form-field>
        </div>
    </div>
    <div class="title-filter-main">
        <div class="kz-filter-select format-select" [ngClass]="{'disabled-option': !enableFilter}">
            <mat-form-field appearance="fill" class="filter-type">
                <mat-label id="loc_txtFormat">Format&nbsp;<span class="kz-visually-hidden">filter</span></mat-label>
                <mat-select panelClass="kz-title-filter-select" disableOptionCentering
                [attr.aria-hidden]="!enableFilter" id="loc_formatSelect" aria-haspopup="listbox"
                    [(value)]="selectedFormat" (openedChange)="openMatSelect($event)" (selectionChange)="filterChangeForCarousel('Format',$event.value)"
                    aria-labelledby="loc_txtFormat" role="combobox" #formatSelect [attr.aria-expanded]="formatSelect.panelOpen"
                    axis360MatSelectAriaExpanded>
                    <mat-option *ngFor="let format of homeFormatType" [id]="format.id" attr.aria-label="{{format.text}}" [value]="format.value">
                        {{format.text}}
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="filter-icon" svgIcon="kz-filter-down-arrow"></mat-icon>
            </mat-form-field>
        </div>
    </div>
</div>
